import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { CONFIG, Razor_CONFIG } from "../../../../config/data";
import axios from "axios";
import CheckboxOrRadioGroup from "../../../UI/CheckboxOrRadioGroup";
import ToggleButton from "../../../UI/ToggleButton";
import InputField from "../../../UI/InputField";
import SpanField from "../../../UI/SpanField";
import Spinner from "../../../UI/Spinner";
import Select from "react-select";
import * as moment from "moment";
import { Bar, Line } from "react-chartjs-2";
import Validation from "../../../../actions/Validation";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../modal/IdleModal'
import "../../../../assets/css/mystyle.css";


import {
  getTimeFormat,
  getTimeFormatToUI,
  getDateISOFormat

} from "../../../../constants/globalLevelFunctions";

import {
  getAllHospitalQueueReport,
  getAllReviewsReport,
  updateAdminDetails,
  getDocByBusiness,
  getCharturl,
  getBusinessById,
  updateDoctorStatus,
  UpdateQueueDrOfficeScheduleChange,
  getTimeoutbycategory,RazorPayPayment
} from "../../../../config/url.json";
import LoadingHeartrate from "../../../UI/Heartrate";
import Dnaspinner from "../../../UI/Dnaspinner";

function mapStateToProps(state) {
  console.log(state);
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
const scheduleType = [{ label: "Token", value: "Queue" }, { label: "Appointment", value: "Appointment" }];
const time_break = [
  { label: "Mins", value: "Mins" },
  { label: "Hours", value: "Hours" },
];


const __DEV__ = document.domain === 'https://dbp74y1qpj.execute-api.ap-south-1.amazonaws.com'
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
};
}
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}
const doctor_arrived = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
const hospital_opened = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
const state = {
  labels: ["January", "February", "March", "April", "May"],
  datasets: [
    {
      label: "Check-In",
    /*   backgroundColor: "rgba(75,192,192,1)", */
   
     // borderColor: "rgba(0,0,0,1)",
      borderWidth: 2,
      data: [65, 59, 80, 81, 56],
    },
  ],
};
class Dashboard extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),

      time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;
    this.state = {
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      adminstatus: this.props.userdata.adminstatus,
      selected_schedule_day_id: "",
      queue_report_today: [],
      queue_report_yesterday: [],
      reviews_data: [],
      chart_data: [],
      admin_data: {
        daily_info_is_open: "",
        schedule_type: [],
        daily_info_cycle_time: "",
        daily_info_reschedule_time: "",
        daily_info_open_time: "",
        open_exacttime: "",
        cycle_time: "",
        cycle_time_break: {
          label: "Mins",
          value: "Hours",
        },
        reschedule_time: "",
        reschedule_time_break: {
          label: "Mins",
          value: "Hours",
        },
      },
      doctors_list_data: [],
      errors: {},
      formSuccess: {
        message: "",
        status: "",
      },
      doctorStatusSuccess: {
        message: "",
        status: "",
      },
      currentTime: time,
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
   
  }
  handleClose() {
    this.setState({showModal: false})
  }
  handleclose() {
    this.setState({showPopupModal: false})
  }
 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   
};

  _onAction(e) {
    console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }
  handleUsehere() {
    this.setState({showPopupModal: false})
  }
  handleInput = (e) => {
    let data = this.state.admin_data;
    let name = e.target.name;
    let value = e.target.value;

    if (e.target.name == "cycle_time") {
      if (data.cycle_time_break.label === "Mins") {
        value = value > 59 ? "" : value;
      } else {
        value = value > 24 ? "" : value;
      }
    } else if (e.target.name === "reschedule_time") {
      if (data.reschedule_time_break.label === "Mins") {
        value = value > 59 ? "" : value;
      } else {
        value = value > 24 ? "" : value;
      }
    }
    data[name] = value;
    this.setState({ admin_data: data });
    console.log(this.state.admin_data);
  };
  handleArrayInput = (i, selectoption) => {
    // let data = this.state.doctors_list_data;
    // let name = e.target.name;
    // let value = e.target.value;
    // data[name] = value;
    // this.setState({ doctors_list_data: data });
    console.log(selectoption.target.value);
    let data = this.state.doctors_list_data;
    data[i].doctor_exactarrivaltime = selectoption.target.value
    console.log(data);
    this.setState({ doctors_list_data: data });
  }
  handleTimingSelect = (i, j, selectoption) => {

    let data = this.state.doctors_list_data;
    data[i].selected_schedule_day_id = selectoption.target.value
    this.setState({ doctors_list_data: data });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.admin_data;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ admin_data: data, errors: errors });
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.admin_data;
    data[e.name] = selectedOptions;

    if (e.name == "cycle_time_break") {
      data.cycle_time = "";
    } else if (e.name == "reschedule_time_break") {
      data.reschedule_time = "";
    }
    this.setState({ admin_data: data });
  };
  handleSechduleType = (e) => {
    let data = this.state.admin_data;

    data.schedule_type = e.target.value;
    this.setState({ admin_data: data });
  };
  
  componentDidMount = () => {


      //    const handleInvalidToken = e => {
      //   if (e.key === 'accessToken' && e.oldValue && !e.newValue) {
      //     // Your logout logic here
      //     this.handleLogout()
      //   }
      // }
  
      // window.addEventListener('storage', handleInvalidToken)
  
      // return function cleanup() {
      //   window.removeEventListener('storage', handleInvalidToken)
      // }
    //         window.addEventListener('storage', () => {
    //  window.alert('another window or tab is open')
     
    //       }, false)
         
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });

   

 
    this.setState({ loading: true })
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    let url = getAllHospitalQueueReport + "?business_id=" + this.props.userdata.id; 
    axios
      .get(url, null, CONFIG)
      .then((res) => {
        let data = res.data;

        if (data.status !== "0")
          this.setState({
            queue_report_today: data.data.today,
            queue_report_yesterday: data.data.yesterday,
          });
        else {
          this.setState({
            queue_report_today: [],
            queue_report_yesterday: [],
          });
        }
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let getAllReviewsReporturl =
      getAllReviewsReport + "?business_id=" + this.props.userdata.id;
    axios
      .get(getAllReviewsReporturl, null, CONFIG)
      .then((result) => {
        if (result.data.status === "1") {
          this.setState({ reviews_data: result.data.data[0] });
        } else {
          this.setState({ reviews_data: [] });
        }
      })
      .catch((e) => {
        console.log(e.data);
        console.log("business user getbyid failed");
      });

    // get graph data
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let getCharturldata =
      getCharturl + "?business_id=" + this.props.userdata.id;
    axios
      .get(getCharturldata, null, CONFIG)
      .then((result) => {
        if (result.data.status === "1") {
      
          let chart_data = {
            labels: Object.keys(result.data.data),
            datasets: [
              {
                label: "Check-In",
                backgroundColor: "rgba(218, 0, 0, 0.8)",
              /*   backgroundColor: [
                  "rgba(245, 40, 145, 0.8)",
                  "rgba(218, 0, 0, 0.8)",
                  "rgba(0, 122, 80, 0.8)",
                ], */
               // borderColor: "rgba(41,184,246,1)",
                borderWidth: 2,
                data: Object.values(result.data.data),
              },
            ],
          };
          this.setState({ chart_data: chart_data });
        } else {
          // this.setState({ chart_data: [] });
        }
      })
      .catch((e) => {
        console.log("business user getbyid failed");
      });
    if (this.props.access_token) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      axios
        .post(
          getDocByBusiness,
          {
            n_business_id: this.props.userdata.id, n_currentdate: getDateISOFormat(new Date()),
          },
          CONFIG
        )
        .then((res) => {
          let data = res.data;
          // console.log(data);
          // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'my-secret-key@123').toString();
          // console.log(ciphertext);
          // var bytes = CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123');
          // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          // console.log(decryptedData);
          // console.log(decryptedData.data);
          // console.log(decryptedData.status);
          // console.log(res);
          if (data.status !== "0") {
            let resdata = data.data;
            console.log(resdata);
            let doctorList = [];
            resdata.forEach((element, index) => {
              let doctor = {};
              console.log(element);
              doctor.first_name = element.doctor_name;
              // doctor.last_name = element.last_name;
              doctor.id = element.business_user_id;
              doctor.user_id = element.business_user_id;
              doctor.is_doctor_arrived = element.is_doctor_arrived == "1" ? "Yes" : "No";
              doctor.doctor_Appointments = element.doctor_Appointments;
              doctor.schedule_type = element.schedule_type;
              doctor.selected_schedule_day_id = element.doctor_Appointments[0].schedule_day_id;

              doctor.doctor_exactarrivaltime = element.doctor_exactarrivaltime ? element.doctor_exactarrivaltime : moment().format("HH:mm:00");
              doctorList[index] = doctor;
            });
            this.setState({ doctors_list_data: doctorList });
          }
        })
        .catch((e) => { });

    }
    let { currentTime } = this.state;
    axios
      .post(getBusinessById, { business_id: this.props.userdata.id }, CONFIG)
      .then((response) => {
        let responseData = response.data;
        let admin_data = this.state.admin_data;
        if (responseData.status === "1") {
          let data = responseData.data[0];
          admin_data.daily_info_is_open = data.daily_info_is_open
            ? "Yes"
            : "No";
          admin_data.daily_info_open_time = data.daily_info_open_time
            ? data.daily_info_open_time
            : "";

          var time = admin_data.daily_info_open_time.toString();

          time = time.substr(11, 8);
          console.log(time);
          console.log(admin_data.daily_info_open_time);

          admin_data.daily_info_open_time = time;
          admin_data.schedule_type = data.schedule_type
            ? data.schedule_type
            : [];
          admin_data.open_exacttime = currentTime;
          if (data.daily_info_cycle_time) {
            let cycleTime_UI_Data = getTimeFormatToUI(
              data.daily_info_cycle_time
            );
            admin_data.cycle_time = cycleTime_UI_Data.Time;
            admin_data.cycle_time_break = {
              label: cycleTime_UI_Data.Type,
              value: cycleTime_UI_Data.Type,
            };
          }

          if (data.daily_info_reschedule_time) {
            let rescheduleTime_UI_Data = getTimeFormatToUI(
              data.daily_info_reschedule_time
            );
            admin_data.reschedule_time = rescheduleTime_UI_Data.Time;
            admin_data.reschedule_time_break = {
              label: rescheduleTime_UI_Data.Type,
              value: rescheduleTime_UI_Data.Type,
            };
          }
          this.setState({ admin_data: admin_data });
          this.setState({ loading: false })
        }
      });
  };
    handleToggle = (checkedData, obj) => {
    //if value is off make it on & viceversa
    obj.target.value = obj.target.value === "on" ? "off" : "on";

    let admin_data = this.state.admin_data;
    // if obj is "on" make backend variable as Yes and viceversa. It has to behave like radio button
    admin_data[obj.target.name] = obj.target.value === "on" ? "Yes" : "No";

    this.setState({ admin_data });
  };
  updateDoctorDetails = (e, i) => {
    console.log(e, i)
    let { currentTime } = this.state;
    let doc_list = this.state.doctors_list_data;
    console.log(doc_list)
    let doctorRecord = "";
    let doctorStatus = "";
    let schedule_day_id = "";
    let doctor_exactarrivaltime = "";
    doc_list.forEach((element, index) => {
      if (e === index) {

        doctorStatus = element.is_doctor_arrived;
        doctorRecord = element.user_id;
        schedule_day_id = element.selected_schedule_day_id;
        doctor_exactarrivaltime = element.doctor_exactarrivaltime;
        console.log(element.is_doctor_arrived);
        // if (element.is_doctor_arrived === 1) {
        //   doctor_exactarrivaltime = currentTime;
        //   doctor_exactarrivaltime = element.doctor_exactarrivaltime;
        // }
        // else {
        //   doctor_exactarrivaltime = null;
        //   doctor_exactarrivaltime = element.doctor_exactarrivaltime;
        // }

      }
    });
    // this.setState({ doctors_list_data: doc_list });


    console.log(doc_list);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    let reqdata = {
      user_id: doctorRecord,
      business_id: this.props.userdata.id,
      is_doctor_arrived: doctorStatus === "Yes" ? 1 : 0,
      doctor_exactarrivaltime: doctor_exactarrivaltime
    };
    axios
      .post(updateDoctorStatus, reqdata, CONFIG)
      .then((res) => {
        let data = res.data;

        if (data.status !== "0") {
          data.error_type = "success";
          this.setState({
            doctorStatusSuccess: data,
          });
          if (doctorStatus == 'Yes') {
            let datas = {
              "n_business_id": reqdata.business_id,
              "n_schedule_day_id": schedule_day_id,
              "n_business_user_id": doctorRecord,
              "n_schedule_type": "DR",
              "n_savedtime": reqdata.doctor_exactarrivaltime,
            };
            console.log(datas)
            axios
              .post(UpdateQueueDrOfficeScheduleChange, datas)
              .then((res) => {
                console.log(res);
              }).catch((e) => {
                console.log(e);
              });
          }

          this.setState({ loading: false });
          this.clearMessage();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  dynamichandleToggle = (checkedData, i, obj) => {
    console.log(i, checkedData, obj)
    //if value is off make it on & viceversa
    obj.target.value = obj.target.value === "on" ? "off" : "on";
    let { currentTime } = this.state;
    let doc_list = this.state.doctors_list_data;

    let doctorRecord = "";
    let doctorStatus = "";
    let schedule_day_id = "";
    let schedule_type = "";
    doc_list.forEach((element, index) => {
      if (element.id == obj.target.name.split("|")[1]) {
        element.is_doctor_arrived = obj.target.value === "on" ? "Yes" : "No";
        doctorStatus = element.is_doctor_arrived;
        doctorRecord = element.user_id;
        schedule_day_id = element.selected_schedule_day_id;
        schedule_type = element.schedule_type;
        console.log(element.is_doctor_arrived);
        if (element.is_doctor_arrived === "Yes") {

          doc_list[i].doctor_exactarrivaltime = moment().format("HH:mm:ss");
          // doctor_exactarrivaltime = element.doctor_exactarrivaltime;
        }
        else {
          // doctor_exactarrivaltime = null;
          // doctor_exactarrivaltime = element.doctor_exactarrivaltime;
        }

      }
    });

    this.setState({ doctors_list_data: doc_list });




    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    let reqdata = {
      user_id: doctorRecord,
      business_id: this.props.userdata.id,
      is_doctor_arrived: doctorStatus === "Yes" ? 1 : 0,
      doctor_exactarrivaltime: doctorStatus === "Yes" ? moment().format("HH:mm:ss") : null
    };
    axios
      .post(updateDoctorStatus, reqdata, CONFIG)
      .then((res) => {
        let data = res.data;

        if (data.status !== "0") {
          data.error_type = "success";
          this.setState({
            doctorStatusSuccess: data,
          });
          // console.log(schedule_type.toLowerCase(),doctorStatus); return false;
          if (doctorStatus == 'Yes' && schedule_type.toLowerCase() !== "appointment") {
            let datas = {
              "n_business_id": reqdata.business_id,
              "n_schedule_day_id": schedule_day_id,
              "n_business_user_id": doctorRecord,
              "n_schedule_type": "DR",
              "n_savedtime": moment().utc().format('HH:mm:ss'),
            };
            console.log(datas)
            axios
              .post(UpdateQueueDrOfficeScheduleChange, datas)
              .then((res) => {
                console.log(res);
              }).catch((e) => {
                console.log(e);
              });
          }

          this.setState({ loading: false });
          this.clearMessage();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  updateAdminDetails = (event) => {
    event.preventDefault();
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    let { admin_data, errors, currentTime } = this.state;
    console.log(this.state.currentTime);
    console.log(currentTime);
    admin_data.type = "HospitalDasboardAdmin";

    errors = Validation(admin_data, errors);

    this.setState({ errors: errors });
    if (errors.formIsValid) {
      // console.log(admin_data.daily_info_open_time + ":00");
      // console.log(getDateISOFormat(new Date()));
      // console.log(getDateISOFormat(new Date()) + " " + admin_data.daily_info_open_time + ":00")
      let data = {
        business_id: this.props.userdata.id,
        is_open: admin_data.daily_info_is_open === "Yes" ? 1 : 0,
        cycle_time: getTimeFormat(
          admin_data.cycle_time,
          admin_data.cycle_time_break.label
        ),
        reschedule_time: getTimeFormat(
          admin_data.reschedule_time,
          admin_data.reschedule_time_break.label
        ),
        open_time:
          getDateISOFormat(new Date()) + " " + admin_data.daily_info_open_time,
        schedule_type: admin_data.schedule_type
          ? admin_data.schedule_type.toString()
          : "",
        open_exacttime: currentTime,
      };
      axios
        .put(updateAdminDetails, data, CONFIG)
        .then((res) => {
          let data = res.data;

          if (data.status !== "0") {
            data.error_type = "success";
            this.setState({
              formSuccess: data,
            });
            this.setState({ loading: false });
            this.clearMessage();
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          doctorStatusSuccess: { message: "" },
        }),
      5000
    );
    let  errors = this.state.errors;
    errors.daily_info_open_time = "";
    this.setState({ errors:errors });

  };


  displayRazorpay = () => {
    const res = loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }
    const option = {
      method: 'POST',
      // headers: new Headers({ 'content-type': 'application/json' }),
      mode: 'no-cors'
    };
    const data = '';
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios.post(RazorPayPayment, null, Razor_CONFIG).then((res) => {
      // let data = res.data;

      // if (data.status !== "0")
      //   this.setState({
      //     queue_report_today: data.data.today,
      //     queue_report_yesterday: data.data.yesterday,
      //   });
      // else {
      //   this.setState({
      //     queue_report_today: [],
      //     queue_report_yesterday: [],
      //   });
      // }c
      console.log(res);
      // let data = res;
      let data = res.data;
      console.log(data.currency);
      const options = {
        // key: __DEV__ ? 'rzp_test_CObt3PWG73pjBs' : 'PRODUCTION_KEY',
        key: 'rzp_test_CObt3PWG73pjBs',
        currency: data.currency,
        amount: data.amount.toString(),
        order_id: data.id,
        name: 'Billing and Payment',
        description: '',
        image: 'https://wtmprofileimagesbucket.s3.ap-south-1.amazonaws.com/logo.png',
        handler: function (response) {
          alert(response.razorpay_payment_id)
          alert(response.razorpay_order_id)
          alert(response.razorpay_signature)
        },
        prefill: {
          name: 'elatestrazor',
          email: 'sdfdsjfh2@ndsfdf.com',
          phone_number: '9899999999'
        }
      }
      console.log(options)
      // window.setTimeout(() => {
      console.log('hi')
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
      // }, 1000);

      // res.json();
    }).catch((e) => { console.log(e) });
    // const data = await fetch("http://localhost:1337/razorpay", { method: 'POST' }).then((t) => {
    //   console.log(t)
    //   t.json();
    // }

    // )

    // const data = await fetch('http://localhost:1337/payment/api/razorpay', { method: 'POST' }).then((t) =>
    //   t.json()
    // )




  }
  render() {


    // const __DEV__ = document.domain === 'localhost'
    const {
      queue_report_yesterday,
      queue_report_today,
      reviews_data,
      queue_data,
      errors,
      admin_data,
      formSuccess,
      doctorStatusSuccess,
      chart_data,
      loading,
      hospitaltimeout,
      idleTimeout,
    } = this.state;
    
    let loggedinUserId = this.props.userdata.id;
    let navigation = {};
    navigation.userId = loggedinUserId;

    return (

      <div className="container-fluid">
         
      <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />
   
   {loading && <Dnaspinner />}

        <div className="checkout-tabs">
          <div>

            <h4 className="font-weight-bold">Dashboard</h4>

            <a
              className="App-link"
              onClick={this.displayRazorpay}
              target="_blank"
              rel="noopener noreferrer"
            >

            </a>

            <div className="row queue-box mt-4">
              <div className="col-lg-7">

                <h5 className="hedtxt2">Today</h5>

                <div className="row">

                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body d-flex justify-content-between">
                        <div className="">
                          <p className="font-weight-bold">Queue-In</p>

                          <h4 className="font-weight-bolder mt-3">{queue_report_today.queuein}</h4>
                        </div>
                        <div className="">


                          <div className="iconbox rounded-circle">
                            <i className="fa fa-calendar-check-o font-22 icon-title" aria-hidden="true"></i>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>


                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body d-flex justify-content-between">
                        <div className="">
                          <p className="font-weight-bold">Check-In</p>

                          <h4 className="font-weight-bolder mt-3">{queue_report_today.checkin}</h4>
                        </div>

                        <div className="">

                          <div className="iconbox rounded-circle">
                            <i className="fa fa-arrow-left icon-title" aria-hidden="true"></i>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body d-flex justify-content-between">
                        <div className="">
                          <p className="font-weight-bold">Cancelled</p>
                          <h4 className="font-weight-bolder mt-3">{queue_report_today.cancelled}</h4>
                        </div>
                        <div className="">
                          <div className="iconbox rounded-circle">
                            <i className="fa fa-ban icon-title" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div className="col-lg-5">
                <h5 className="hedtxt2">Yesterday</h5>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card bgg">
                      <div className="card-body d-flex justify-content-between">

                        <div className="">
                          <p className="font-weight-bold ">Consultation</p>

                          <h4 className="font-weight-bolder mt-3">{queue_report_yesterday.visits}</h4>
                        </div>


                        <div className="">
                          <div className="iconbox rounded-circle">
                            <i className="fa fa-calendar-check-o font-22 icon-title" aria-hidden="true"></i>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card bgg">
                      <div className="card-body d-flex justify-content-between">
                        <div className="">
                          <p className="font-weight-bold">Cancelled</p>

                          <h4 className="font-weight-bolder mt-3">{queue_report_yesterday.cancelled}</h4>
                        </div>

                        <div className="">
                          <div className="iconbox rounded-circle">
                            <i className="fa fa-ban icon-title" aria-hidden="true"></i>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>




                </div>
              </div>





            </div>


            <div className="row mt-2">
              <div className="col-lg-12">
                <div className="row">






                  <div className="col-sm-4 hide">
                    <div className="card">
                      <div className="card-body pd-10 min-hitap-1">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-shipping"
                            role="tabpanel"
                            aria-labelledby="v-pills-shipping-tab"
                          >
                            <h5 className="fnsz hptd">Messages </h5>
                            <table className="messagesc5">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="fa fa-comments"></i>
                                  </td>
                                  <td>
                                    <a  href="#">
                                      <p>You have 14 new messages</p>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-comments-o"></i>
                                  </td>
                                  <td>
                                    <p>
                                      <a href="#">256 read messages</a>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body pd-10 min-hitap-1">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-shipping"
                            role="tabpanel"
                            aria-labelledby="v-pills-shipping-tab"
                          >
                            <h5 className="fnsz hptd">Approvals </h5>
                            <table className="messagesc5">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="fa fa-envelope"></i>
                                  </td>
                                  <td>
                                    <a href="#">
                                      <p>15 new medicines waiting for approval</p>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-envelope-open"></i>
                                  </td>
                                  <td>
                                    <p>
                                      <a href="#">6 Approved appointments</a>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="card ">
                    <div className="card-header"> <div className="card-title">Administration</div> </div>
                      <div className="card-body pd-10 height417">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div className="tab-pane fade show active" >
                        
                            <div className="user-notification">
                              <SpanField
                                classname={
                                  "0" === formSuccess.status
                                    ? "text-danger user-notification"
                                    : "text-success user-notification"
                                }
                                title={formSuccess.message}
                              />
                            </div>
                            <div className="row p-2">
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-5">
                                    <label className="font-family">Hospital Opened ?</label>
                                  </div>

                                  <div className="col-sm-5">
                                    <ToggleButton
                                      name={"daily_info_is_open"}
                                      dataOn="Yes"
                                      dataOff="No"
                                      value={
                                        "" === admin_data.daily_info_is_open ||
                                          "No" === admin_data.daily_info_is_open
                                          ? "off"
                                          : "on"
                                      }
                                      checked={
                                        admin_data.daily_info_is_open === "Yes"
                                          ? true
                                          : false
                                      }
                                      controlFunc={this.handleToggle.bind(
                                        "daily_info_is_open",
                                        admin_data.daily_info_is_open
                                      )}
                                    />
                                  </div>
                                </div>
                                <SpanField
                                  classname="text-danger"
                                  title={errors.schedule_type}
                                />
                              </div>
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-5">
                                    <label className="font-family">Schedule Type </label>
                                  </div>
                                  <div className="col-sm-7 pl-10">
                                    {/* <CheckboxOrRadioGroup
                                      setName={"schedule_type"}
                                      type={"radio"}
                                      controlFunc={this.handleSechduleType}
                                      options={scheduleType}
                                      selectedOptions={admin_data.schedule_type}
                                    /> */}
                                    <div className="form-group">
                                      {scheduleType.map((option) => {
                                        return (
                                          <div
                                            key={option.label}
                                            className="custom-control-1 custom-radio-1 mb-2 mylft "
                                          >
                                            <input
                                              className="form-radio1"
                                              disabled={true}
                                              name="schedule_type"
                                              onChange={this.handleSechduleType}
                                              value={option.value}
                                              checked={admin_data.schedule_type.indexOf(option.value) > -1}
                                              type="radio"
                                              id={option.label}
                                            />
                                            <label className="custom-control-label-1 font-family" htmlFor={option.label}>
                                              {option.label}
                                            </label>
                                          </div>)
                                      })}
                                    </div>
                                    
                                  </div></div>

                                <SpanField
                                  classname="text-danger"
                                  title={errors.cycle_time}
                                />
                              </div>

                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-5">
                                    <label>Cycle Time </label>
                                  </div>
                                  <div className="col-sm-3 pr0">
                                    <div className="form-group">
                                      <InputField
                                        id="cycle_time"
                                        inputType={"number"}
                                        title=""
                                        name={"cycle_time"}
                                        controlFunc={this.handleInput}
                                        content={admin_data.cycle_time}
                                        placeholder={""}
                                        max={60}
                                        min={1}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-4 pl0">
                                    <div className="form-group sform-control">
                                      <Select
                                        maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="cycle_time_break"
                                        value={admin_data.cycle_time_break}
                                        onChange={this.handleselectChange}
                                        options={time_break}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <SpanField
                                  classname="text-danger"
                                  title={errors.reschedule_time}
                                />
                              </div>
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-5">
                                    <label className="font-family">Grace Time </label>
                                  </div>
                                  <div className="col-sm-3 pr0">
                                    <div className="form-group">
                                      <InputField
                                        id="reschedule_time"
                                        inputType={"number"}
                                        title=""
                                        name={"reschedule_time"}
                                        controlFunc={this.handleInput}
                                        content={admin_data.reschedule_time}
                                        placeholder={""}
                                        max={60}
                                        min={1}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-4 pl0">
                                    <div className="form-group sform-control">
                                      <Select
                                        maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="reschedule_time_break"
                                        value={admin_data.reschedule_time_break}
                                        onChange={this.handleselectChange}
                                        options={time_break}
                                      />
                                    </div>
                                  </div>
                                </div>
                              
                              </div>
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-5">
                                    <label className="font-family">Hospital Open Time </label>
                                  </div>

                                  <div className="col-sm-7">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        name="daily_info_open_time"
                                        onChange={this.handleInput}
                                        // value={this.state.currentTime}
                                        value={admin_data.daily_info_open_time}
                                      />
                                        <SpanField
                                  classname="text-danger"
                                  title={errors.daily_info_open_time}
                                />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="form-group mb-0 abtn float-right mg-lft">
                                <button
                                  className="signupbtn abtn "
                                  onClick={this.updateAdminDetails}
                                  style={{ marginTop: 0, padding: 9 + "px" }}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="card">
                    <div className="card-header"> <div className="card-title">Doctors List</div> </div>
                      <div className="card-body pd-10 height417">
                        <div className="tab-content">
                          <div className="tab-pane fade show active">
                      
                            <div className="user-notification">
                              <SpanField
                                classname={
                                  "0" === doctorStatusSuccess.status
                                    ? "text-danger user-notification"
                                    : "text-success user-notification"
                                }
                                title={doctorStatusSuccess.message}
                              />
                            </div>
                            <div className="row dashboard-doctorlist">
                              <div className="col-sm-12 topgapess">
                                {this.state.doctors_list_data.map((el, i) => (
                                  <Fragment key={i}>
                                    <div className="row p-2">
                                      <div className="col-sm-3">
                                        <label className="font-weight-bold">
                                          {i +
                                            1 +
                                            ") " +
                                            el.first_name}
                                        </label>
                                      </div>
                                      <div className="col-sm-4">
                                        {el.doctor_Appointments.map(
                                          (option, j) => {
                                            return (
                                              <Fragment key={option.schedule_day_id + j}>

                                                <div className="form-check fltx">
                                                  {/* {el.selected_schedule_day_id}<br /> */}
                                                  {/* {option.schedule_day_id}<br /> */}
                                                  <input
                                                    className="form-check-input font-family"
                                                    type="radio"
                                                    name={el.first_name}
                                                    id={el.first_name}
                                                    value={option.schedule_day_id}
                                                    checked={
                                                      el.selected_schedule_day_id === option.schedule_day_id
                                                    }
                                                    onChange={this.handleTimingSelect.bind(this, i, j)}
                                                  />
                                                  <label
                                                    className="form-check-label font-family"
                                                    htmlFor={option.event_name}
                                                  >
                                                    {option.event_name} ({option.starttime} -{" "}
                                                    {option.endtime})
                                                  </label>

                                                </div>


                                              </Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                      <div className="col-sm-3">
                                        <input
                                          type="time"
                                          className="form-control mg-top"
                                          placeholder=""
                                          name={"doctor_arrival_time|" + el.id}
                                          onChange={this.handleArrayInput.bind(this, i)}
                                          disabled={true}
                                          // value={this.state.currentTime}
                                          value={el.doctor_exactarrivaltime}
                                        // value="11:31:00"
                                        />
                                      </div>
                                      <div className="col-sm-2 mg-top">
                                        <ToggleButton
                                          name={"is_doctor_arrived|" + el.id}
                                          dataOn="Yes"
                                          dataOff="No"
                                          value={
                                            "" === el.is_doctor_arrived ||
                                              "No" === el.is_doctor_arrived
                                              ? "off"
                                              : "on"
                                          }
                                          checked={
                                            el.is_doctor_arrived === "Yes"
                                              ? true
                                              : false
                                          }
                                          controlFunc={this.dynamichandleToggle.bind(
                                            "is_doctor_arrived" + el.id,
                                            el.is_doctor_arrived, i
                                          )}
                                        />
                                        {/* <div className="form-group mb-0 abtn">
                                          <button
                                            className="signupbtn abtn "
                                            onClick={this.updateDoctorDetails.bind(this, i)}
                                            style={{ marginTop: 0, padding: 9 + "px" }}
                                          >
                                            Update
                                          </button>
                                        </div> */}
                                      </div>

                                    </div>
                                  </Fragment>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="card m365">
                    <div className="card-header"> <div className="card-title">Notifications</div> </div>
                      <div className="card-body  pd-10">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-shipping"
                            role="tabpanel"
                            aria-labelledby="v-pills-shipping-tab"
                          >
                      
                            <table className="messagesc5">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="fa fa-calendar-check-o"></i>
                                  </td>
                                  <td>
                                    <a className="font-weight-normal" href="#">
                                      <p className="font-family">Upcoming Appointment</p>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-calendar"></i>
                                  </td>
                                  <td>
                                    <p className="font-family">
                                      <a href="/shift_planner">Calender</a>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 ">
                    <div className="card m365">
                    <div className="card-header"> <div className="card-title">Check-in trend last week</div> </div>
                      <div className="card-body p-2 chartstyle ">
                       

                 {/*        <Line data={chart_data}
                          options={{
                            scales: {
                              xAxes: [{
                                gridLines: {
                                  display: false
                                }
                              }],
                              yAxes: [{
                                ticks: {
                                  beginAtZero: true,
                                },
                                gridLines: {
                                  display: false
                                }
                              }],
                            }
                          }} /> */}

                         <Bar 
                          data={chart_data}
                          height={230}
                          width={380}
                           options={{
                            title: {
                              display: false,
                              text: "Total Check-in based on week",
                              fontSize: 30,
                            },
                            legend: {
                              display: true,
                              position: "right",
                            },
                          }}
                        /> 

                      </div>

                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="card m365">
                    <div className="card-header"> <div className="card-title ">Reviews</div> </div>
                      <div className="card-body  pd-10">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-shipping"
                            role="tabpanel"
                            aria-labelledby="v-pills-shipping-tab"
                          >
                         
                            <table className="messagesc5">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="fa fa-thumbs-o-up"></i>
                                  </td>
                                  <td>
                                    <a href="/profile">
                                      <p className="font-family">{reviews_data.reviewLike} reviews</p>
                                    </a>
                                  </td>
                                </tr>
                                {/* <tr>
                              <td>
                                <i className="fa fa-thumbs-o-down"></i>
                              </td>
                              <td>
                                <p>
                                  <a href="#">27 reviews</a>
                                </p>
                              </td>
                            </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 


                  {/* <div className="col-sm-4 ">
                    <div className="card m365">
                      <div className="card-body p-2">
                        <div className="text-center">
                          <h5 className="fnsz hptd">Dummy</h5>
                        </div>
                        <div className="tab-content">
                          <div className="tab-pane fade show active">


                            <div className="row dashboard-doctorlist">
                              <div className="col-sm-12 topgapess"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>



              </div>


              <div className="col-lg-3">

                <div className="row">




                  <div className="col-sm-12 pl0 pr0">


                  </div>


                  <div className="col-sm-12 pl0 hide">
                    <div className="card ">
                      <div className="card-body pd-10 min-hitap-1">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-shipping"
                            role="tabpanel"
                            aria-labelledby="v-pills-shipping-tab"
                          >
                            <h5 className="fnsz hptd">Statement </h5>
                            <table className="messagesc5">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="fa fa-file-text-o"></i>
                                  </td>
                                  <td>
                                    <a href="#">
                                      <p>You have 3 new statements</p>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-download"></i>
                                  </td>
                                  <td>
                                    <p>
                                      <a href="#">
                                        Download previous month statements
                                      </a>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card ">
                      <div className="card-body pd-10 min-hitap-1 ">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-shipping"
                            role="tabpanel"
                            aria-labelledby="v-pills-shipping-tab"
                          >
                            <h5 className="fnsz hptd">Recent News </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>






                </div>

              </div>


            </div>
            <div className="row">







            </div>
          </div>
        </div>
        {/* <Footerad /> */}
      </div>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
