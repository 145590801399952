import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import SideBar from "../components/container/SideBar";
// import Header from "../components/container/layout/business/Header";
import ConsultHeader from "../components/container/layout/ConsultHeader";
// import Prescription from "../views/Prescription";
import FollowupDetails from "../views/FollowupDetails";
import ReferralDoctor from "../views/ReferralDoctor";
import ConsultationHistory from "../views/ConsultationHistory";

import axios from "axios";
import {
  getMasterSymptoms,
  getSymptomByCategory,
  consultDoctor,
  getAllHospitalDrug,
  getAllActiveingredientsforhospital,
  getAllgenericnameforhospital,
  getAllDrugs,
  getAllActiveingredientsforDrugs,
  getAllgenericnameforactiveingredients,
  getAllMasterDisease,
  getAllSymptom,
  getCheckinDetails,
   //prescriptions code
  searchDrug,
  getAllMasterDrug,
  getAllDrugTypes,
  getAllDrugCategories,
  getPrescription,
  // updatequeueURL,
  getQEntryById,
  updatePrescription,
  UpdateQueueForCheckOut,
  BaseUrl,sendemail,sendsms
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Spinner from "../components/UI/Spinner";
import SpanField from "../components/UI/SpanField";
import InputField from "../components/UI/InputField";
import TextArea from "../components/UI/TextArea";
import Select from "react-select";

// prescriptions code
import ReactDatatable from "@ashvin27/react-datatable";
import Footerad from "../components/UI/Footerad";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class Consult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userdata: {},
      loading: false,
      diseaseList: [],
      symptomCategory: [],
      symptoms: [],
      cloneSymptoms: [],
      consult: {
        client_id: "",
        business_id: "",
        reason_for_visit: [],
        symptoms: "",
        diagnosis: "",
        other_comments:"",
      },
      successMessage: "",
      errorMessage: "",
      PrescriptionerrorMessage: "",
      PrescriptionsuccessMessage: "",
      selectedSymptoms: [],
      symptomlist: [],
      activeIndex: '',
      activeTab: "Assessment",
      checkinData: [],

      // prescriptions codes
      consultData: null,
      records: [],
      searchRecords: [],
      searchData: {
        drug_name: "",
        drug_category: "",
        drug_type: "",
        drug_unit: "",
        active_ingredients: "",
        manufacturer: "",
      },
      drugUnits: [
        {
          value: "ea",
          label: "ea",
        },
        {
          value: "mg",
          label: "mg",
        },
      ],
      drugList: [],
      ActiveIngredientList:[],
      GenericNameList:[],
      drugTypes: [],
      drugCategories: [],
      // successMessage: "",
      // errorMessage: "",
      QueuesuccessMsg: "",

    };

    this.columns = [
    /*   {
        key: "drug_id",
        text: "drug id",
        className: "drug_id",
        align: "left",
        sortable: true,
      }, */
    /*   {
        key: "drug_id",
        text: "drug_id",
        className: "sform-control",
        sortable: false,
        align: "left",
        width: "25%",
        cell: (drug_id) => {
          return this.generatetagforcolumn(drug_id);
        }, 
      }, */
      {
        key: "medicine_description",
        text: "Medicine ",
        className: "medicine_description",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "medicine_id",
        text: "medicine id",
        className: "hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
      },
      {
        key: "days",
        text: "Days",
        className: "days",
        align: "left",
        sortable: true,
        width: 100,
        cell: (record, i) => {
          return (
            <div class="form-group dayscount">
              <div
                class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                style={{ minWidth: "112px" }}
              >
                <span class="input-group-btn input-group-prepend">
                  <button
                    class="btn btn-primary bootstrap-touchspin-down"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "days", true)}
                  >
                    -
                  </button>
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={record.days}
                  onChange={(e) => this.handleTableInput(e, i)}
                />
                <span class="input-group-btn input-group-append">
                  <button
                    class="btn btn-primary bootstrap-touchspin-up"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "days")}
                  >
                    +
                  </button>
                </span>
              </div>
            </div>
          );
        },
      },
      {
        key: "before_food",
        text: "Before Food",
        className: "before_food",
        align: "left",
        sortable: true,
        cell: (record, i) => {
          return (
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id={"bfood-" + i}
                name="before_food"
                onChange={this.handleTableCheckboxChange}
                defaultChecked={record.before_food}
              />
              <label class="custom-control-label" for={"bfood-" + i} />
            </div>
          );
        },
      },
      {
        key: "morning",
        text: "Morning",
        className: "morning",
        sortable: true,
        align: "left",
        width: 100,
        cell: (record, i) => {
          return (
            <div class="form-group dayscount">
              <div
                class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                style={{ minWidth: "112px" }}
              >
                <span class="input-group-btn input-group-prepend">
                  <button
                    class="btn btn-primary bootstrap-touchspin-down"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "morning", true)}
                  >
                    -
                  </button>
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={record.morning}
                  onChange={(e) => this.handleTableInput(e, i)}
                />
                <span class="input-group-btn input-group-append">
                  <button
                    class="btn btn-primary bootstrap-touchspin-up"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "morning")}
                  >
                    +
                  </button>
                </span>
              </div>
            </div>
          );
        },
      },
      {
        key: "noon",
        text: "Noon",
        className: "noon",
        sortable: true,
        align: "left",
        width: 100,
        cell: (record, i) => {
          return (
            <div class="form-group dayscount">
              <div
                class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                style={{ minWidth: "112px" }}
              >
                <span class="input-group-btn input-group-prepend">
                  <button
                    class="btn btn-primary bootstrap-touchspin-down"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "noon", true)}
                  >
                    -
                  </button>
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={record.noon}
                  onChange={(e) => this.handleTableInput(e, i)}
                />
                <span class="input-group-btn input-group-append">
                  <button
                    class="btn btn-primary bootstrap-touchspin-up"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "noon")}
                  >
                    +
                  </button>
                </span>
              </div>
            </div>
          );
        },
      },
      {
        key: "night",
        text: "Night",
        className: "night",
        sortable: true,
        align: "left",
        width: 100,
        cell: (record, i) => {
          return (
            <div class="d-flex">
              <div
                class="form-group dayscount"
                style={{
                  paddingRight: "12px",
                }}
              >
                <div
                  class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                  style={{ minWidth: "112px" }}
                >
                  <span class="input-group-btn input-group-prepend">
                    <button
                      class="btn btn-primary bootstrap-touchspin-down"
                      type="button"
                      onClick={() => this.incOrDecValue(i, "night", true)}
                    >
                      -
                    </button>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    value={record.night}
                    onChange={(e) => this.handleTableInput(e, i)}
                  />
                  <span class="input-group-btn input-group-append">
                    <button
                      class="btn btn-primary bootstrap-touchspin-up"
                      type="button"
                      onClick={() => this.incOrDecValue(i, "night")}
                    >
                      +
                    </button>
                  </span>
                </div>
              </div>
              {/* <div
                className="text-center"
                style={{
                  padding: "12px 0 12px 12px",
                  borderLeft: "1px solid #ccc",
                  margin: "-12px 0",
                }}
              > */}
                {record.unit}
              </div>
            // </div>
          );
        },
      },
      {
        key: "total",
        text: "Total",
        className: "total",
        sortable: true,
        align: "left",
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 20,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
  }
  // prescription code
  incOrDecValue(index, name, isDecrement = false) {
    const data = this.state.records;
    let value = parseInt(data[index][name]) || 0;
    console.log(value);
    if(value>1||value==1||value==0){
      if (!isDecrement) {
        data[index][name] = value + 1;
      } else if (value !== 0) {
        data[index][name] = value - 1;
      }
      // calculate total
      let total = 1;
  
      if (data[index].drug_type === 'Syrup' || data[index].drug_type === 'Ointment') {
        total = 1;
      } else {
        total =
          (parseInt(data[index].morning) || 0) +
          (parseInt(data[index].noon) || 0) +
          (parseInt(data[index].night) || 0);
        data[index].total = total * parseInt(data[index].days || 0);
      }
    } else {
      data.splice(index, 1);
    }
    


    this.setState({
      records: data,
    });
  }
  clearsearch()
  {
    const searchData = this.state.searchData;
    searchData.drug_name="";
    searchData.active_ingredients="";
    searchData.generic_name="";
    searchData.manufacturer="";
    searchData.drug_id="";
    this.setState({ searchData: searchData,searchRecords: [], PrescriptionerrorMessage: "" });
   
  }
  searchAndShowDrug() {
    const searchData = this.state.searchData;
    const consultData=this.state.consultData;
   
    let postData = {};
    postData.drug_name = searchData.drug_name ? searchData.drug_name.label : "";
    postData.drug_category = searchData.drug_category ? searchData.drug_category.label : "";
    postData.drug_type = searchData.drug_type ? searchData.drug_type.label : "";
    postData.user_id = consultData.business_id;
    postData.manufacturer = searchData.manufacturer;
    postData.active_ingredients = searchData.active_ingredients ? searchData.active_ingredients.label : "";
    postData.generic_name = searchData.generic_name ? searchData.generic_name.label : "";
    postData.drug_id = searchData.drug_id;
    postData.type = "user";
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios
        .post(searchDrug, postData, CONFIG)
        .then((res) => {
          this.setState({ loading: false });
          const result = res.data;
          if (result.countOfRecords === 0) {
            this.setState({errorMessage:"Drug not found in list", searchRecords: [] });
            return;
          }
          const records = result.data;
          const data = [];
          records.forEach((element) => {
            data.push({
              id: element.id,
              drug_id: element.drug_id,
              drug_name: element.drug_name,
              drug_category: element.drug_category,
              drug_type: element.drug_type,
              manufacturer: element.manufacturer,
              active_ingredients: element.active_ingredients,
              generic_name:element.generic_name,
              unit_of_measure: element.unit_of_measure,
            });
          });

          this.setState({ searchRecords: data });
            this.setState({successMessage: "Your Drug List has been listed successfully." });

        })
        .catch((e) => {
          this.setState({ searchRecords: [], loading: false });
        });
    });
    this.clearMessage();
  }


  isMedicineExist(record, drug_id) {
    let checkedvalue = false;
    record.forEach((element, index) => {
      if (element.medicine_id === drug_id) {
        checkedvalue = true;


      }
      
    })
    return checkedvalue;
  }
  addDrug() {
    const selectedDrugs = this.state.records;

    this.state.searchRecords.map((record) => {
      
      if (record.isSelected) {
             console.log(record.id);
        let isMedicineExist = this.isMedicineExist(selectedDrugs, record.id);
        console.log(isMedicineExist);
         if (selectedDrugs.indexOf())
        if (!isMedicineExist) {
          if (record.drug_type === 'Syrup' || record.drug_type === 'Ointment') {
            selectedDrugs.push({
              medicine_description: record.generic_name,
              drug_type: record.drug_type,
              medicine_id: record.id,
              days: 1,
              before_food: 0,
              morning: 0,
              noon: 0,
              night: 0,
              total: 0,
              unit: record.unit_of_measure,
            });
          
          } else {
            selectedDrugs.push({
              medicine_description: record.generic_name,
              drug_type: record.drug_type,
              medicine_id: record.id,
              days: 1,
              before_food: 0,
              morning: 0,
              noon: 0,
              night: 0,
              total: 0,
              unit: record.unit_of_measure,
            });
          
          }

          this.setState({ PrescriptionerrorMessage: "", records: selectedDrugs});
          this.setState({ PrescriptionsuccessMessage: "Your Drugs have been added successfully to the cart." });
        }else
        {
          this.setState({ PrescriptionsuccessMessage: "Drug already added to cart." });
        }
        
      }
    
    });
    this.clearMessage();
    this.clearsearch();
  }

 /*  generatetagforcolumn = (billing_record) => {
    if (consultation_fee !== billing_record.description) {
      return (
        <Select maxMenuHeight={120}
          placeholder=""
          isClearable={true}
          openMenuOnClick={false}
          value={billing_record.selectedSearchBoxValue}
          options={this.state.d}
          className="customselectbilling"
          onChange={(record) => {
            let records = this.state.records;
            var recIndex = findWithAttr(records, "s_no", billing_record.s_no);
            if (null != record) {
              records[recIndex].selectedSearchBoxValue = record;
              records[recIndex].fee = record.amount;
              records[recIndex].gst_rate = record.tax_percentage + " %";
              records[recIndex].gst_amount =
                (record.tax_percentage / 100) * record.amount;
              records[recIndex].total_amount =
                Number(records[recIndex].fee) +
                Number(records[recIndex].gst_amount);
            } else {
              records[recIndex].selectedSearchBoxValue = "";
              records[recIndex].fee = "";
              records[recIndex].gst_rate = "";
              records[recIndex].gst_amount = "";
              records[recIndex].total_amount = "";
            }

            console.log(recIndex);
            console.log(records.length - 1);
            if (recIndex === records.length - 1) {
              let default_rec = Object.assign({}, record);
              default_rec.s_no = records.length + 1;
              records.push(default_rec);
            }
            this.setState({ records }, () => {
              this.calculateSection();
            });
          }}
        />
      );
    } else {
      return billing_record.description;
    }
  };
 */
  submitPrescription() {
    const postData = this.state.consultData;
    postData.medicine_description = this.state.records;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios
        .post(consultDoctor, postData, CONFIG)
        .then((res) => {
          this.setState({ loading: false });
          const result = res.data;
          if (result.status === "1") {
            this.setState({ PrescriptionsuccessMessage:"Your Prescription details have been updated successfully." });
          } else {
            this.setState({
              PrescriptionerrorMessage:
                result.message || "Failed to submit prescription details",
            });
          }
          this.setState({ activeTab: "Follow up" })

        })
        .catch((e) => {
          this.setState({
            loading: false,
            errorMessage: "Failed to submit consultant details",
            activeTab: "Follow up"
          });
        });


      this.clearMessage();
    });
  }

  deleteRecord(record) {
  
        const selectedDrugs = this.state.records;
        console.log(selectedDrugs);
        const deleteIndex = selectedDrugs.indexOf(record);
        selectedDrugs.splice(deleteIndex, 1);
        this.setState({records: selectedDrugs });
        this.setState({ PrescriptionerrorMessage: "Your Drugs have been deleted successfully." });
        this.clearMessage();
  }

  handleTableCheckboxChange = (e) => {
    let data = this.state.records;
    let id = e.target.id.split("-")[1];
    data[id][e.target.name] = e.target.checked ? 1 : 0;
    this.setState({ records: data });
  };

  handleSymptomSearch = (e) => {
    let cloneSymptoms = this.state.cloneSymptoms;
    if (null == e.target.value || "" === e.target.value) {
      this.setState({
        symptoms: cloneSymptoms
      });
    } else {
      //let symptoms = this.state.symptoms;
      const filteredSymptopms = cloneSymptoms.filter(symptom => {
        return symptom.symptom_name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      this.setState({
        symptoms: filteredSymptopms
      });
    }


  };

  handleTableInput = (e, index) => {
    let data = this.state.records;
    let name = e.target.name;
    let value = e.target.value;
    data[index][name] = value;
    this.setState({ records: data });
  };

  handleCheckbox = (e) => {
    let data = this.state.searchRecords;
    let id = e.target.id.split("-")[1];

    let name = e.target.name;
    let value = e.target.checked;
    data[id][name] = value;
    this.setState({ searchRecords: data });

  };

  handleSearchInput = (e) => {
    let data = this.state.searchData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ searchData: data });
  };

  handleSearchSelect = (selectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = selectedOptions;
    this.setState({ searchData: data });
    //console.log(selectedOptions.label);
    
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    const checkinData = { id: this.props.match.params.id };
    axios
      .post(getCheckinDetails, checkinData, CONFIG)
      .then((res) => {
        this.setState({ checkinDetails: res.data.data })
      

        if (res.data.status === "1") {
          console.log(selectedOptions.label);
   
      axios
      .post(getAllActiveingredientsforDrugs,{user_id:res.data.data.business_id,drug_name:selectedOptions.label}, CONFIG)
      .then((ActiveIngredientdrugRespons) => {
        let ActiveIngredientNameList = ActiveIngredientdrugRespons.data.data;
        let ActiveIngredientList = [];
        ActiveIngredientNameList.forEach((element, index) => {
          ActiveIngredientList.push({
            value: element.id,
            label: element.active_ingredients,
            manufacturer: ""
            
          });
        });

        this.setState({ ActiveIngredientList });
      
      }).catch((e) => { });
    }  
    
  }).catch((e) => { });


  };
  handleSearchGenericNameselect= (selectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = selectedOptions;
    this.setState({ searchData: data });

    
  };
  handleSearchactiveingredientsSelect= (activeselectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = activeselectedOptions;
    this.setState({ searchData: data });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    const checkinData = { id: this.props.match.params.id };
    axios
      .post(getCheckinDetails, checkinData, CONFIG)
      .then((res) => {
        this.setState({ checkinDetails: res.data.data })
      

        if (res.data.status === "1") {
         
   
      axios
      .post(getAllgenericnameforactiveingredients,{user_id:res.data.data.business_id,active_ingredients:activeselectedOptions.label}, CONFIG)
      .then((GenericNameRespons) => {
        let GenericNameFullList = GenericNameRespons.data.data;
        let GenericNameList = [];
        GenericNameFullList.forEach((element, index) => {
          GenericNameList.push({
            value: element.id,
            label: element.generic_name,
            manufacturer: ""
            
          });
        });

        this.setState({ GenericNameList });
      
      }).catch((e) => { });
    }  
    
  }).catch((e) => { });


  };
  componentDidMount = () => {


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    const checkinData = { id: this.props.match.params.id };
    axios
      .post(getCheckinDetails, checkinData, CONFIG)
      .then((res) => {
        this.setState({ checkinDetails: res.data.data })


        if (res.data.status === "1") {

          console.log(this.state.checkinDetails);
          console.log(res.data.data);
          console.log(res.data.data.reason_for_visit);

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(getAllSymptom, { user_id: res.data.data.business_id }, CONFIG)
            .then((list) => {
              let symptomlist = list.data.data;

              symptomlist.forEach((element, index) => {
                let symptom = {};
                symptom.label = element.symptom_name;
                symptom.value = element.symptom_name;
                symptom.symptom_name = element.symptom_name;
                symptom.symptom_category = element.symptom_category;
                symptom.id = element.id;

                symptomlist[index] = symptom;
              });

              this.setState({ symptomlist: symptomlist });
            })
            .catch((e) => { });



          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(getMasterSymptoms, { user_id: res.data.data.business_id }, CONFIG)
            .then((list) => {
              let symptomCategory = list.data.data;
              let symptomCategorylist = [];
              symptomCategory.forEach((element) => {
                symptomCategorylist.push({
                  value: element.id,
                  label: element.symptom_category,
                });
              });
              var result = symptomCategorylist.reduce((unique, o) => {
                if (!unique.some(obj => obj.label === o.label)) {
                  unique.push(o);
                }
                return unique;
              }, []);

              result.push({ label: "Others", value: "0" })
              this.setState({ symptomCategory: result });
            })
            .catch((e) => { });

        }


      }).catch((e) => { });



    axios
      .post(getAllDrugTypes, null, CONFIG)
      .then((drugTypeResponse) => {
        let drugTypeList = drugTypeResponse.data.data;
        let drugTypes = [];
        drugTypeList.forEach((element, index) => {
          drugTypes.push({ value: element.id, label: element.drug_type });
        });

        this.setState({ drugTypes });
      })
      .catch((e) => { });






    axios
      .post(getAllMasterDisease, null, CONFIG)
      .then((list) => {
        const data = list.data.data;
        let diseaseList = [];
        data.forEach((element) => {
          diseaseList.push({
            value: element.disease_name,
            label: element.disease_name,
          });
        });

        this.setState({ diseaseList: diseaseList });
      })
      .catch((e) => { });

    // consultation codes

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    const postData = { id: this.props.match.params.id };
    axios
      .post(getPrescription, postData, CONFIG)
      .then((res) => {
        let data = res.data.data || res.data.date;
        if (res.data.status !== "1" || !data || !data[0]) {
          return;
        }
        console.log(data[0]);
        console.log(data[0].medicine_description);
        this.setState({
          consultData: data[0],
          records: data[0].medicine_description || [],
          consult: data[0] || [],
        });
      })
      .catch((e) => { });

    // get search select options
/*     axios
      .post(getAllMasterDrug, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugNameList = drugMasterRespons.data.data;
        let drugList = [];
        masterdrugNameList.forEach((element, index) => {
          drugList.push({
            value: element.id,
            label: element.drug_name,
            manufacturer: "",
            active_incredient: "",
          });
        });

        this.setState({ drugList });
      })
      .catch((e) => { });
 */
     const consultData=this.state.consultData;

     axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
  
    axios
      .post(getCheckinDetails, checkinData, CONFIG)
      .then((res) => {
        this.setState({ checkinDetails: res.data.data })
      

        if (res.data.status === "1") {

   
      axios
      .post(getAllHospitalDrug,{user_id:res.data.data.business_id}, CONFIG)
      .then((drugRespons) => {
        let drugNameList = drugRespons.data.data;
        let drugList = [];
        drugNameList.forEach((element, index) => {
          drugList.push({
            value: element.id,
            label: element.drug_name,
            manufacturer: "",
            active_incredient: "",
          });
        });

        this.setState({ drugList });
      
      }).catch((e) => { });

      axios
      .post(getAllActiveingredientsforhospital,{user_id:res.data.data.business_id}, CONFIG)
      .then((ActiveIngredientdrugRespons) => {
        let ActiveIngredientNameList = ActiveIngredientdrugRespons.data.data;
        let ActiveIngredientList = [];
        ActiveIngredientNameList.forEach((element, index) => {
          ActiveIngredientList.push({
            value: element.id,
            label: element.active_ingredients,
            manufacturer: ""
            
          });
        });

        this.setState({ ActiveIngredientList });
      }).catch((e) => { });

      axios
      .post(getAllgenericnameforhospital,{user_id:res.data.data.business_id}, CONFIG)
      .then((GenericNameRespons) => {
        let GenericNameFullList = GenericNameRespons.data.data;
        let GenericNameList = [];
        GenericNameFullList.forEach((element, index) => {
          GenericNameList.push({
            value: element.id,
            label: element.generic_name,
            manufacturer: ""
            
          });
        });

        this.setState({ GenericNameList });
      
      }).catch((e) => { });
    }  
    
  }).catch((e) => { });

    axios
      .post(getAllDrugCategories, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugCategoryResponse) => {
        let list = drugCategoryResponse.data.data;
        let drugCategories = [];
        list.forEach((element, index) => {
          drugCategories.push({
            value: element.id,
            label: element.drug_category,
          });
        });
        this.setState({ drugCategories });
      })
      .catch((e) => { });

     
  };

  searchAndShowSymptom(searchData, index) {

    let data = {};
    data.symptom_category = searchData.label;
    data.user_id = this.props.userdata.id;
     axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(getSymptomByCategory, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data;
        if (result.countOfRecords > 0) {
          let records = result.data;
          let symptoms = [];
          records.forEach((element) => {
            symptoms.push({
              symptom_name: element.symptom_name,
              id: element.id,
            });
          });
let options = [];
let consultsymp = this.state.consult.symptoms;
if(consultsymp){
  consultsymp.forEach((el, index) => {
    options.push(el.label);
    
  });

}
  
  
       let uniqueoptions = options.filter((c, index) => {
        return options.indexOf(c) === index;
    });


          this.setState({
            symptoms: symptoms,
            cloneSymptoms: symptoms,
            // consult: { symptoms: symptoms[0].symptom_name },
            activeIndex: index,
            selectedSymptoms: uniqueoptions,
          });
        } else {
          this.setState({
            symptoms: [],
            // consult: { symptoms: [] },
            activeIndex: index,
            // selectedSymptoms: [],
          });
        }
      });
    });
  }

  selectSymptom = (symptom, e) => {
    const options = this.state.selectedSymptoms;
    let index;

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      options.push(e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = options.indexOf(e.target.value);
      options.splice(index, 1);
    }
    let consult = this.state.consult;

    let selectedSymptoms = [];

    let consultsymp = consult.symptoms;
    if(consultsymp){
      consultsymp.forEach((el, index) => {
              options.push(el.label);              
            });  
    }
    

     let uniqueoptions = options.filter((c, index) => {
      return options.indexOf(c) === index;
  });
  uniqueoptions.forEach((e,i)=>{
    selectedSymptoms.push({label:e,value:e})
  })
    this.setState({ selectedSymptoms: uniqueoptions });

   
    consult.symptoms = selectedSymptoms;


    this.setState({
      consult,
    });
  };

  submitAssesment() {
    const data = this.state.consult;
    data.client_id = this.state.consultData.client_id; // TODO: replace this with actual client id
    data.business_id = this.state.consultData.business_id;
    data.id = this.props.match.params.id;
  
    
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(consultDoctor, data, CONFIG).then(
        (res) => {
          const result = res.data;
          if (result.status === "1") {
            this.setState({ successMessage:  "Your Assessment has been updated successfully.", consult: data });
          } else {
            this.setState({
              errorMessage:
                result.message || "Failed to submit consultant details",
              consult: data,
            });
          }

          this.setState({ loading: false, consult: data, activeTab: "Prescription" });
        },
        (err) => {
          this.setState({
            loading: false,
            errorMessage: "Failed to submit consultant details",
            consult: data,
            activeTab: "Prescription"
          });
        }
      );
    });
    this.clearMessage();
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          successMessage: "",
          errorMessage: "",
          PrescriptionsuccessMessage: "",
          PrescriptionerrorMessage: "",
        }),
      5000
    );
  };

  handleInput = (e) => {
    let data = this.state.consult;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ consult: data });
  };

  handleSelect = (selectedOptions, e) => {

    let data = this.state.consult;
    data[e.name] = selectedOptions;
    if (e.name === "symptoms") {
      

      
let options = [];


      let consultsymp = selectedOptions;
if(consultsymp){
  consultsymp.forEach((el, index) => {
    options.push(el.label);
    
  });

}
  
  
       let uniqueoptions = options.filter((c, index) => {
        return options.indexOf(c) === index;
    });

this.setState({selectedSymptoms:uniqueoptions})

     
    }
    this.setState({ consult: data });
  };
  activateTab(activateTab, e) {

    this.setState({ activeTab: activateTab });
  }
  CheckOut = (e) => {

    this.setState({ loading: true })
    axios.defaults.headers.common["Authorization"] = `Bearer ${this.props.access_token}`;
    let clientapppointmentId = this.state.checkinData.q_id;
    axios.post(getQEntryById, { q_id: clientapppointmentId }, CONFIG)
      .then((queue_data) => {

        if (queue_data.data.status === '1') {
          let queuein_data = queue_data.data.data[0]
          queuein_data.status = 'Check-Out'
          this.setState({ queue_data: queuein_data })
          let queuedata = {
            "n_business_id": queuein_data.business_id,
            "n_business_user_id": queuein_data.business_user_id,
            "n_schedule_day_id": queuein_data.schedule_day_id,
            "n_q_id": queuein_data.q_id,
            "n_status": "Check-Out",
            "n_schedule_type": "CHKOUT",
            "n_preference": "1"
          }

          // return false;
          //   {
          //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
          //     "n_schedule_day_id": "f4e6f51b-bdc8-11eb-97e3-0ad8e4601dac",   
          //     "n_status": "Check-Out",   
          //     "n_business_user_id": "ef98dcf0-bdc8-11eb-a631-39b54728a3e8",
          //     "n_schedule_type": "CHKOUT",
          //     "n_q_id":"2a8efbc1-035b-452c-96fd-f1f2f531885f",
          //     "n_preference":"1"
          // }
          const postData = this.state.consultData;
// console.log(postData); console.log(queuein_data);return false;
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(UpdateQueueForCheckOut, queuedata)
            .then((response) => {





            let reqdata = {
                "to" : "elangamanir@gmail.com",
                "subject" : "Followup Remainder",
                "mailHeader" : "Followup Remainder",
                "message": "Hi Name, Your have followup remainder in 2 days in myhos for checkup. "
            }
            let notificationdata = {
              "message_code": "FOLLOW_UP",
              "language_code": "en",
              "data": {
                "patient_name": queuein_data.first_name,
                "followup_days": reqdata.message,
                "business_name":"",
                // patient_name,followup_days,business_name
        
              },
              "receiver": {
                "phoneNumber": "+919944053296"
              }
            };
            console.log(reqdata);
              // console.log(this.props.userdata);
              
              axios
                .post(sendemail, reqdata)
                .then((res) => {
                  let resdata = res.data;                            
                  axios
                  .post(sendsms, notificationdata)
                  .then((result) => {
                    console.log(result)
                  }).catch((err) => {
                    console.log(err);
                  })
                  console.log(resdata);
                  this.setState({ loading: false });
                  let data = {
                    message:"",
                    status:""
                  }
                  if (resdata===true) {
                    data.error_type = "success";
                    data.message = "Referral email send successfully"
                    data.status = "1"
                    this.setState({
                      formSuccess: data,
                    });
                    this.setState({ loading: false });
                    this.clearMessage();
                  } else {
                    data.error_type = "error";
                    data.message = "Failed"
                    data.status = "0"
                    this.setState({
                      formSuccess: data,
                    });
                    this.setState({ loading: false });
                    this.clearMessage();
                  }
                })
                .catch((e) => { });




              let data = { id: this.props.match.params.id, business_id: queuein_data.business_id };
              axios.post(updatePrescription, data, CONFIG).then((list) => {
                return false

              }).catch((e) => {
                console.log("Update queue failed");
                toast.error("Check out could not be Completed.",{
                  className:"toast-error"
                });
              });
              this.setState({ loading: false })


             // this.setState({ QueuesuccessMsg: "Queue status updated successfully" });
             toast.success("The Consultation was completed successfully.",{
              className:"toast-success"
            });
           

              window.location.href = "/consult";
              // this.props.history.push("/consult");

            }).catch((e) => {
              console.log("Update queue failed");
            });
        }
      }).catch((e) => {
        console.log("Get appoinment by id failed");
      });

  }
  render() {
    const {
      symptomCategory,
      consult,
      successMessage,
      errorMessage,
      PrescriptionerrorMessage, PrescriptionsuccessMessage,
      loading,
      diseaseList,
      symptomlist,
      activeIndex,

      //prescriptionss code
      searchData,
      searchRecords,
      drugUnits,
      drugList,
      ActiveIngredientList,
      GenericNameList,
      drugCategories,
      drugTypes,
      QueuesuccessMsg,
      checkinDetails,
      consultData
    } = this.state;
    return (
      <div id="layout-wrapper" >
        <input type="hidden" id="usertype" value="doctor" />
       {/*  <Spinner loading={loading} /> */}
       {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage">
            <ConsultHeader user_id={this.props.userdata.id} id={this.props.match.params.id} />
            <div className="main-box">
              <div className="sidemenu">
                <SideBar />
              </div>

              <section className="main-body hospital-profilegap">
              <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                <div className="container-fluid">
                
                  <div className="checkout-tabs">
                    <div className="row">

                      <div className="col-sm-12 pl0 c-page-wrapper">

                        <div className="col-sm-12">
                          <div className="hmeclr cnpag bg-transparent  pb-12">
                            <ul className="nav nav-pills manag ctab" role="tablist">

                              <li
                                className="nav-item waves-effect waves-light"
                                onClick={this.activateTab.bind(this, "Assessment")}
                              >
                                <a
                                  className={
                                    this.state.activeTab === "Assessment"
                                      ? "nav-link  btnview w-auto active b-r-25"
                                      : "nav-link  btnview w-auto b-r-25"
                                  }
                                  // className="nav-link  btnview w-auto"
                                  href="#"
                                >
                                  <span className="d-block d-sm-none">
                                    {" "}
                                    Assessment{" "}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    {" "}
                                    Assessment{" "}
                                  </span>
                                </a>
                              </li>
                              <li
                                className="nav-item waves-effect waves-light"
                                onClick={this.activateTab.bind(
                                  this,
                                  "Prescription"
                                )}
                              >
                                <a
                                  className={
                                    this.state.activeTab === "Prescription"
                                      ? "nav-link  btnview w-auto active b-r-25"
                                      : "nav-link  btnview w-auto b-r-25"
                                  }
                                  // className="btnview active nav-link w-auto"
                                  href="#"
                                >
                                  <span className="d-block d-sm-none">
                                    Prescription{" "}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    Prescription{" "}
                                  </span>
                                </a>
                              </li>
                              <li
                                className="nav-item waves-effect waves-light"
                                onClick={this.activateTab.bind(
                                  this,
                                  "Follow up"
                                )}
                              >
                                <a
                                  className={
                                    this.state.activeTab === "Follow up"
                                      ? "nav-link  btnview w-auto active b-r-25"
                                      : "nav-link  btnview w-auto b-r-25"
                                  }
                                  href="#"
                                >
                                  <span className="d-block d-sm-none">
                                    Follow up{" "}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    Follow up{" "}
                                  </span>
                                </a>
                              </li>
                              <li
                                className="nav-item waves-effect waves-light"
                                onClick={this.activateTab.bind(
                                  this,
                                  "Referral Doctor"
                                )}
                              >
                                <a
                                  className={
                                    this.state.activeTab === "Referral Doctor"
                                      ? "nav-link  btnview w-auto active b-r-25"
                                      : "nav-link  btnview w-auto b-r-25"
                                  }
                                  href="#"
                                >
                                  <span className="d-block d-sm-none">
                                  Referral Doctor{" "}
                                  </span>
                                  <span className="d-none d-sm-block">
                                  Referral Doctor{" "}
                                  </span>
                                </a>
                              </li>
                     
                              <li
                                className="nav-item waves-effect waves-light"
                                onClick={this.activateTab.bind(this, "History")}
                              >
                                <a
                                  className={
                                    this.state.activeTab === "History"
                                      ? "nav-link  btnview w-auto active b-r-25"
                                      : "nav-link  btnview w-auto b-r-25"
                                  }
                                  href="#"
                                >
                                  <span className="d-block d-sm-none">
                                    History{" "}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    History{" "}
                                  </span>
                                </a>
                              </li>
                            </ul>
                           
                          </div>
                          
                          {QueuesuccessMsg ? (<div className="abtn">
                            <SpanField
                              classname="text-success  mt-2"
                              title={QueuesuccessMsg}
                            /></div>
                          ) : ""}
                        </div>

                        
                        <Fragment>
                          <div
                            className="row"
                            style={{
                              display:
                                this.state.activeTab === "Follow up"
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <FollowupDetails id={this.props.match.params.id} onPreviousClick={this.activateTab.bind(this, "Prescription")} onNextClick={this.activateTab.bind(this, "Referral Doctor")} />
                          </div>
                          <div
                            className="row"
                            style={{
                              display:
                                this.state.activeTab === "Referral Doctor"
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <ReferralDoctor id={this.props.match.params.id} onPreviousClick={this.activateTab.bind(this, "Follow up")} onNextClick={this.activateTab.bind(this, "History")} />
                          </div>
                          <div
                            className="row"
                            style={{
                              display:
                                this.state.activeTab === "Assessment"
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <div className="col-sm-9">
                              <div className="row">
                                <div className="col-sm-8">
                                  <div className="card h-100">
                                  <div class="card-header"> <div class="card-title">Reason for Visit</div> </div>
                                    <div className="card-body ">

                                      <div
                                        className="tab-content"
                                        id="v-pills-tabContent"
                                      >
                                        <div
                                          className="tab-pane fade show active"
                                          id="v-pills-shipping"
                                          role="tabpanel"
                                          aria-labelledby="v-pills-shipping-tab"
                                        >
                                          <div className="row">
                                            <div className="col-sm-12">
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Diagnosis</label>
                                                </div>
                                                <div className="form-group sform-control frlrit">
                                                  <div className="input-group ">
                                                    <Select maxMenuHeight={200}
                                                      isClearable={true}
                                                      className="w-100 p-0"
                                                      name="reason_for_visit"
                                                      placeholder="Search"
                                                      isMulti
                                                      value={
                                                        consult.reason_for_visit
                                                      }
                                                      onChange={this.handleSelect}
                                                      options={diseaseList}
                                                     
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-sm-12">
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Symptoms</label>
                                                </div>
                                                <div className="form-group sform-control frlrit ">
                                                  <div className="input-group ">
                                                    <Select maxMenuHeight={120}
                                                      isClearable={true}
                                                      className="form-control p-0"
                                                      placeholder="Search"
                                                      name="symptoms"
                                                      isMulti
                                                      value={consult.symptoms}
                                                      onChange={this.handleSelect}
                                                      options={symptomlist}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="tab-content m-t-25"
                                        id="v-pills-tabContent"
                                      >
                                        <div
                                          className="tab-pane fade show active"
                                          id="v-pills-shipping"
                                          role="tabpanel"
                                          aria-labelledby="v-pills-shipping-tab"
                                        >
                                          <div className="row">
                                            <div className="col-sm-12">
                                             
                                              <TextArea
                                                style={{ minHeight: 100 }}
                                                name={"diagnosis"}
                                                controlFunc={this.handleInput}
                                                content={consult.other_comments}
                                                maxLength={100}
                                              />

                                              <div className="text-center">
                                                <button
                                                  className=" signupbtn  pad-6 margin-10 float-right"
                                                  onClick={() =>
                                                    this.submitAssesment()
                                                  }
                                                >
                                                  Next
                                                </button>
                                               
                                                
                                              </div>
                                              <div className="user-notification">
                                                <SpanField
                                                  classname="text-success mt-2"
                                                  title={successMessage}
                                                />
                                                <SpanField
                                                  classname="text-danger mt-2"
                                                  title={errorMessage}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>

                                </div>
                                <div className="col-sm-4">
                                  <div className="card mb-0">
                                  <div class="card-header"> <div class="card-title">Symptom List</div> </div>
                                    <div className="card-body ">
                                      <div
                                        className="tab-content"
                                        id="v-pills-tabContent"
                                      >
                                        <div
                                          className="tab-pane fade show active"
                                          id="v-pills-shipping"
                                          role="tabpanel"
                                          aria-labelledby="v-pills-shipping-tab"
                                        >
                                          <div
                                            className="card"

                                          >
                                           
                                            <div className="input-group">
                                              <InputField
                                                inputType={"text"}
                                                name={"symptomSearch"}
                                                controlFunc={this.handleSymptomSearch}
                                                content={
                                                  this.state.symptomSearch
                                                }
                                                placeholder="Search Symptom List"
                                              />
                                            </div>



                                          </div>
                                          <div className="row Symptomsymtomsscrol">

                                            <div className="col-sm-12 c-pagebutton">
                                 
                                              {this.state.symptoms.map((symptom, i) => {
                                                return (
                                                  <div className="custom-control custom-checkbox mb-1">
                                                    <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      value={symptom.symptom_name}
                                                      id={symptom.id}
                                                      onClick={this.selectSymptom.bind(
                                                        this,
                                                        symptom
                                                      )}
                                                      checked={this.state.selectedSymptoms.indexOf(symptom.symptom_name) > -1}

                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      for={symptom.id}
                                                    >
                                                      {symptom.symptom_name}
                                                    </label>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-3 pl0">
                              <div className="card mb-0">
                             <div class="card-header"> <div class="card-title">Symptom Category</div> </div>
                                <div className="card-body">
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                        <div id="accordion">
                                            <div className="card mb-1 shadow-none">
                                              
                                              <div className=" systemresultscrol">
                                                <div className="c-pagebutton c-pagebtn1">
                                                  {symptomCategory.map(
                                                    (symptom, index) => {
                                                      return (
                                                        <button
                                                          className={
                                                            (activeIndex === index && activeIndex !== '')
                                                              ? "active"
                                                              : ""
                                                          }
                                                          onClick={() =>
                                                            this.searchAndShowSymptom(
                                                              symptom,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          {symptom.label}
                                                        </button>
                                                      );
                                                    }
                                                  )}

                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                  

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div
                            class="row"
                            className="row"
                            style={{
                              display:
                                this.state.activeTab === "Prescription"
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <div class="col-sm-9 pr0">
                             
                              <div class="card h484">
                              <div class="card-header d-flex align-items-center justify-content-between"> 
                                 <div class="card-title">Prescription</div> 
                                 
                              </div>
                                <div class="card-body ">
                                  <div
                                    class="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      class="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div className="user-notification">
                                            <SpanField
                                              classname="text-success mt-2"
                                              title={PrescriptionsuccessMessage}
                                            />
                                            <SpanField
                                              classname="text-danger mt-2"
                                              title={PrescriptionerrorMessage}
                                            />

                                          </div>
                                        <div className="doctorconsultTable" >
                                        
                                           <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            className="table table-bordered table-striped kas  mobile_table"
                                          />
                                        </div>

                                        {/* <div className="d-flex justify-content-center"> */}
                                        <div className="">
                                        <div class="text-center">
                                          <button
                                                  className=" signupbtn  pad-6 mt-0 float-left mg-top"
                                                  onClick={this.activateTab.bind(this, "Assessment")}
                                                >
                                                  Previous
                                                </button>
                                            </div>
                                        <div class="text-center ml-2 float-right mg-top">
                                            <button
                                              className=" signupbtn  pad-6 mt-0 "
                                              onClick={() =>
                                                this.submitPrescription()
                                              }
                                            >
                                              Next
                                            </button>
                                          </div>
                                          
                                        </div>
                                        

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-3 pl0">
                           
                              <div class="card searchDrug">
                              <div class="card-header"> <div class="card-title">Search Drug</div> </div>
                                <div class="card-body">
                                  <div
                                    class="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      class="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                       <div className="user-notification">
                                                <SpanField
                                                  classname="text-success mt-2"
                                                  title={successMessage}
                                                />
                                                <SpanField
                                                  classname="text-danger mt-2"
                                                  title={errorMessage}
                                                />
                                              </div>
                                      <div class="row">
                                        <div class="col-sm-12">

                                      {/*     <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              className="selectcls p-0"
                                              name="drug_type"
                                              placeholder="Drug Type"
                                              isMandatory={true}
                                              value={searchData.drug_type}
                                              onChange={this.handleSearchSelect}
                                              options={drugTypes}
                                            />
                                          </div> */}
                                          <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <InputField
                                              inputType={"text"}
                                              name={"manufacturer"}
                                              controlFunc={this.handleSearchInput}
                                              content={searchData.manufacturer}
                                              placeholder="Manufacturer"
                                            />
                                          </div>
                                          <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              className="selectcls p-0"
                                              name="drug_name"
                                              placeholder="Drug Name"
                                              isMandatory={true}
                                              value={searchData.drug_name}
                                              onChange={this.handleSearchSelect}
                                              options={drugList}
                                            />
                                          </div>
                                          <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              className="selectcls p-0"
                                              name="active_ingredients"
                                              placeholder="Active Ingredients"
                                              isMandatory={true}
                                              value={searchData.active_ingredients}
                                              onChange={this.handleSearchactiveingredientsSelect}
                                              options={ActiveIngredientList}
                                            />
                                          </div>
                                          <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              className="selectcls p-0"
                                              name="generic_name"
                                              placeholder="Generic Name"
                                              isMandatory={true}
                                              value={searchData.generic_name}
                                              onChange={this.handleSearchGenericNameselect}
                                              options={GenericNameList}
                                            />
                                          </div>
                                       {/*    <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              placeholder="Drug Category"
                                              className="selectcls p-0"
                                              name="drug_category"
                                              isMandatory={true}
                                              value={searchData.drug_category}
                                              onChange={this.handleSearchSelect}
                                              options={drugCategories}
                                            />
                                          </div> */}
                                          {/* <div class="input-group mb-10">
                                          <span class="input-group-addon">
                                            <i class="glyphicon fa fa-search icontextb" />
                                          </span>
                                          <Select
                                            isClearable={true}
                                            className="selectcls p-0"
                                            name="drug_unit"
                                            isMandatory={true}
                                            value={searchData.drug_unit}
                                            onChange={this.handleSearchSelect}
                                            options={drugUnits}
                                          />
                                        </div> */}
                                          
                                          <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <InputField
                                              inputType={"text"}
                                              name={"drug_id"}
                                              controlFunc={this.handleSearchInput}
                                              content={
                                                searchData.drug_id
                                              }
                                              placeholder={"Drug Id"}
                                            />
                                          </div>
                                          <div class="text-center">
                                            <button
                                              className="float-center signupbtn pad-6 margin-10"

                                              onClick={() =>
                                                this.searchAndShowDrug()
                                              }
                                            >
                                              Search
                                            </button>
                                            <button
                                              className="float-center signupbtn pad-6 margin-10"

                                              onClick={() =>
                                                this.clearsearch()
                                              }
                                            >
                                              clear
                                            </button>
                                          </div>
                                          <div class="card-body">
                                            <p>Click the Checkbox to add the drugs.</p>
                                            {searchRecords.map((drug, i) => {
                                              return (
                                                <div class="custom-control custom-checkbox mb-1">
                                                  <input
                                                    type="checkbox"
                                                    name="isSelected"
                                                    class="custom-control-input"
                                                    id={"drug-" + i}
                                                    onChange={this.handleCheckbox}
                                                  />
                                                  <label
                                                    class="custom-control-label"
                                                    for={"drug-" + i}
                                                  >
                                                    {drug.generic_name}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          </div>
                                          {searchRecords &&
                                            searchRecords.length ? (
                                            <div class="adcrt mb-2">
                                              <button
                                                onClick={() => this.addDrug()}
                                              >
                                                <i
                                                  class="fa fa-shopping-cart"
                                                  aria-hidden="true"
                                                />
                                                &nbsp;Add to Cart
                                              </button>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{
                              display:
                                this.state.activeTab === "History"
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <ConsultationHistory id={this.props.match.params.id} user_id={this.state.consultData ? this.state.consultData.client_id : ""} /></div>

                        </Fragment>
                        {/* <Footerad /> */}

                      </div>
                    </div>
                   
                  </div>


                </div>
           

              </section>
    <FooterSection />
            </div>

          </div>
        </div>
      </div>

    );
  }
}

export default connect(mapStateToProps)(Consult);
