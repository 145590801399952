import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


function SideBar(props) {
    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem("token");
        props.logout();
        props.history.push("/");
    };
    const [click, setClick] = React.useState(false);
    const handleClick = () => setClick(!click);
    return (
        <div className={click ? "sidebar-toggleon toggle" : " toggle"} >
        <button  onClick={()=>handleClick()} ><i className="fas fa-bars"></i></button>
        <div className="vertical-menu displaymobilenone">
            <div data-simplebar="">
                {/* <!--- Sidemenu --> */}
                <div id="sidebar-menu">
                    {/*   <!-- Left Menu Start -->*/}
                    <ul className="metismenu list-unstyled" id="side-menu">

                        <li className="sidmnl">
                            <a
                                href={"/admindashboard"}
                                className="waves-effect"
                                aria-expanded="false"
                            >
                                        <i className="fa-solid fa-house"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href={"/news_feeds"}
                                className="waves-effect"
                                aria-expanded="false"
                            >
                              <i className="fas fa-newspaper"></i>
                                <span>News Feeds</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href={"/offers"}
                                className="waves-effect"
                                aria-expanded="false"
                            >
                                     <i className="fas fa-percent"></i>
                                <span>Offers</span>
                            </a>
                        </li>

                        <li className="sidmnl">
                            <a href="/footeradverdisement" className="waves-effect"
                                aria-expanded="false">
                              <i className="fas fa-ad"></i>
                                <span>Footer Advertisement</span>

                            </a>
                        </li>
                        <li className="sidmnl">
                            <a href="/notification" className="waves-effect"
                                aria-expanded="false">
                            <i className="fas fa-bell"></i>
                                <span>Notifications</span>

                            </a>
                        </li>

                        <li className="sidmnl">
                            <a href="/promocode" className="waves-effect"
                                aria-expanded="false">
                             <i className="fab fa-codepen"></i>
                                <span>
                                    Promocode</span>

                            </a>
                        </li>

                        <li className="sidmnl">
                            <a
                                href="/drugimport"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                            <i className="fas fa-file-import"></i>
                                <span>Master Drug Import</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/specialitylist"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                              <i className="fas fa-tasks-alt"></i>
                                <span>Speciality Management</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/symptomimport"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                            <i className="fas fa-child"></i>
                                <span>Master Symptom Import</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/subscriptionplan"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                              <i className="fas fa-hand-sparkles"></i>
                                <span>Subscription Plan</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/vendorform"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                              <i className="fas fa-ad"></i>
                                <span>Advertisement Management</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/hospitallist"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                               <i className="fas fa-hospital"></i>
                                <span> Hospital List</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/doctorlist"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                              <i className="fas fa-user-md"></i>
                                <span> Doctor List</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/patientlist"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                             <i className="fas fa-user-injured"></i>
                                <span> Patient List</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/adminpharmacylist"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                                <i className="fas fa-prescription-bottle-alt"></i>
                                <span> Pharmacy List</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/adminlablist"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                                <i className="fas fa-vials"></i>
                                <span> Lab List</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/timeout"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                              <i className="fas fa-user-clock"></i>
                                <span> Timeout</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/doctorcount"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                              <i className="fas fa-user-clock"></i>
                                <span> Doctor Count Subscription</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/Transfertype"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                             <i className="fas fa-exchange-alt"></i>
                                <span>Transfer type for Stock</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/QueueReport"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                             <i className="fas fa-notes-medical"></i>
                                <span>Queue Report</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/AppointmentReport"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                             <i className="fas fa-calendar-check"></i>
                                <span>Appointment Report</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/QueueCompletedReport"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                        <i className="fas fa-sticky-note"></i>
                                <span> Queue Completed Report</span>
                            </a>
                        </li>
                        <li className="sidmnl">
                            <a
                                href="/AppointmentCompletedReport"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                       <i className="fas fa-clipboard"></i>
                                <span> Appt Completed Report</span>
                            </a>
                        </li>

                        <li className="sidmnl">
                            <a
                                href="/videoupload"
                                className=" waves-effect"
                                aria-expanded="false"
                            >
                        <i className="fas fa-video"></i>
                                <span> Video Upload</span>
                            </a>
                        </li>

                        <li className="sidmnl sidmnlss">
                            <a
                                href="/#"
                                className="waves-effect"
                                aria-expanded="false"
                                onClick={handleLogout}
                            >
                              <i className="fas fa-sign-out-alt"></i>
                                <span>Sign out</span>
                            </a>
                        </li>
                    </ul>
                </div>
                {/*<!-- Sidebar -->*/}
            </div>
        </div >
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loggedIn: state.AuthReducer.loggedIn,
        user: state.AuthReducer.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SideBar)
);
