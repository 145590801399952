import React, { Component } from "react";
import { connect } from "react-redux";
import StarRatings from "react-star-ratings";
import axios from "axios";
import { getBusinessuserShortDetail,updateavatar,getAdvertisementData } from "../../../../config/url.json";
import {getProfilePercentage} from "../../../../constants/globalLevelFunctions";
import { CONFIG,imageconfig } from "../../../../config/data";
import { ProfilePicModel } from "../../../../components/UI/ProfilePicModel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import S3 from "react-aws-s3";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.options = {
      autoplay: true,
      loop: true,
      nav:false,
      items:1,
      dots:false,
  }
    this.state = {
      access_token: props.access_token,
      adverdisement:[],

      headerinfo: {
        profile_name: "",
        qualification: "",
        specialization: "",
        work_place: "",
        id: "",
        languages_known: "",
        profile_percentage: "",
        rating: "",
        profile_percentage_style:"",
        avatar: "",
        first_name:"",
        last_name:"",
        address1:"",
        city:"",
        zip_code:"",
        doctor_id:"",
        language:""

        
        
      },
    };
  }
  componentDidMount() {
    let loggedInuserId = this.props.user_id;

    window.setTimeout(() => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(
          getBusinessuserShortDetail,
          { business_user_id: loggedInuserId },
          CONFIG
        )
        .then((short_details) => {
          console.log("business short");
          console.log(short_details);

          if (short_details.data.status === "1") {
            let info = short_details.data.data;
            console.log(info);
            info.profile_percentage_style = getProfilePercentage(info.profile_percentage)
            this.setState({ headerinfo: info });
            console.log(this.state.headerinfo);
          }
        })
        .catch((e) => {
          console.log("business short info failed");
        });


        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
  
        axios
          .post(getAdvertisementData, null, CONFIG)
          .then((response) => {
          //  console.log(response.data)
  
            if (response.data.status === "1") {
              // let info = short_details.data.data[0];
              // info.profile_percentage_style = getProfilePercentage(info.profile_percentage);
              
              this.setState({ adverdisement: response.data.data, loading: false });
              
            }
          })
          .catch((e) => {
            console.log("vendor info failed");
          });
    }, 1000);
  }
  startWebCamera = event => {
    this.setState({openWebCamera: true});
  }

  imageSelectHandler = event =>{

    let files = event.target.files || event.dataTransfer.files;
            if (!files.length) {
                console.log('no files');
                return false;
            }
            console.log(files);
            console.log(files[0]);
   

            let file = event.target.files[0];
            let newfileName = event.target.files[0].name;

        // console.log(imageconfig);
            imageconfig.dirName = 'client/'+ this.props.userdata.id
            console.log(imageconfig);

            const reacts3client = new S3(imageconfig);
            console.log(reacts3client)
            reacts3client.uploadFile(file, newfileName).then(data => {
            console.log(data)
            if (data.status === 204) {
                console.log('success');
                let resultdata = {
                  'avatar':data.location,
                  'user_id':this.props.userdata.id,
                  'user_type':"business_user"
                }
                // axios.defaults.headers.common[
                //   "Authorization"
                // ] = `Bearer ${this.props.access_token}`;
                axios.post(updateavatar,resultdata, CONFIG).then((result)=>{
                  let headerinfo = this.state.headerinfo;
                  headerinfo.avatar = data.location;
                  this.setState({headerinfo:headerinfo, addPopup: false});
                  toast.success("Your profile picture has successfully updated.",{
                    className:"toast-success"
                  });
                })    
              
            } else {
                console.log('error');
                // this.setState({profile_img: URL.createObjectURL(file), addPopup: false});
            }
        })
    
  }
  handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
    console.log(dataUri);
    const data = {
      file: dataUri
    };

    let reader = new FileReader();
     
    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result
      });
    }
 
    // reader.readAsDataURL(dataUri)

 console.log(this.state.selectedFile);

    var fd = new FormData();
 
    fd.append('file', this.state.selectedFile);
 
    var request = new XMLHttpRequest();
 
    request.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        alert('Uploaded!');
      }
    };
    // request.open("POST", uploadImage+'?user_id='+this.props.userdata.id, true);
    request.send(fd);


  }
  editImage = event =>{
    this.setState({ addPopup: true});
  }
  render() {
    const { headerinfo,adverdisement } = this.state;
    let PopupClose = () => this.setState({ addPopup: false, openWebCamera: false});

    let ratestar = headerinfo ? headerinfo.rating : 0;
    return (
      <section className="my-head2">
      
      <div className="row header-bg">
        <div className="col-sm-2 text-left">
          <div className=" logocenter">
            <a href="#">
              {" "}
              <img
              src={require("../../../../assets/images/logo.png")}
                alt="Logo"
              />
            </a>
          </div>
        </div>
       
      
        <div className="col-sm-5">
        <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} showToast={true} closeOnClick={false} pauseOnHover />

        <ProfilePicModel
                      show={this.state.addPopup}
                      onHide={PopupClose}
                      data={{imageSelection:this.imageSelectHandler, 
                        openWebCamera:this.state.openWebCamera,
                        startWebCamera:this.startWebCamera.bind(this),
                            takePicture:this.handleTakePhotoAnimationDone.bind(this)}}
                      title={"Select Profile Picture"}
                      message={"Are you sure you want to delete this record?"}
                    />
          <div className="grids justify-content-between">
          <div className="doctorimg d-flex">
            <div className="position-relative">
            <img className="profileImage img-circle" id="profileImage"
                      alt="Profile"
                      src={
                        headerinfo.avatar
                          ? headerinfo.avatar
                          : require("../../../../assets/images/user1.jpg")
                      }
                    />
                       <div className="p-image p-imagedoctor">
                    <i className="fa fa-camera upload-button"  onClick={this.editImage}></i>
                    
                  </div>
            </div>
                  
                  <div className="doctorprofile ">
                <h4 style={{ marginBottom: "5px" }}>
                  {headerinfo.first_name} {headerinfo.last_name}
                  {/* <StarRatings
                    rating={ratestar ? ratestar : 0}
                    starRatedColor="#ebb338"
                    starDimension="15px"
                    starSpacing="3px"
                    numberOfStars={5}
                    name="rating"
                  /> */}
                </h4>
               
                <h5>
                  <span className="witfnt">{headerinfo.qualification}</span>
                </h5>
                <h5>
                  {headerinfo.address1},{headerinfo.city} -{headerinfo.zip_code}
                </h5>
                <h5> {headerinfo.speciality_name} </h5>
                <h5> Doctor ID :: {headerinfo.doctor_id} </h5>
                {/* <h6>
                  <i className="fa fa-language" aria-hidden="true"></i>
                  <span> {headerinfo.language} </span>
                </h6> */}
              </div>
                  </div>
                  <div className="strengthprofile22 ">
                    
                <p className="strgth100">{headerinfo.profile_percentage}</p>
                <div className="stengthback">
                  <div className="dropback ">
                    <div className="bg-wit" style={{ height: headerinfo.profile_percentage_style }}>
                      <img
                        src={require("../../../../assets/images/profile-strength.png")}
                        alt="profile-strength"
                        className="strengthprofile"
                      />
                    </div>
                  </div>
                </div>
                
                <span className="witfnt">Profile</span>
                 </div>
         

          

          </div>
        </div> 
        <div className="col-sm-5">
        {(adverdisement.length ? (<OwlCarousel className="owl-theme" {...this.options} loop margin={10} nav>
        {adverdisement.map((data, i) => {     
         return( <div key={i} className="item">
          <img src={data.vendor_photo} className="advertisment img-fluid" alt={data.vendor_name} />
        </div>)
        })}
        
      </OwlCarousel>):(<img
            src={require("../../../../assets/images/advertismenttop.jpg")}
            alt="advertisement"
            className="advertisment"
          />))}
        </div>


       
      </div>
     
      </section>
    );
  }
}

export default connect(mapStateToProps)(Header);
