import React, { Component } from "react";
import { connect } from "react-redux";
import StarRatings from "react-star-ratings";
//import starRating from '../UI/starRating';
import { getClientShortDetail,updateavatar,getAdvertisementData } from "../../../../config/url.json";
import { AUTH_CONFIG, CONFIG, imageconfig } from "../../../../config/data";
import {getProfilePercentage} from "../../../../constants/globalLevelFunctions";
import { ProfilePicModel } from "../../../../components/UI/ProfilePicModel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import S3 from "react-aws-s3";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function mapStateToProps(state) {
  // console.log(state);

  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.options = {
      autoplay: true,
      loop: true,
      nav:false,
      items:1,
      dots:false,
  }
    this.state = {
      adverdisement:[],
      access_token: props.access_token,
      headerInfo: {
        avatar: "",
        full_name: "",
        registeration_id: "",
        blood_group: "",
        profile_percentage_style:"",
        profile_percentage:""
        
      },
    };
  }
  componentDidMount() {
    let loggedInuserId = this.props.user_id;

    // axios.defaults.headers.common["Authorization"] = `Bearer ${this.state.access_token}`;

    window.setTimeout(() => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      axios
        .post(getClientShortDetail, { client_id: loggedInuserId })
        .then((short_details) => {
          if (short_details.data.status === "1") {
            let info = short_details.data.data[0];
            let headerInfo = this.state.headerInfo;
            headerInfo.avatar = info.avatar;
            headerInfo.full_name = info.first_name + " " + info.last_name;
            headerInfo.registeration_id = info.patient_id;
            headerInfo.blood_group = info.blood_group;
            headerInfo.profile_percentage = info.profile_percentage;
            headerInfo.profile_percentage_style = getProfilePercentage(info.profile_percentage)
            
            headerInfo.avatar = info.avatar;
            this.setState({ headerInfo });
          }
        })
        .catch((e) => {
          console.log("client short info failed");
        });


        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
  
        axios
          .post(getAdvertisementData, null, CONFIG)
          .then((response) => {
          //  console.log(response.data)
  
            if (response.data.status === "1") {
              // let info = short_details.data.data[0];
              // info.profile_percentage_style = getProfilePercentage(info.profile_percentage);
              
              this.setState({ adverdisement: response.data.data, loading: false });
              
            }
          })
          .catch((e) => {
            console.log("vendor info failed");
          });


    }, 1000);
  }
  startWebCamera = event => {
    this.setState({openWebCamera: true});
  }

  imageSelectHandler = event =>{

    let files = event.target.files || event.dataTransfer.files;
            if (!files.length) {
                console.log('no files');
                return false;
            }
            console.log(files);
            console.log(files[0]);
   

            let file = event.target.files[0];
            let newfileName = event.target.files[0].name;

        // console.log(imageconfig);
            imageconfig.dirName = 'client/'+ this.props.userdata.id
            console.log(imageconfig);

            const reacts3client = new S3(imageconfig);
            console.log(reacts3client)
            reacts3client.uploadFile(file, newfileName).then(data => {
            console.log(data)
            if (data.status === 204) {
                console.log('success');
                let resultdata = {
                  'avatar':data.location,
                  'user_id':this.props.userdata.id,
                  'user_type':"client"
                }
                // axios.defaults.headers.common[
                //   "Authorization"
                // ] = `Bearer ${this.props.access_token}`;
                axios.post(updateavatar,resultdata, CONFIG).then((result)=>{
                  let headerInfo = this.state.headerInfo;
                  headerInfo.avatar = data.location;
                  this.setState({headerInfo:headerInfo, addPopup: false});
                  toast.success("Your profile picture has successfully updated.",{
                    className:"toast-success"
                  });   
                }) 
               
            } else {
                console.log('error');
                // this.setState({profile_img: URL.createObjectURL(file), addPopup: false});
            }
        })
    
  }
  handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
    console.log(dataUri);
    const data = {
      file: dataUri
    };

    let reader = new FileReader();
     
    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result
      });
    }
 
    // reader.readAsDataURL(dataUri)

 console.log(this.state.selectedFile);

    var fd = new FormData();
 
    fd.append('file', this.state.selectedFile);
 
    var request = new XMLHttpRequest();
 
    request.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        alert('Uploaded!');
      }
    };
    // request.open("POST", uploadImage+'?user_id='+this.props.userdata.id, true);
    request.send(fd);


  }
  editImage = event =>{
    this.setState({ addPopup: true});
  }
  render() {
    let { headerInfo,adverdisement } = this.state;
    let PopupClose = () => this.setState({ addPopup: false, openWebCamera: false});

    return (
      <section className="my-head">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-2 text-left pr0">
              <div className="logocenter">
                <a href="#">
                  {" "}
                  <img
                    alt="LOGO"
                    src={require("../../../../assets/images/logo.png")}
                  />
                </a>
              </div>
            </div>
          
           
            <div className="col-sm-5 pl0 pr0">
            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} showToast={true} closeOnClick={false} pauseOnHover />
            

                 <ProfilePicModel
                      show={this.state.addPopup}
                      onHide={PopupClose}
                      data={{imageSelection:this.imageSelectHandler, 
                        openWebCamera:this.state.openWebCamera,
                        startWebCamera:this.startWebCamera.bind(this),
                            takePicture:this.handleTakePhotoAnimationDone.bind(this)}}
                      title={"Select Profile Picture"}
                      message={"Are you sure you want to delete this record?"}
                    />
                    
                    <div className="grids clientHeader">
                      <div className="doctorimg d-flex">
                      <img className="profileImage img-circle"  id="profileImage"
                        alt="Profile"
                        src={
                          headerInfo.avatar
                            ? headerInfo.avatar
                            : require("../../../../assets/images/user1.jpg")
                        }
                      />
                      
                      <div className="p-image btn-m">
                      <i className="fa fa-camera upload-button"  onClick={this.editImage}></i>
                      
                      </div>
                      <div className="doctorprofile">
                    <h4>{headerInfo.full_name}</h4>
                    <br/><h5>
                      Registration ID :
                      <span className="witfnt">
                        {" "}
                        {headerInfo.registeration_id}
                      </span>{" "}
                    </h5>
                    <h5 className="mt-10">
                      <span className="witfnt">{headerInfo.blood_group}</span>
                    </h5>
                      </div>
                      </div>
                      
                   
                      <div className="strengthprofile22">
                    <p className="strgth100">{headerInfo.profile_percentage}</p>

                    <div className="stengthback">
                      <div className="dropback">
                        <div className="bg-wit" style={{ height: headerInfo.profile_percentage_style }}>
                          <img
                            alt="Profile strength"
                            src={require("../../../../assets/images/profile-strength.png")}
                            className="strengthprofile"
                          />
                                          </div>                 

                        </div>
                      </div>
                      <span className="witfnt">Profile </span> 
                      </div>
                    </div>
             
            </div>
            <div className="col-sm-5 pl0">
              {/* <img
                alt="Advertisment"
                src={require("../../../../assets/images/advertismenttop.jpg")}
                className="advertisment"
              /> */}
              {(adverdisement.length ? 
                (
                <OwlCarousel className="owl-theme" {...this.options} loop margin={10} nav>
                  {adverdisement.map((data, i) => {     
                  return( <div key={i} className="item">
                    <img src={data.vendor_photo} className="advertisment img-fluid" alt={data.vendor_name} />
                  </div>)
                  })}
                  
                </OwlCarousel>
            ):(<img
            src={require("../../../../assets/images/advertismenttop.jpg")}
            alt="advertisement"
            className="advertisment"
          />))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// export default Header;
// const Header = (props) => {
//   console.log("client header");
//   console.log(headerInfo);

//   return (

//   );
// };
export default connect(mapStateToProps)(Header);
