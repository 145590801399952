import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import { getAllBusinessList,removeBusinessFromBusinesslist,updateHospital,getAllUserSubscriptions,updateHospitalStatus } from "../config/url.json";
import axios from "axios";
import { CONFIG,payment_status } from "../config/data";
import moment from "moment";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import Select from "react-select";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import InputField from "../components/UI/InputField";
import Validation from "../actions/Validation";
import DatePicker from "react-date-picker";
import HospitallistExport from "./HospitallistExport";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}
const status = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
];
function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

class HospitalList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableSuccess: {
                message: "",
                status: "",
            },
            records: [],
            actions: "update",
            loading: false,
            showDeleteConfirmPopup: false,
            confirmedDelete: false,
      
             deletePopup: false,
             hospitaldata:
             {
               id:"",
               business_name:"",
              user_id: "",
              email:"",
              phone_number:"",
              subscription:"",
              payment_status:"",
              expires_at:"",

             },
             usersubscriptionlist:[],
             errors: {
                
                email:"",
                phone_number:"",
                subscription:"",
                payment_status:"",
                expires_at:"",

            },
            formSuccess: {
                message: "",
                status: "",
              },
        };

     
        this.columns = [
          {
            key: "s_no",
            text: "S.No",
            className: "s_no",
            align: "left",
            sortable: true,
          
          }, 
          {
                key: "id",
                text: "Hospital ID",
                className: "emp_id",
                align: "left",
                sortable: true,
            },
            {
                key: "business_name",
                text: "Business Name",
                className: "business_name",
                align: "left",
                sortable: true,
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true,
                cell: (record) => {
                  return(
                  <Fragment>
                       <a
                   href={record.email}
                   title={
                    record.email
                   }
                   >
                <i
                   className="fas fa-headset"
                   aria-hidden="true"
                   ></i>
                </a>
                  </Fragment>
                  )
                },
            },
            // {
            //     key: "Specialities",
            //     text: "Speciality",
            //     className: "speciality",
            //     align: "left",
            //     sortable: true,
            // },
            // {
            //     key: "dob",
            //     text: "Date Of Birth",
            //     width: 92,
            //     className: "dob",
            //     align: "left",
            //     sortable: true,
            //     cell: (record) => {
            //         return moment(record.dob).format("DD-MM-YYYY");
            //     },
            // },
            // {
            //     key: "gender",
            //     text: "Gender",
            //     className: "gender",
            //     sortable: true,
            //     align: "left",
            // },
            // {
            //     key: "marital_status",
            //     text: "Marital Status",
            //     className: "marital_status",
            //     sortable: true,
            //     align: "left",
            // },
            // {
            //     key: "blood_group",
            //     text: "Blood Group",
            //     className: "blood_group",
            //     sortable: true,
            //     align: "left",
            // },
            {
              key: "address",
              text: "Hospital Address",
              className: "address",
              sortable: true,
              align: "left",
              width:20,
              cell: (record) => {
                return(
                <Fragment>
                     <a
                 href={record.address}
                 title={
                  record.address
                 }
                 >
              <i
                 className="fa-solid fa-house"
                 aria-hidden="true"
                 ></i>
              </a>
                </Fragment>
                )
              },
          },
          {
            key: "phone_number",
            text: "Phone Number",
            width: 20,
            className: "phone_number",
            align: "left",
            sortable: true,
            cell: (record) => {
              return(
              <Fragment>
                   <a
             /*   href={"tel:+91"+record.phone_number} */
               title={
                record.phone_number
               }
               >
            <i
               className="fa fa-phone-square grnbk"
               aria-hidden="true"
               ></i>
            </a>
              </Fragment>
              )
            },
          },
            {
              key: "subscription",
              text: "Subscription",
              className: "subscription",
              sortable: true,
              align: "left",
          },
          {
            key: "payment_status",
            text: "Payment Status",
            className: "Payment_Status",
            sortable: true,
            align: "left",
        },
        {
          key: "expires_at",
          text: "Expires At",
          className: "expires_at",
          sortable: true,
          align: "left",
          cell: (record) => {

          var date=moment(record.expires_at).format("DD-MMM-yyyy");
            console.log(date);
           if(date ==="Invalid date")
           {
            return "";

           }else
           {
            return moment(record.expires_at).format("DD-MMM-yyyy");
           }

          },
      },

      {
        key: "status",
        text: "Status",
        className: "status statuswidth",
        tHeadClassName: "csutomcolspan",
        width:100,
        sortable: true,
        align: "left",
        cell: (record) => {
            let obj = {
                label: record.status,
                value: record.status,
            };
            return (
                <Select maxMenuHeight={120}
                    className="form-control p-0"
                    name="status"
                    value={obj}
                    onChange={(data) => this.handleColumnChange(record, data)}
                    options={status}
                    disabled={(option) => option.value === "Approved"}
                 />
            );
        },
    },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                    this.setState({ deletePopup: true, record: record })
                                }
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    handleColumnChange = (record, selectedOptions) => {

        record.status = selectedOptions ? selectedOptions.value : "Pending";
        this.UpdateColumnChange(record);
    };

    UpdateColumnChange = (data) => {
        axios.defaults.headers.common[
            "Authorization"
         ] = `Bearer ${this.props.access_token}`;
         let request = {
             user_id: data.user_id, status: data.status
         }
       axios.post(updateHospitalStatus, request).then((list) => {
        let records = this.state.records;

           data = list.data.data;
            if (list.data.status === "1") {
      //  var recIndex = findWithAttr(records, "user_id", data.user_id);

     //   records[recIndex].status = data.status;

        this.setState({ records, tableSuccess: "" });
         }

        this.clearMessage();
         });
    };
    handleselectChange = (selectedOptions, e) => {
      let data = this.state.hospitaldata;
      data[e.name] = selectedOptions;
  
      this.setState({ hospitaldata: data });
    };
    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };

    handleDOBChange = (date) => {
      let Hospitaldata = this.state.hospitaldata;
      Hospitaldata.expires_at = date;
      this.setState({ hospitaldata: Hospitaldata });
    };
   
    componentDidMount = () => {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .get(getAllBusinessList, null, CONFIG)
            .then((list) => {
                console.log(list);
                console.log(list.data.data);
                this.setState({ records: list.data.data });
                let users = [];
                let userlist = list.data.data;
                userlist.forEach((element, index) => {
                  let user = {};
                  user.s_no = index + 1;
                  user.id=element.id;
                  user.business_name = element.business_name;
                  user.address=element.address1 +","+ element.city +","+ element.state +","+ element.country+"-"+element.zip_code;
                  user.address1=element.address1;
                  user.city=element.city;
                  user.state=element.state;
                  user.country=element.country;
                  user.phone_code=element.phone_code;
                  user.phone_number = (element.phone_number==null)?element.phone_number: (element.phone_number.includes(element.phone_code)? element.phone_number :element.phone_code+element.phone_number);
          
                //  user.phone_number = element.phone_number;
                  user.email = element.email;
                  user.subscription = element.subscription;
                  user.payment_status = element.payment_status;
                  user.expires_at = element.expires_at;
                  user.user_id=element.user_id;
                  user.status=element.status;
                  users[index] = user;
                });
        
                this.setState({ records: users });
            })
            .catch((e) => { console.log(e) });



            axios
            .post(getAllUserSubscriptions, null, CONFIG)
            .then((result) => {
              console.log(result.data);
              if (result.data.status === "1") {
                let usersubscriptionlist = [];
                result.data.data.forEach((element, index) => {
                  let obj = {};
                  obj = element;
                  obj.label = element.subscription_name;
                  obj.value = element.id;
                  usersubscriptionlist[index] = obj;
                });
      
                this.setState({
                  usersubscriptionlist: usersubscriptionlist,
                });
              } else {
                alert("Subscription list empty");
              }
            })
            .catch((e) => {
              console.log(e);
              console.log("business user getbyid failed");
            });
      
    }
    cancel = () => {
        let data = this.state.hospitaldata;
        data.id="";
        data.business_name = "";
        data.email = "";
        data.phone_number = "";
        data.subscription="";
        data.payment_status="";
        data.expires_at="";
              
        this.setState({ hospitaldata: data, actions: "update" });
      };
    editRecord = (record) => {
        console.log(record);
        this.setState({ actions: "edit" });
    
        let hospitaldata = this.state.hospitaldata;
        hospitaldata.id=record.id;
        hospitaldata.user_id = record.user_id;
        hospitaldata.business_name=record.business_name;
        hospitaldata.id = record.id;
        hospitaldata.user_id = record.user_id;
        hospitaldata.email=record.email;
        hospitaldata.status=record.status;
        hospitaldata.phone_number = record.phone_number;
        hospitaldata.subscription={
        label: record.subscription,
        value: record.subscription,
      };
        hospitaldata.payment_status = {
          label: record.payment_status,
          value: record.payment_status,
        };
        
        if(hospitaldata.expires_at===null|| hospitaldata.expires_at==="")
        {
          hospitaldata.expires_at =record.expires_at;
        }else
        {
          hospitaldata.expires_at=moment(record.expires_at,moment.defaultFormat).toDate();
        }
                
        this.handleHospitalChange("user_id", hospitaldata.user_id);
        this.setState({ hospitaldata: hospitaldata });
      };
    
      clearAll() {
        let actions = this.state.actions;
        actions = "update";
        this.setState({ actions });
        let hospitaldata = {};
        hospitaldata.id="";
        hospitaldata.business_name="";
        hospitaldata.email = "";
        hospitaldata.phone_number = "";
        hospitaldata.subscription="";
        hospitaldata.payment_status="";
        hospitaldata.expires_at="";
        this.setState({ hospitaldata });
      }

    handleHospitalChange(value, field) {
        let fields = this.state.hospitaldata;
        // let errors = this.state.educationerror;
        console.log(field);
         if (value === "email") {
          fields.email = field.target.value;
          // errors[i].register_id = '';
        }else if (value === "id") {
          fields.id = field;
          // errors[i].education = '';
        } 
        else if (value === "business_name") {
          fields.business_name = field.target.value;
          // errors[i].education = '';
        }  
        else if (value === "phone_number") {
          fields.phone_number = field.target.value;
          // errors[i].education = '';
        }  
        else if (value === "subscription") {
          fields.subscription = field.target.value;
          // errors[i].education = '';
        }  
        else if (value === "expires_at") {
          fields.expires_at = field.target.value;
          // errors[i].education = '';
        }else if (value === "payment_status") {
          fields.payment_status = field.target.value;
          // errors[i].education = '';
        }                
        this.setState({
          hospitaldata: fields,
        });
      }
    updateHospital=(e)=> {
     // let user_id = record.user_id;
        let { hospitaldata, errors } = this.state;
        hospitaldata.type = "hospitallist";
        errors = Validation(hospitaldata, errors);
        this.setState({ errors: errors });
        if (errors.formIsValid) {
          let data = this.state.hospitaldata;
    
      //  let data = {};
        
        data.user_id=hospitaldata.user_id;
        data.id=hospitaldata.id;
        data.business_name=hospitaldata.business_name;
        data.email = hospitaldata.email;
        data.phone_number = hospitaldata.phone_number;
        data.subscription=hospitaldata.subscription.label;
        data.payment_status=hospitaldata.payment_status.label;
        data.expires_at=hospitaldata.expires_at;
        data.status=hospitaldata.status;
        axios.put(updateHospital, data, CONFIG).then((list) => {
         console.log(data);
         console.log(list.data);
          let response=list.data;
          if (response.status === "1") {

          let member = {};

          member.id=data.id;
          member.user_id = data.user_id;
          member.business_name=data.business_name;
          member.email = data.email;
          member.phone_number = data.phone_number;
          member.subscription=data.subscription;
          member.payment_status=data.payment_status;
          member.expires_at=data.expires_at;
          member.status=data.status;
          let records = this.state.records;

          /** Updating the data table */
          let existingRecords = this.state.records;
          var index = existingRecords.findIndex((item) => item.user_id === data.user_id);
          existingRecords.splice(index, 1, member);
          this.setState({ records: existingRecords });

          this.setState({ records });
          this.setState({ formSuccess: list.data });
          this.clearAll();
          this.clearMessage();
         
          }
        });
      }
      }
      deleteRecord(record) {
      
        let user_id = record.user_id;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(removeBusinessFromBusinesslist, { user_id: user_id }, CONFIG)
          .then((res) => {
            let response = res.data;
            if (response.status === "1") {
              let records = this.state.records;
    
              const deleteIndex = records.map((item) => item.user_id).indexOf(record.user_id);
              records.splice(deleteIndex, 1);
              this.setState({ records });
              this.setState({ tableSuccess: res.data });
              toast.error("Hospital deleted. ", {
                className: "toast-error"
              });
              this.clearMessage();
            }
          })
          .catch((e) => { });
        this.setState({ deletePopup: false, record: null });
    
        this.setState({
          confirmedDelete: false,
        });
        axios.defaults.headers.common[
          "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
          .get(getAllBusinessList, null, CONFIG)
          .then((list) => {
              console.log(list)
              this.setState({ records: list.data.data });
              let users = [];
              let userlist = list.data.data;
              userlist.forEach((element, index) => {
                let user = {};
                user.s_no = index + 1;
                user.id=element.id;
                user.business_name = element.business_name;
                user.address=element.address1 +","+ element.city +","+ element.state +","+ element.country+"-"+element.zip_code;
                user.address1=element.address1;
                user.city=element.city;
                user.state=element.state;
                user.country=element.country;
                user.phone_number = element.phone_number;
                user.email = element.email;
                user.subscription = element.subscription;
                user.payment_status = element.payment_status;
                user.expires_at = element.expires_at;
                user.status=element.status;
                user.user_id=element.user_id;
                users[index] = user;
              });
      
              this.setState({ records: users });
          })
          .catch((e) => { console.log(e) });
      }

    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {
            hospitaldata,
            formSuccess,
            tableSuccess,
            errors,
            loading,
        } = this.state;
        return (
            <div id="layout-wrapper">
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>


                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3"> Hospital List </h4>
                                    <div className="row mt-4">
                                    <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.records}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                            show={this.state.deletePopup}
                                                             onHide={() =>
                                                             this.setState({
                                                              deletePopup: false,
                                                               })
                                                               }
                                                             onConfirm={() =>
                                                             this.deleteRecord(this.state.record)
                                                             }
                                                            title={"Confirmation"}
                                                              message={
                                                               "Are you sure you want to delete this record??"
                                                               }
                                                             />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                            <div className="card">
                            <div className="card-header"> <div className="card-title"> {" "}
                                    {this.state.actions === "Edit"
                                      ? "Edit"
                                      : "Edit"}{" "}
                                    Hospital</div> </div>
                              <div className="card-body">
                                <div>
                                 
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        0 === formSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={formSuccess.message}
                                    />
                                  </div>
                                  <div className="row">
                                  <div className="col-lg-4">
                                    <div>
                                        <label>Business ID</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"id"}
                                          controlFunc={this.handleHospitalChange.bind(this)}
                                          content={hospitaldata.id}
                                          placeholder={""}
                                          max={50}
                                        />
                                
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.id}
                                      /> 
                                     
                                    </div>

                              
                                     

                                    </div>
                                    <div className="col-lg-4">
                                    <div>
                                        <label>Business Name</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"email"}
                                          controlFunc={this.handleHospitalChange.bind(this)}
                                          content={hospitaldata.business_name}
                                          placeholder={""}
                                          max={50}
                                        />
                               
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.business_name}
                                      /> 
                                     
                                    </div>

                                 
                                     

                                    </div>

                                    <div className="col-lg-4">
                                    <div>
                                        <label>Email</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"email"}
                                          controlFunc={this.handleHospitalChange.bind(
                                            this,
                                            "email"
                                          )}
                                          content={hospitaldata.email}
                                          placeholder={""}
                                          max={50}
                                        />
                                    <div><span>&nbsp;</span></div>
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.email}
                                      /> 
                                     
                                    </div>

                                    <div className="col-lg-4">
                                    
                                      </div>
                                     

                                    </div>

                                    <div className="col-lg-4">
                                      <div>
                                        <label>Phone Number</label>
                                      </div>
                                      <div className="form-group form-control">
                                      <PhoneInput
                                                international
                                                defaultCountry="IN"
                                                placeholder="Enter phone number"
                                                value={hospitaldata.phone_number}
                                                onChange={this.handleHospitalChange.bind(
                                                  this,
                                                  "phone_number"
                                                )}
                                                error={
                                                    hospitaldata.phone_number
                                                    ? isValidPhoneNumber(
                                                        hospitaldata.phone_number
                                                    )
                                                      ? undefined
                                                      : "Invalid phone number"
                                                    : "Phone number required"
                                                }
                                                 disabled={true}
                                              />
                                              <div><span>&nbsp;</span></div>
                                              <SpanField
                                                classname="text-danger"
                                                title={errors.phone_number}
                                              />
                                      </div>
                                      

                                    </div>

                                    
                                    <div className="col-sm-4">
                                      <div>
                                        <label>Subscription</label>
                                      </div>
                                      <div className="form-group sform-control">
                                        <Select maxMenuHeight={120}
                                          className="form-control p-0"
                                          name="subscription"
                                          value={hospitaldata.subscription}
                                          onChange={this.handleselectChange}
                                          options={this.state.usersubscriptionlist}
                                        />
                                      </div>
                                      <SpanField
                                                classname="text-danger"
                                                title={errors.subscription}
                                              />
                                    </div>
                                    <div className="col-sm-4">
                                      <div>

                                      </div>
                                      <div>
                                        <div>
                                          <label>Expiration Date</label>
                                        </div>
                                        <div className="form-group">
                                          <DatePicker
                                            className="form-control"
                                            value={hospitaldata.expires_at}
                                            format="dd-MM-yyyy"
                                            onChange={(date) =>
                                              this.handleDOBChange(date)
                                            }
                                            placeholder={"Select the expiration date"}
                                            yearPlaceholder='YYYY'
                                            monthPlaceholder='MM'
                                            dayPlaceholder='DD'
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.expires_at}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-sm-4">
                                    
                                      <div>
                                        <div>
                                          <label>Payment Status</label>
                                        </div>
                                        <div className="form-group sform-control">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="payment_status"
                                            value={hospitaldata.payment_status}
                                            onChange={this.handleselectChange}               
                                            options={payment_status}
                                          />
                                        </div>
                                        <SpanField
                                                classname="text-danger"
                                                title={errors.payment_status}
                                              />
                                      </div>
                                    </div>

                               

                                
                                
                                  </div>
                                  <div className='row'>
                                  <div className="col-sm-12">

<div className="form-group text-center mb-0 float-right">
<button
    ref="btn"
    href="patients-register"
    className="signupbtn abtn btdpml btn-margin"
    onClick={this.updateHospital}>
                                           
    {loading ? (
      <LoadingSpinner title="Update" />
    ) : this.state.actions === "Update" ? (
      "Update"
    ) : (
      "Update"
    )}
  </button>
  <button
    href="patients-register"
    className="signupbtn abtn btdpml"
    onClick={this.cancel}
  >
    Cancel
  </button>
</div>
</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                    <div className="card">
                                    <div className="card-header"> <div className="card-title">Export Hospital List</div> </div>
                                                <div className="card-body">
                                                    <div>
                                                       
                                                        <div className="user-notification">
                                                            <SpanField
                                                                classname={
                                                                    0 === formSuccess.status
                                                                        ? "text-danger user-notification"
                                                                        : "text-success user-notification"
                                                                }
                                                                title={formSuccess.message}
                                                            />
                                                        </div>
                                                        <div >
                                                            <div className="col-sm-4">
                                                                
                                                              <HospitallistExport records={this.state.records}/>
                                                                
                                                               
                                                            </div>



                                                         
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                       {/*  <label className="control-label">{this.state.file ? this.state.file.name : ""}</label> */}
                                                    </div>
                                                </div>
                                             
                                            </div>
                                            </div>
                                    </div>
                                </div>
                            
                            </section>
                            <FooterSection />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(
    mapStateToProps,
)(HospitalList);