import React, { Component, Fragment } from "react";
// import ProfileHeader from "../components/container/ProfileHeader";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import SideBar from "../components/container/SideBar";
import { connect } from "react-redux";
import TextArea from "../components/UI/TextArea";
import axios from "axios";
import {
  getBusinessUserById,
  updateBusinessUser,
  getTimeoutbycategory,
  DeleteAccount
} from "../config/url.json";
import { withRouter } from "react-router-dom";
import { getReviwes } from "../config/url.json";
import { CONFIG,card_type } from "../config/data";
import StarRatings from "react-star-ratings";
import Validation from "../actions/Validation";
import InputField from "../components/UI/InputField";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import CheckboxOrRadioGroup from "../components/UI/CheckboxOrRadioGroup";
import Select from "react-select";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import YearPick from "../components/UI/YearPick";
import MonthPick from "../components/UI/MonthPick";
import StarField from "../components/UI/StarField";
import {
  MONTH_PAIR,
} from "../constants/config/data";
import ReactDatatable from "@ashvin27/react-datatable";
import { Popup } from "../components/UI/Popup";
import * as moment from "moment";
import DatePicker from "react-date-picker";
import {
  stringToLabelValue,
  getKeyFromValue,
  getMaxIdOfRecords,
  calculate_age,
} from "../constants/globalLevelFunctions";

import {
  nationalityArray,
  blood_group,
  gender,
  language,
  AUTH_CONFIG,
} from "../config/data";
import Label from "../components/UI/Label";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { element } from "prop-types";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    access_token: state.AuthReducer.access_token,
    userdata: state.AuthReducer.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

const google = window.google;
const scuedueType = ["Queue", "appointment"];
const working_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const currentYear = new Date().getFullYear();

let nationality = [];
nationalityArray.forEach((element) => {
  nationality.push({ value: element, label: element });
});

let eq_record = {
  id: "",
  education: "",
  specialization: "",
  university: "",
  completion_month: "",
  completion_year: "",
};

let cert_record = {
  organization: "",
  specialization: "",
  valid_URL: "",
  certification_completion_month: "",
  certification_completion_year: "",
  valid_upto_month: "",
  valid_upto_year: "",
  id: "",
};

let experience_record = {
  hospital: "",
  ex_address: "",
  ex_city: "",
  ex_state: "",
  ex_country: "",
  ex_zipcode:"",
  experience_from: "",
  experience_to: "",
  id: "",
};

let medical_licence_record = {
  id: "",
  organization: "",
  licence_Id: "",
  expiration_date: "",
};

class BusinessUserProfile extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;

    this.state = {
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      deletePopup: false,
      perandspeDisabled: true,
      expandmemDisabled: false,
      hoursdisabled: false,
      alldaysbtnClicked: false,
      
      checkalldays: false,
      isDisable: false,
      pageAction: "Save",
      edEdit: true,
      exEdit: true,
      certEdit: true,
      medEdit: true,
      getBusinessUserdetails:null,
      headerinfo: {
        profile_name: "",
        qualification: "",
        specialization: "",
        work_place: "",
        id: "",
        languages_known: "",
        profile_percentage: "",
        rating: "",
      },
      reviewinfo: {
        friendliness: "",
        value_of_money: "",
        less_wait_time: "",
        best_treatment: "",
        easy_explanation: "",
        good_facility: "",
      },
      commentsinfo: {
        commenter: "",
        avatar: "",
        overall_rating: "",
        comment_text: "",
        reviewed_on: "",
      },
      businessUserData: {
        first_name: "",
        last_name: "",
        age: "",
        profile_pic: "",
        date_of_birth: "",
        address1: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email: "",
        gender: "",
        blood_group: "",
        nationality: "",
        language: "",
        medical_licence_id: "",
        expiry_date: "",
        user_type: "business_user",
        google_address: false,
        schedule_type:"",
        working_hours: [
          {
            morning_shift_start: "",
            morning_shift_end: "",
            evening_shift_start: "",
            evening_shift_end: "",
          },
        ],
        working_days: [],
        speciality: [],
        educational_qualification: [],
        certification: [],
        experience: [],
        medical_licence_details: [],
      },

      certifications: [
        {
          organization: "",
          specilization: "",
          certification_type: "",
          certification_completion_month: "",
          certification_completion_year: "",
          valid_upto_month: "",
          valid_upto_year: "",
        },
      ],
        payment_details: [
          {
            "credit_card": "",
            "card_name": "",
            "card_type": {
              "label": "",
              "value": ""
            },
            "expiry_month": "",
            "expiry_year": "",
            "cvv": "",
            "payment_option": ""
          },
        
      ],
      payment_details: [
        {
          "credit_card": "",
          "card_name": "",
          "card_type": {
            "label": "",
            "value": ""
          },
          "expiry_month": "",
          "expiry_year": "",
          "cvv": "",
          "payment_option": ""
        },
      ],
      payment_details_error: [{
        "credit_card": "",
        "card_name": "",
        "card_type": {
          "label": "",
          "value": ""
        },
        "expiry_month": "",
        "expiry_year": "",
        "cvv": "",
        "payment_option": ""
      },],
      errors: {
        first_name: "",
        last_name: "",
        profile_pic: "",
        date_of_birth: "",
        address1: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email: "",
        gender: "",
        blood_group: "",
        nationality: "",
        medical_licence_id: "",
        expiry_date: "",
      },
      commentsArr: [],
      educational_qualification_formData: {
        id: "",
        education: "",
        specialization: "",
        university: "",
        completion_month: "",
        completion_year: "",
      },
      certifications_formData: {
        organization: "",
        specialization: "",
        valid_URL: "",
        certification_completion_month: "",
        certification_completion_year: "",
        valid_upto_month: "",
        valid_upto_year: "",
        id: "",
      },

      experiences_formData: {
        hospital: "",
        ex_address: "",
        ex_city: "",
        ex_state: "",
        ex_country: "",
        ex_zipcode:"",
        experience_from: "",
        experience_to: "",
        id: "",
      },
      medical_licence_formData: {
        organization: "",
        licence_Id: "",
        expiration_date: "",
        id: "",
      },

      educational_qualification_errors: [
        {
          education: "",
          specialization: "",
          university: "",
          completion_year_month: "",
        },
      ],
      medical_licence_error: {
        organization: "",
        licence_Id: "",
        expiration_date: "",
      },
      certificate_errors: [
        {
          organization: "",
          specialization: "",
          valid_URL: "",
          certification_completion_month: "",
          certification_completion_year: "",
          valid_upto_month: "",
          valid_upto_year: "",
        },
      ],
      experiences_errors: [
        {
          hospital: "",
          ex_address: "",
          city: "",
          state: "",
          country: "",
          ex_zipcode:"",
          experience_from: "",
          experience_to: "",
        },
      ],
      loading: false,
    };
    this.autocomplete_ex_address = null;
    this.education_columns = [
      {
        key: "id",
        text: "S.No",
        className: "id",
        align: "left",
        sortable: true,
      },
      {
        key: "education",
        text: "Education",
        className: "education",
        align: "left",
        sortable: true,
      },
      {
        key: "specialization",
        text: "Specialization",
        className: "specialization",
        align: "left",
        sortable: true,
      },
      {
        key: "university",
        text: "University",
        className: "university",
        sortable: true,
        align: "left",
      },
      {
        key: "completion_year_month",
        text: "Completion Date",
        className: "completion_year_month",
        sortable: true,
        align: "left",
      },
      {
        key: "action",
        text: "Action",
        className: "hideAction",
        TrOnlyClassName: "hideAction",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                type="button"
                className="btn btn-blue btn-sm btn-space"
                onClick={() => {
                  this.setState({ edEdit: true, edAction: "edit" });
                  this.editEqucationRecord(record);
                }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm btn-space"
                onClick={() => this.onDeleteClick(record, "education")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.certificateColumns = [
      {
        key: "id",
        text: "S.NO",
        className: "id",
        align: "left",
        sortable: true,
      },
      {
        key: "organization",
        text: "Organization",
        className: "organization",
        align: "left",
        sortable: true,
      },
      {
        key: "specialization",
        text: "Specialization",
        className: "specialization",
        align: "left",
        sortable: true,
      },
      {
        key: "valid_URL",
        text: "Valid URL",
        className: "valid_URL",
        sortable: true,
        align: "left",
      },
      {
        key: "completion_year_month",
        text: "Completion Date",
        className: "certification_completion",
        sortable: true,
        align: "left",
      },
      {
        key: "valid_date",
        text: "Valid Date",
        className: "valid_Date",
        sortable: true,
        align: "left",
      },
      {
        key: "action",
        text: "Action",
        className: "hideAction",
        TrOnlyClassName: "hideAction",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                type="button"
                className="btn btn-blue btn-sm btn-space"
                onClick={() => {
                  this.setState({ certEdit: true, certAction: "edit" });
                  this.editCertificateRecord(record);
                }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm btn-space"
                onClick={() => this.onDeleteClick(record, "certificate")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.experienceColumns = [
      {
        key: "id",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "hospital",
        text: "Hospital",
        className: "hospital",
        align: "left",
        sortable: true,
      },
      {
        key: "ex_address",
        text: "Address",
        className: "ex_address",
        sortable: true,
        align: "left",
      },
      {
        key: "ex_city",
        text: "City",
        className: "ex_city",
        sortable: true,
        align: "left",
      },
      {
        key: "ex_state",
        text: "State",
        className: "ex_state",
        sortable: true,
        align: "left",
      },
      {
        key: "ex_country",
        text: "Country",
        className: "ex_country",
        sortable: true,
        align: "left",
      },
      {
        key: "ex_zipcode",
        text: "Pincode",
        className: "ex_zipcode",
        sortable: true,
        align: "left",
      },
      {
        key: "experience_from",
        text: "Experience From",
        className: "experience_from",
        sortable: true,
        align: "left",
        cell: (record) => {
          return moment(record.experience_from).format("DD-MMM-yyyy");
        },
      },
      {
        key: "experience_to",
        text: "Experience To",
        className: "experience_to",
        sortable: true,
        align: "left",
        cell: (record) => {
          return moment(record.experience_to).format("DD-MMM-yyyy");
        },
      },
      {
        key: "action",
        text: "Action",
        className: "hideAction",
        TrOnlyClassName: "hideAction",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                type="button"
                className="btn btn-blue btn-sm btn-space"
                onClick={() => {
                  this.setState({ exEdit: true, exAction: "edit" });
                  this.editExperienceRecord(record);
                }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm btn-space"
                onClick={() => this.onDeleteClick(record, "experience")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.medical_licence_columns = [
      {
        key: "id",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "organization",
        text: "Organization",
        className: "organization",
        align: "left",
        sortable: true,
      },
      {
        key: "licence_Id",
        text: "Licence ID",
        className: "licence_Id",
        sortable: true,
        align: "left",
      },
      {
        key: "expiration_date",
        text: "Expiration Date",
        className: "expiration_date",
        sortable: true,
        align: "left",
        cell: (record) => {
          return moment(record.expiration_date).format("DD-MMM-yyyy");
        },
      },
      {
        key: "action",
        text: "Action",
        show: false,
        width: 100,
        align: "left",
        className: "hideAction",
        TrOnlyClassName: "hideAction",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                type="button"
                className="btn btn-blue btn-sm btn-space"
                onClick={() => {
                  this.setState({ medEdit: true, medAction: "edit" });
                  this.editMedicalRecord(record);
                }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm btn-space"
                onClick={() => this.onDeleteClick(record, "medicalLicence")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      show_pagination: false,
      show_filter: false,
      show_length_menu: false,
      show_info: false,
      button: {
        excel: false,
        print: false,
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleExperiencePlaceSelect = this.handleExperiencePlaceSelect.bind(this);
    this.handleCertificateInputChange=this.handleCertificateInputChange.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
   
  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
};

  _onAction(e) {
    // console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  }
  }

  addPaymentDetail() {
    this.setState((prevState) => ({
      payment_details: [
        ...prevState.payment_details,
        {
          "credit_card": "",
          "card_name": "",
          "card_type": {
            "label": "",
            "value": ""
          },
          "expiry_month": "",
          "expiry_year": "",
          "cvv": "",
          "payment_option": ""
        },
      ],
    }));
    this.setState((prevState) => ({
      payment_details_error: [
        ...prevState.payment_details_error,
        {
          "credit_card": "",
          "card_name": "",
          "card_type": {
            "label": "",
            "value": ""
          },
          "expiry_month": "",
          "expiry_year": "",
          "cvv": "",
          "payment_option": ""
        },
      ],
    }));
  }

  removePaymentDetail(i) {
    let payment_details = [...this.state.payment_details];
    payment_details.splice(i, 1);
    this.setState({ payment_details });
  }

  handlePaymentDetailChange(i, value, field) {

    
    let fields = this.state.payment_details;

    let payment_details_error = this.state.payment_details_error;
    if (value === "credit_card" || value === "card_name" || value === "payment_option" || value === "cvv") {

      // const re = /^[0-9\b]+$/;
      // console.log(field.target.value);
      //   if (field.target.value === '' || re.test(field.target.value)) {
      //      this.setState({value: field.target.value})
      //   }  
      
      let max = field.target.max;
      let v = field.target.value;

      if (v.length > max) {
        const value1 = v.slice(0, max);
        payment_details_error[i][value] = "Maximum Length has exceeded.";
        fields[i][value] = value1;
      } else {
        payment_details_error[i][value] = "";
        if(value === "card_name")
          fields[i][value] = v.replace(/[^a-zA-Z]/ig,'');
        else if(value === "cvv")
          fields[i][value] = v.replace(/[^0-9]/ig,'');
          else if(value==="credit_card")
          fields[i][value] = v.replace(/[^0-9]/ig,'');
        else 
        fields[i][value] = v;

      }

      console.log(payment_details_error);
      console.log(fields);
    } else if (value === "card_type" || value === "expiry_month" || value === "expiry_year") {
      fields[i][value] = field;
    }
    this.setState({
      payment_details_error: payment_details_error,
      payment_details: fields
    });
  }
  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch(() => { });

    this.autocomplete_ex_address = new google.maps.places.Autocomplete(
      document.getElementById("ex_address"),
      {}
    );

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    this.autocomplete_ex_address.addListener(
      "place_changed",
      this.handleExperiencePlaceSelect
    );
    this.setState({ loading: true });
    window.setTimeout(() => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      axios
        .post(
          getBusinessUserById,
          { business_user_id: this.props.userdata.id },
          CONFIG
        )
        .then((userData) => {
          console.log("userData");
          console.log(userData.data.data);
          if (userData.data.status == 1)
           {
            let details = userData.data.data;
            console.log(details);
            let businessUserData = this.state.businessUserData;
            businessUserData.first_name = details.first_name;
            businessUserData.last_name = details.last_name;
            businessUserData.age = details.age;
            if (!details.payment_details || details.payment_details == null||details.payment_details =="") {
              businessUserData.payment_details = [{
                "credit_card": "",
                "card_name": "",
                "card_type": {
                  "label": "",
                  "value": ""
                },
                "expiry_month": "",
                "expiry_year": "",
                "cvv": "",
                "payment_option": ""
              }];
            } else {
              businessUserData.payment_details = details.payment_details;
            }
            console.log(businessUserData.payment_details)
            let sex = {};
            sex.label = details.gender;
            sex.value = details.gender;
            businessUserData.gender = sex;
            let bg = {};
            bg.label = details.blood_group;
            bg.value = details.blood_group;
            businessUserData.blood_group = bg;
            let nationality = {};
            nationality.label = details.nationality;
            nationality.value = details.nationality;
            businessUserData.nationality = nationality;
            businessUserData.language = details.language;
            businessUserData.address1 = details.address1;
            businessUserData.city = details.city;
            businessUserData.state = details.state;
            businessUserData.country = details.country;
            businessUserData.zip_code = details.zip_code;
            // businessUserData.phone_number = details.phone_number;
            businessUserData.date_of_birth = details.date_of_birth;
            businessUserData.email = details.email;
            businessUserData.qualification = details.qualification;
            // businessUserData.expiry_date = details.expiry_date;
            businessUserData.expiry_date = details.expiry_date
              ? moment(details.expiry_date, moment.defaultFormat).toDate()
              : "";
            businessUserData.licence_id = details.licence_id;
            businessUserData.user_id = details.user_id;
            businessUserData.business_user_type = details.business_user_type;
            businessUserData.business_user_type_id =
              details.business_user_type_id;
            businessUserData.id = details.id;
            businessUserData.age = calculate_age(details.date_of_birth);
            businessUserData.profile_summary = details.profile_summary;
            businessUserData.phone_number = details.phone_number ? details.phone_code.concat(details.phone_number) : "";
            businessUserData.schedule_type = details.schedule_type ? details.schedule_type : "";
          
            /* Working Hours secton */
            if (
              "null" === details.working_hours ||
              null == details.working_hours
            ) {
              let working_hours = {
                morning_shift_start: "",
                morning_shift_end: "",
                evening_shift_start: "",
                evening_shift_end: "",
                hours_24: false,
              };
              businessUserData.working_hours = working_hours;
            } else
             {
              businessUserData.working_hours = details.working_hours;
            }

            /* Working Days section */
            if (null == details.working_days) {
              details.working_days = [];
            } else {
              businessUserData.working_days = details.working_days;
              if (7 === businessUserData.working_days.length) {
                businessUserData.working_all_days = true;
              } else {
                businessUserData.working_all_days = false;
              }
            }

             let speciality = [];
            let specialities_array = this.props.appdata
              ? this.props.appdata[0].specialities
              : []; 
              console.log(details.speciality);
            if (details.speciality) {
              specialities_array.forEach((element) => {
              // console.log(details.speciality.includes(element.id));
                if (details.speciality.includes(element.id) === true && details.speciality===element.id.toString()) {
                  speciality.push({
                    value: element.id,
                    label: element.name,
                  });
                }
              });
            }
            businessUserData.speciality = speciality;

            businessUserData.language = stringToLabelValue(
              businessUserData.language
            );

            businessUserData.educational_qualification = details.educational_qualification;
            businessUserData.certification = details.certification;
            businessUserData.experience = details.experience;
            businessUserData.medical_licence_details = 
              details.medical_licence_details;
              console.log(businessUserData);
            this.setState({ businessUserData,payment_details:businessUserData.payment_details,getBusinessUserdetails:details });
            this.setState({ loading: false });
          }
        })
        .catch((e) => {
          console.log(e);
          console.log("business user getbyid failed");
        });
    }, 1000);
    /** Reviews */
    let data = {};
    data.user_id = 1;
    data.user_type = "doctor";

    axios
      .post(getReviwes, { data }, CONFIG)
      .then((reviewResult) => {
        let reviewResultData = reviewResult.data;
        let commentsData = reviewResult.data.data.comments;
        let commentsArr = this.state.commentsArr;

        let reviewinfo = this.state.reviewinfo;
        reviewinfo.friendliness = reviewResultData.data.friendlyness;
        reviewinfo.value_of_money = reviewResultData.data.value_of_money;
        reviewinfo.less_wait_time = reviewResultData.data.less_wait_time;
        reviewinfo.best_treatment = reviewResultData.data.best_treatment;
        reviewinfo.easy_explanation = reviewResultData.data.easy_explanation;
        reviewinfo.good_facility = reviewResultData.data.good_facility;
        this.setState({ reviewinfo });

        commentsData.forEach((element, index) => {
          let commentinfo = {};
          let commentsArr = this.state.commentsArr;
          commentinfo.commenter = element.ReviewBy;
          commentinfo.avatar = element.avatar;
          commentinfo.overall_rating = element.over_all_review;
          commentinfo.comment_text = element.comments;
          commentinfo.reviewed_on = element.ReviewOn;
          commentsArr[index] = commentinfo;
        });
        this.setState({ commentsArr });
      })
      .catch(() => { });
  };

  submitData = (e) => {
    e.preventDefault();
    console.log('ssssss')
    if ("Save" === this.state.pageAction) {
      let { businessUserData, errors,getBusinessUserdetails } = this.state;
      console.log(getBusinessUserdetails);
      businessUserData.user_type = "update_business_user";
      errors = Validation(businessUserData, errors);

      this.setState({ errors: errors });

      let data = this.state.businessUserData;

      console.log(data);
      console.log(errors);
      console.log(errors.formIsValid);

      if (errors.formIsValid) {
        this.setState({ loading: true });
        let specialities_array = this.props.appdata
        ? this.props.appdata[0].specialities
        : [];
        data.type = "doctor";
        data.blood_group = data.blood_group.value;
        data.gender = data.gender.value;
        if(data.speciality!==undefined){
        specialities_array.forEach((element, index) => {
       
          if (data.speciality === element.id) {
            data.speciality = element.id;
            data.Specialities = element.name;
          }
         
        });
        data.speciality=data.speciality.value;
      }
     //   let speciality = [];
       /*  if (data.speciality) {
          data.speciality.forEach((element) =>
            speciality.push(element.value)
          );
        }
        data.speciality = speciality.toString(); */
       /*  let speciality = [];
        if (data.speciality) {
          data.speciality.forEach((element) =>
            speciality.push(element.value)
          );
        }
        data.speciality = speciality.toString();
 */
         if(data.speciality==undefined||data.speciality==null)
        {
        data.speciality=getBusinessUserdetails.speciality;
        } 
        console.log(getBusinessUserdetails.speciality);

        let lang = [];
        console.log(data.language);
        if (data.language) {
          data.language.forEach((element) => lang.push(element.value));
        }
        data.language = lang.toString();
        data.nationality = data.nationality.value;

        data.phone_code = "+" + parsePhoneNumber(data.phone_number).countryCallingCode;
        data.phone_number = parsePhoneNumber(data.phone_number).nationalNumber;
        console.log(data);
        data.payment_details = this.state.payment_details;
        try {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;

          console.log(updateBusinessUser);
          console.log(data);

          const result = axios.put(updateBusinessUser, data, AUTH_CONFIG);

          const p = Promise.resolve(result);

          p.then((value) => {
            if (value.data.status === "1") {
              this.setState({ loading: false });
            
              // this.props.history.push("/dashboard");
           //   window.location.replace("/profile");
           axios
           .post(
             getBusinessUserById,
             { business_user_id: this.props.userdata.id },
             CONFIG
           )
           .then((userData) => {
             console.log("userData");
             console.log(userData.data.data);
             if (userData.data.status == 1)
              {
               let details = userData.data.data;
               console.log(details);
               let businessUserData = this.state.businessUserData;
               businessUserData.first_name = details.first_name;
               businessUserData.last_name = details.last_name;
               businessUserData.age = details.age;
               if (!details.payment_details || details.payment_details == null||details.payment_details =="") {
                 businessUserData.payment_details = [{
                   "credit_card": "",
                   "card_name": "",
                   "card_type": {
                     "label": "",
                     "value": ""
                   },
                   "expiry_month": "",
                   "expiry_year": "",
                   "cvv": "",
                   "payment_option": ""
                 }];
               } else {
                 businessUserData.payment_details = details.payment_details;
               }
               console.log(businessUserData.payment_details)
               let sex = {};
               sex.label = details.gender;
               sex.value = details.gender;
               businessUserData.gender = sex;
               let bg = {};
               bg.label = details.blood_group;
               bg.value = details.blood_group;
               businessUserData.blood_group = bg;
               let nationality = {};
               nationality.label = details.nationality;
               nationality.value = details.nationality;
               businessUserData.nationality = nationality;
               businessUserData.language = details.language;
               businessUserData.address1 = details.address1;
               businessUserData.city = details.city;
               businessUserData.state = details.state;
               businessUserData.country = details.country;
               businessUserData.zip_code = details.zip_code;
               // businessUserData.phone_number = details.phone_number;
               businessUserData.date_of_birth = details.date_of_birth;
               businessUserData.email = details.email;
               businessUserData.qualification = details.qualification;
               // businessUserData.expiry_date = details.expiry_date;
               businessUserData.expiry_date = details.expiry_date
                 ? moment(details.expiry_date, moment.defaultFormat).toDate()
                 : "";
               businessUserData.licence_id = details.licence_id;
               businessUserData.user_id = details.user_id;
               businessUserData.business_user_type = details.business_user_type;
               businessUserData.business_user_type_id =
                 details.business_user_type_id;
               businessUserData.id = details.id;
               businessUserData.age = calculate_age(details.date_of_birth);
               businessUserData.profile_summary = details.profile_summary;
               businessUserData.phone_number = details.phone_number ? details.phone_code.concat(details.phone_number) : "";
               businessUserData.schedule_type = details.schedule_type ? details.schedule_type : "";
             
               /* Working Hours secton */
               if (
                 "null" === details.working_hours ||
                 null == details.working_hours
               ) {
                 let working_hours = {
                   morning_shift_start: "",
                   morning_shift_end: "",
                   evening_shift_start: "",
                   evening_shift_end: "",
                   hours_24: false,
                 };
                 businessUserData.working_hours = working_hours;
               } else
                {
                 businessUserData.working_hours = details.working_hours;
               }
   
               /* Working Days section */
               if (null == details.working_days) {
                 details.working_days = [];
               } else {
                 businessUserData.working_days = details.working_days;
                 if (7 === businessUserData.working_days.length) {
                   businessUserData.working_all_days = true;
                 } else {
                   businessUserData.working_all_days = false;
                 }
               }
   
                let speciality = [];
               let specialities_array = this.props.appdata
                 ? this.props.appdata[0].specialities
                 : []; 
                 console.log(details.speciality);
               if (details.speciality) {
                 specialities_array.forEach((element) => {
                 // console.log(details.speciality.includes(element.id));
                   if (details.speciality.includes(element.id) === true && details.speciality===element.id.toString()) {
                     speciality.push({
                       value: element.id,
                       label: element.name,
                     });
                   }
                 });
               }
               businessUserData.speciality = speciality;
   
               businessUserData.language = stringToLabelValue(
                 businessUserData.language
               );
   
               businessUserData.educational_qualification = details.educational_qualification;
               businessUserData.certification = details.certification;
               businessUserData.experience = details.experience;
               businessUserData.medical_licence_details = 
                 details.medical_licence_details;
   console.log(businessUserData);
               this.setState({ businessUserData,payment_details:businessUserData.payment_details });
               this.setState({ loading: false });
             }
           })
           .catch((e) => {
             console.log(e);
             console.log("business user getbyid failed");
           });


              toast.success("Your profile has been updated successfully.",{
                className:"toast-success"
              });
              
            }
            if (value.data.status === "0") {
              this.setState({ loading: false });
              this.setState({ error: value.data.message });
              toast.error("Profile could not be Updated.",{
                className:"toast-error"
              });
            }
          });
        } catch (e) {
          alert(e);
        }
      }
    } else {
      this.setState({
        isDisable: !this.state.isDisable,
      });
    }
    this.setState({ pageAction: "Save" });
  };

  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    let { businessUserData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;
        // console.log(val);
        if (addressType === "administrative_area_level_1")
          businessUserData.state = val;
        if (addressType === "locality")
          businessUserData.city = val;
        if (addressType === "country") businessUserData.country = val;
        // if (addressType === "street_number") {

        businessUserData.address1 = place.name;
        // }

        if (addressType === "postal_code") businessUserData.zip_code = val;
      }
    }

    this.setState({
      businessUserData,
      google_address: true,
    });
  }

  handleExperiencePlaceSelect() {
    let place = this.autocomplete_ex_address ? this.autocomplete_ex_address.getPlace():"";

    

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
if(place != ""){
  let { experiences_formData } = this.state;
  for (var i = 0; i < place.address_components.length; i++) {
    var addressType = place.address_components[i].types[0];

    if (componentForm[addressType]) {
      var val = place.address_components[i][componentForm[addressType]];

      if (addressType === "administrative_area_level_1")
        experiences_formData.ex_state = val;
      if (addressType === "locality")
        experiences_formData.ex_city = val;
      if (addressType === "country") experiences_formData.ex_country = val;

      experiences_formData.ex_address = place.name;
      if (addressType === "postal_code") {
        experiences_formData.ex_zipcode = val;
         }
    }
  }

  this.setState({
    experiences_formData,
    google_address: true,
  });
}
    
  }

  handleInputChange = (e) => {
    let data = this.state.businessUserData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (
      name ==="age" ||
      name === "address1"||
      name === "city" ||
      name === "zip_code" ||
      name === "licence_id"||
      name === "qualification"||
      name ==="valid_URL"
      
    ) {
      this.validateMaxLength(name, value, max);
    } else {
    data[name] = value;
    }
    this.setState({ businessUserData: data });
  };

  validateMaxLength(name, value, max) {
    let data = this.state.businessUserData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ businessUserData: data, errors: errors });
  }

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.businessUserData;
    data[e.name] = selectedOptions;
    this.setState({ businessUserData: data });
  };

  /* for country detail change */
  selectCountry(val) {
    let data = this.state.businessUserData;
    data.country = val;
    this.setState({ businessUserData: data });
  }

  /* for state detail change */
  selectState(val) {
    let data = this.state.businessUserData;
    data.state = val;
    this.setState({ businessUserData: data });
  }

  handlePhoneInputChange = (event) => {
    let businessUserData = this.state.businessUserData;
    businessUserData.phone_number = event;
    this.setState({ businessUserData });
  };

  /* for work detail change */
  handleWorkhoursChange(field, value) {
    let data = this.state.businessUserData;
    let working_hours_data =  data.working_hours;
    console.log(working_hours_data);
    if ("24Hours" === field && (typeof working_hours_data != "string")) {
      working_hours_data.hours_24 = !working_hours_data.hours_24;
      working_hours_data.morning_shift_start = '00:00';
      working_hours_data.morning_shift_end = '11:59';
      working_hours_data.evening_shift_start = '12:00';
      working_hours_data.evening_shift_end = '23:59';


    } else {
      working_hours_data.hours_24 = false;
      working_hours_data[`${field}`] = value.target.value;
    }

    data.working_hours = working_hours_data;

    this.setState({ businessUserData: data });
  }

  handleWorkingDays = (e) => {
    let data = this.state.businessUserData;

    let working_all_days_data = data.working_all_days;

    if ("allDaysId" === e.target.id) {
      working_all_days_data = !working_all_days_data;

      data.working_all_days = working_all_days_data;

      data.working_all_days
        ? (data.working_days = working_days)
        : (data.working_days = []);
    } else {
      const newSelection = e.target.value;
      let newSelectionArray;

      if (data.working_days.indexOf(newSelection) > -1) {
        newSelectionArray = data.working_days.filter((s) => s !== newSelection);
      } else {
        newSelectionArray = [...data.working_days, newSelection];
      }

      data.working_days = newSelectionArray;
      let wd = [];
      data.working_days.forEach((element) => {
        if (working_days.indexOf(element) > -1) {
          wd.push(element);
        }
      });

      data.working_days = wd;
    }

    data.working_days.length === 7
      ? (data.working_all_days = true)
      : (data.working_all_days = false);

    this.setState({ businessUserData: data });
  };
  handleSechduleType = (e) => {
    let data = this.state.businessUserData;
    data.schedule_type = [e.target.value];
    this.setState({ businessUserData: data });
  };

  enableHours() {
    let field = this.state.businessUserData;
    this.setState({ businessUserData: !field.hoursdisabled });
  }

  /* for education change */
  handleCertificationChange(field, value) {
    let fields = this.state.certifications_formData;

    if (field === "organization") {
      fields.organization = value.target.value;
    } else if (field === "specialization") {
      fields.specialization = value.target.value;
    } else if (field === "valid_URL") {
      fields.valid_URL = value.target.value;
    } else if (field === "certification_completion_month") {
      fields.certification_completion_month = value;
    } else if (field === "certification_completion_year") {
      fields.certification_completion_year = value;
    } else if (field === "certification_completion_year") {
      fields.certification_completion_year = value;
    } else if (field === "valid_upto_month") {
      fields.valid_upto_month = value;
    } else if (field === "valid_upto_year") {
      fields.valid_upto_year = value;
    }
    this.setState({
      certifications_formData: fields,
    });
  }
  handleCertificateInputChange = (e) => {
    let data = this.state.certifications_formData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "specialization" ||
      name === "organization" ||
      name === "valid_URL"
      
    ) {
      this.validateMaxLengthforcertificates(name, value, max);
    } else {
      data[name] = value;
    }
   
    
    this.setState({ certifications_formData: data });
  };

  validateMaxLengthforcertificates(name, value, max) {
    let data = this.state.certifications_formData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ certifications_formData: data, errors: errors });
  }
  /* for certifications change */
  addCertificationDetail() {
    let formData = this.state.certifications_formData;
    formData.type = "addCertificate";
    let { certificate_errors } = this.state;
    certificate_errors = Validation(formData, certificate_errors);
    this.setState({ certificate_errors: certificate_errors });

    if (certificate_errors.formIsValid) {
      let records = this.state.businessUserData.certification;
      let record = "";
      /* if id is present then update or consider as new record */
      if ("" === formData.id) {
        record = Object.assign({}, cert_record);
        record.id = getMaxIdOfRecords(records);
        record.s_no = records.length + 1;
      } else {
        const updateRecordIndex = records
          .map((item) => item.id)
          .indexOf(formData.id);
        record = records[updateRecordIndex];
      }
      record.organization = formData.organization;
      record.specialization = formData.specialization;
      record.valid_URL = formData.valid_URL;
      record.completion_year_month =
        MONTH_PAIR.get(formData.certification_completion_month) +
        "-" +
        formData.certification_completion_year;
      record.valid_date =
        MONTH_PAIR.get(formData.valid_upto_month) +
        "-" +
        formData.valid_upto_year;

      if ("" === formData.id) {
        records.push(record);
      }

      let initalData = Object.assign({}, cert_record);

      this.setState({
        certifications_formData: initalData,
        certAction: "",
        certEdit: false,
      });
    }
  }

  /* for education change */
  addExperienceDetail() {
    let formData = this.state.experiences_formData;
    formData.type = "addExperience";
    let { experiences_errors } = this.state;
    console.log(experiences_errors);
    experiences_errors = Validation(formData, experiences_errors);
    this.setState({ experiences_errors: experiences_errors });
    let records = this.state.businessUserData.experience;
    console.log(formData);
 if (experiences_errors.formIsValid) {
      console.log(records);
      if ("" === formData.id) {
        let record = Object.assign({}, experience_record);
        record.id = getMaxIdOfRecords(records);
       
        if (records.length > 0) {
          let maxId = Math.max.apply(
            Math,
            records.map(function (o) {
              return o.id;
            })
          );
          record.s_no = maxId + 1;
        } else {
          record.s_no = records.length + 1;
        }

        record.hospital = formData.hospital;
        record.ex_address = formData.ex_address;
        record.ex_city = formData.ex_city;
        record.ex_state = formData.ex_state;
        record.ex_country = formData.ex_country;
        record.ex_zipcode=formData.ex_zipcode;
        record.experience_from = formData.experience_from;
        record.experience_to = formData.experience_to;
        record.s_no = records.length + 1;

        records.push(record);
        //this.setState({ experiences: records });
      } else {
        const updateRecordIndex = records
          .map((item) => item.id)
          .indexOf(formData.id);
        let record = records[updateRecordIndex];
        console.log(records);
        console.log(updateRecordIndex);
        console.log(records);
        console.log(record);
        record.hospital = formData.hospital;
        record.ex_address = formData.ex_address;
        record.ex_city = formData.ex_city;
        record.ex_state = formData.ex_state;
        record.ex_country = formData.ex_country;
        record.ex_zipcode=formData.ex_zipcode;
        record.experience_from = formData.experience_from;
        record.experience_to = formData.experience_to;
      }

      let initalData = Object.assign({}, experience_record);

      this.setState({
        experiences_formData: initalData,
        exAction: "",
        exEdit: false,
      });
      console.log(this.state.businessUserData.experience);
    }
  }

  editExperienceRecord(record) {
    let dataObj = this.state.experiences_formData;
    console.log(dataObj);
    // return false;
    dataObj.id = record.id;
    dataObj.hospital = record.hospital;
    dataObj.ex_address = record.ex_address;
    dataObj.ex_city = record.ex_city;
    dataObj.ex_state = record.ex_state;
    dataObj.ex_country = record.ex_country;
    dataObj.ex_zipcode=record.ex_zipcode;
    dataObj.experience_from = record.experience_from? moment(
      record.experience_from,
      moment.defaultFormat
    ).toDate():"";
    dataObj.experience_to = record.experience_to? moment(
      record.experience_to,
      moment.defaultFormat
    ).toDate():"";
    console.log(dataObj);
    this.setState({ experiences_formData: dataObj });
  }

  editCertificateRecord(record) {
    let dataObj = this.state.certifications_formData;
    dataObj.id = record.id;
    dataObj.organization = record.organization;
    dataObj.specialization = record.specialization;
    dataObj.valid_URL = record.valid_URL;
    let completion = record.completion_year_month.split("-");
    dataObj.certification_completion_month = getKeyFromValue(
      MONTH_PAIR,
      completion[0]
    ).toString();
    dataObj.certification_completion_year = completion[1];
    let validity = record.valid_date.split("-");
    dataObj.valid_upto_month = getKeyFromValue(
      MONTH_PAIR,
      validity[0]
    ).toString();
    dataObj.valid_upto_year = validity[1];
  }

  handleExperienceChange(field, value) {
    let fields = this.state.experiences_formData;

    if (field === "hospital") {
      fields.hospital = value.target.value;
    } else if (field === "ex_address") {
      fields.ex_address = value.target.value;
    } else if (field === "ex_city") {
      fields.ex_city = value.target.value;
    } else if (field === "ex_state") {
      fields.ex_state = value;
    } else if (field === "ex_country") {
      fields.ex_country = value;
    } else if (field === "experience_from") {
      fields.experience_from = value;
    } else if (field === "experience_to") {
      fields.experience_to = value;
    }

    this.setState({
      experiences_formData: fields,
    });
  }
  handleExperienceInputChange = (e) => {
    let data = this.state.experiences_formData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "hospital" ||
      name === "ex_address" ||
      name === "ex_city" 
      
      
    ) {
      this.validateMaxLengthforexperiences(name, value, max);
    } else {
      data[name] = value;
    }
   
    
    this.setState({ experiences_formData: data });
  };
  validateMaxLengthforexperiences(name, value, max) {
    let data = this.state.experiences_formData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ experiences_formData: data, errors: errors });
  }

  /*Medical Licence datatable add, edit */
  
  handleMedicalLicenceInputChange = (e) => {
    let data = this.state.medical_licence_formData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "organization" ||
      name === "licence_Id" 
        
      
    ) {
      this.validateMaxLengthformedicallicence(name, value, max);
    } else {
      data[name] = value;
    }
   
    
    this.setState({ medical_licence_formData: data });
  };
  validateMaxLengthformedicallicence(name, value, max) {
    let data = this.state.medical_licence_formData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ medical_licence_formData: data, errors: errors });
  }

  addMedicalLicenceDetail() {
    let formData = this.state.medical_licence_formData;
    formData.type = "addMedicalLicence";
    let { medical_licence_error } = this.state;
    medical_licence_error = Validation(formData, medical_licence_error);
    this.setState({ medical_licence_error: medical_licence_error });

    if (medical_licence_error.formIsValid) {
      let records = this.state.businessUserData.medical_licence_details;
      let record = "";
      if ("" === formData.id) {
        let record = Object.assign({}, medical_licence_record);
         record.id = getMaxIdOfRecords(records);
       
        if (records.length > 0) {
          let maxId = Math.max.apply(
            Math,
            records.map(function (o) {
              return o.id;
            })
          );
          record.s_no = maxId + 1;
        } else {
          record.s_no = records.length + 1;
        }
        record.organization = formData.organization;
        record.licence_Id = formData.licence_Id;
        record.expiration_date = formData.expiration_date;
        record.s_no = records.length + 1;
        console.log(record);
        records.push(record);
      } else {
        const updateRecordIndex = records
          .map((item) => item.id)
          .indexOf(formData.id);
        let record = records[updateRecordIndex];
        record.organization = formData.organization;
        record.licence_Id = formData.licence_Id;
        record.expiration_date = formData.expiration_date;
      }
      
      let initalData = Object.assign({}, medical_licence_record);
      this.setState({
        medical_licence_formData: initalData,
        medAction: "",
        medEdit: false,
      });
    }
  }

  cancelAddEdit(tableName, e) {
    let initalData = "";
    if ("education" === tableName) {
      initalData = Object.assign({}, eq_record);
      this.setState({
        educational_qualification_formData: initalData,
        edAction: "",
        edEdit: false,
      });
    } else if ("certificate" === tableName) {
      initalData = Object.assign({}, cert_record);
      this.setState({
        certifications_formData: initalData,
        certAction: "",
        certEdit: false,
      });
    } else if ("experience" === tableName) {
      initalData = Object.assign({}, experience_record);
      this.setState({
        experiences_formData: initalData,
        exAction: "",
        exEdit: false,
      });
    } else if ("medicalLicence" === tableName) {
      initalData = Object.assign({}, medical_licence_record);
      this.setState({
        medical_licence_formData: initalData,
        medAction: "",
        medEdit: false,
      });
    }
  }

  editMedicalRecord(record) {
    let dataObj = this.state.medical_licence_formData;
    dataObj.id = record.id;
    dataObj.organization = record.organization;
    dataObj.licence_Id = record.licence_Id;
    dataObj.expiration_date = moment(
      record.expiration_date,
      moment.defaultFormat
    ).toDate();
    console.log(dataObj);
    this.setState({ educational_qualification_formData: dataObj });
  }

  handleDatePickerChange = (date, formName) => {
    if ("medical_licence_formData" === formName) {
      let data = this.state.medical_licence_formData;
      const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
      data.expiration_date = offsetDate;
      this.setState({ medical_licence_formData: data });
    }
  };

  handleEducationDetailChange(field, value) {
    let fields = this.state.educational_qualification_formData;

    if (field === "education") {
      fields.education = value.target.value;
    } else if (field === "specialization") {
      fields.specialization = value;
    } else if (field === "university") {
      fields.university = value.target.value;
    } else if (field === "completion_year") {
      fields.completion_year = value;
    } else if (field === "completion_month") {
      fields.completion_month = value;
    }
    this.setState({
      educational_qualification_formData: fields,
    });
  }

  /* for education change */
  addEducationDetail() {
    let formData = this.state.educational_qualification_formData;
    formData.type = "addEducationDetail";
    let { educational_qualification_errors } = this.state;
    educational_qualification_errors = Validation(formData, educational_qualification_errors);
    this.setState({ educational_qualification_errors: educational_qualification_errors });

    if (educational_qualification_errors.formIsValid) {
      let records = this.state.businessUserData.educational_qualification;

      let record = "";
      /* if id is present then update or consider as new record */
      if ("" === formData.id) {
        record = Object.assign({}, cert_record);
        record.id = getMaxIdOfRecords(records);
        record.s_no = records.length + 1;
      } else {
        const updateRecordIndex = records
          .map((item) => item.id)
          .indexOf(formData.id);
        record = records[updateRecordIndex];
      }

      record.education = formData.education;
      record.education_id = formData.education;
      record.specialization_id = formData.specialization.value;
      record.specialization = formData.specialization.label;
      record.university = formData.university;
      record.completion_year_month =
        MONTH_PAIR.get(formData.completion_month) +
        "-" +
        formData.completion_year;

      if ("" === formData.id) {
        records.push(record);
      }

      let initalData = Object.assign({}, eq_record);

      this.setState({
        educational_qualification_formData: initalData,
        edAction: "",
        edEdit: false,
      });
    }
  }

  onDeleteClick(record, tableName) {
    this.setState({ addPopup: true, record: record, tableName: tableName });
  }

  deleteRecord(record, tableName) {
    if ("education" === tableName) {
      let records = this.state.businessUserData.educational_qualification;
      const deleteIndex = records.map((item) => item.id).indexOf(record.id);
      records.splice(deleteIndex, 1);
      toast.success("Education deleted.Please save the changes. ",{
        className:"toast-success"
      });
    } else if ("certificate" === tableName) {
      let records = this.state.businessUserData.certification;
      const deleteIndex = records.map((item) => item.id).indexOf(record.id);
      records.splice(deleteIndex, 1);
      toast.success("Certificate deleted.Please save the changes. ",{
        className:"toast-success"
      });
    } else if ("experience" === tableName) {
      let records = this.state.businessUserData.experience;
      const deleteIndex = records.map((item) => item.id).indexOf(record.id);
      records.splice(deleteIndex, 1);
      toast.success("Experience deleted.Please save the changes. ",{
        className:"toast-success"
      });
    } else if ("medicalLicence" === tableName) {
      let records = this.state.businessUserData.medical_licence_details;
      const deleteIndex = records.map((item) => item.id).indexOf(record.id);
      records.splice(deleteIndex, 1);
      toast.success("Medical license deleted.Please save the changes. ",{
        className:"toast-success"
      });
    }
    this.setState({ addPopup: false, record: "" });
  }

  editEqucationRecord(record) {
    let dataObj = this.state.educational_qualification_formData;
    dataObj.id = record.id;
    dataObj.education = record.education;
    dataObj.specialization = {
      value: record.specialization_id,
      label: record.specialization,
    };
    dataObj.university = record.university;

    var completionObj = record.completion_year_month.split("-");

    dataObj.completion_month = this.getMonthValue(completionObj[0]).toString();

    dataObj.completion_year = completionObj[1];

    this.setState({ educational_qualification_formData: dataObj });
  }

  getMonthValue(val) {
    return [...MONTH_PAIR.entries()]
      .filter(({ 1: v }) => v === val)
      .map(([k]) => k);
  }

  removeCertificationDetail(i) {
    let certifications = [...this.state.certifications];
    certifications.splice(i, 1);
    this.setState({ certifications });
  }
  handleExDateChange = (date) => {
    let businessUserData = this.state.businessUserData;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    businessUserData.expiry_date = offsetDate;
    this.setState({ businessUserData: businessUserData });
  };
  
  DeleteAccount=(user_id,user_type)=> {
   
    // let user_id = this.props.userdata.id;
  //  let user_type=this.props.userdata.user_type;
     axios.defaults.headers.common[
       "Authorization"
     ] = `Bearer ${this.props.access_token}`;
     axios
       .post(DeleteAccount, { user_id: user_id ,user_type:user_type}, CONFIG)
       .then((res) => {
         let response = res.data;
         if (response.status === "1") {
          
           toast.error("Doctor Account deleted. ", {
             className: "toast-error"
           });
          
         }
       })
       .catch((e) => { });
     this.setState({ deletePopup: false, record: null });
 
     this.setState({
       confirmedDelete: false,
     });
   
 
   };
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () =>
      this.deleteRecord(this.state.record, this.state.tableName);
      let PopupdeleteConfirm = () =>
      this.DeleteAccount(this.props.userdata.id,this.props.userdata.user_type);
    let specializations_array = this.props.appdata
      ? this.props.appdata[0].specializations
      : [];
    let specializations = [];
    specializations_array.forEach((element) => {
      specializations.push({ value: element.id, label: element.name });
    });
    specializations_array = specializations;

    const {
      reviewinfo,
      commentsArr,
      businessUserData,
      errors,
      educational_qualification_formData,
      educational_qualification_errors,
      certifications_formData,
      certificate_errors,
      experiences_formData,
      experiences_errors,
      medical_licence_formData,
      medical_licence_error,
      hospitaltimeout,
      payment_details,
      payment_details_error,
    } = this.state;
    const phone_number = businessUserData.phone_number;

    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let specialities = [];
    specialities_array.forEach((element) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;


    return (
      <div className="main-content bacfot">
         <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />

        {/* <Spinner loading={this.state.loading} /> */}
        <div className="mainpage">
          <section className="my-head">
            <div className="container-fluid">
              {/* <ProfileHeader headerdetails={headerinfo}/> */}
              <BusinessUserHeader user_id={this.props.userdata.id} />
            </div>
          </section>
          <div className="main-box">
            <div className="sidemenu pr0">
              <SideBar />
            </div>
            <Popup
                                      show={this.state.deletePopup}
                                      onHide={() =>
                                        this.setState({
                                          deletePopup: false,
                                        })
                                      }
                                      onConfirm={PopupdeleteConfirm}
                                      title={"Confirmation"}
                                      message={
                                        "Are you sure you want to delete your account?"
                                      }
                                    />

            <section className="main-body hospital-profilegap">
            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

              <div className="container-fluid">
                <div className="checkout-tabs">
                  
                <h4 className="font-weight-bold ">Profile Summary</h4>
                
                <br/>
                <table className="deleteaccountbtn mb-2">
                              <tbody>
                                <tr>
                                        <td></td>
                                  <td>
                                         <button
                                            type="submit"
                                            onClick={() =>
                                              this.setState({ deletePopup: true})
                                            }
                                            className="s3 float-right"
                                          >
                                            Delete Account
                                          </button>
                                          </td>
                                        </tr>
                                        </tbody></table>
             
                <div className="row">
                        <div
                          className={
                            this.state.expandmemDisabled
                              ? "col-sm-12"
                              : "col-sm-7 "
                          }
                        >


                          <div
                            className={
                              this.state.pageAction === "Edit"
                                ? "card non-Editable"
                                : "card"
                            }
                          >
                            <div className="card-body">
                              <div className="mb-4">
                                <ul className="nav nav-pills manag" role="tablist">
                                  <li className="nav-item waves-effect waves-light">
                                    <a
                                      className={
                                        this.state.expandmemDisabled
                                          ? "nav-link btnview auto-width"
                                          : "nav-link active btnview auto-width"
                                      }
                                      onClick={() => {
                                        this.setState({
                                          expandmemDisabled: !this.state
                                            .expandmemDisabled,
                                        });
                                        this.setState({
                                          perandspeDisabled: !this.state
                                            .perandspeDisabled,
                                        });
                                      }}
                                    >
                                      <span className=" d-sm-block">
                                        {" "}
                                        Personal &amp; Speciality{" "}
                                      </span>
                                    </a>
                                  </li>
                                  <li className="nav-item waves-effect waves-light">
                                    <a
                                      className={
                                        this.state.expandmemDisabled
                                          ? "nav-link active btnview auto-width"
                                          : "nav-link  btnview auto-width"
                                      }
                                      onClick={() => {
                                        this.setState({
                                          perandspeDisabled: !this.state
                                            .perandspeDisabled,
                                        });
                                        this.setState({
                                          expandmemDisabled: !this.state
                                            .expandmemDisabled,
                                        });
                                      }}
                                    >
                                      <span className=" d-sm-block">
                                        Experience &amp; Membership{" "}
                                      </span>
                                    </a>
                                  </li>

                                  <li className="nav-item waves-effect waves-light">
                                    <button
                                      className="signupbtn abtn mtlogin"
                                      onClick={this.submitData}
                                    >
                                      {this.state.pageAction === "Edit"
                                        ? "Edit"
                                        : "Save"}
                                    </button>
                                  </li>
                                
                          
                                </ul>
                              </div>
                             
                             
                                        
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div
                                    className="row"
                                    style={{
                                      display: this.state.perandspeDisabled
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    <div className="col-sm-12">
                                  
                                      <div className="form-group frllftfull">
                                      <label>Profile Summary</label>
                                        <TextArea
                                          rows={5}
                                          resize={true}
                                          content={
                                            businessUserData.profile_summary ? businessUserData.profile_summary.trim() : ""
                                          }
                                          name={"profile_summary"}
                                          controlFunc={this.handleInputChange}
                                          placeholder={""}
                                          isvisible={this.state.isDisable ? "true" :  undefined}
                                          maxLength={200}
                                        />
                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="First Name" isMandatory={true} />
                                        </div>

                                        <div className="form-group  form-control  frlrit">
                                          <label>
                                            {businessUserData.first_name.trim()}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Last Name" isMandatory={true} />  
                                        </div>
                                        <div className="form-group   form-control frlrit">
                                          <label>
                                            {businessUserData.last_name.trim()}
                                          </label>

                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Age" isMandatory={true} /> 
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <InputField
                                            isMandatory={true}
                                            inputType={"text"}
                                            name={"age"}
                                            controlFunc={this.handleInputChange}
                                            content={businessUserData.age}
                                            placeholder={""}
                                            isvisible={this.state.isDisable ? "true" :  undefined}
                                            max={3}
                                          />
                                        </div>
                                        <div className="form-group frlrclfgf-1">
                                        <Label title="Sex" isMandatory={true} />   
                                        </div>
                                        <div className="form-group sform-control frlrclfgf">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="gender"
                                            value={businessUserData.gender}
                                            onChange={this.handleselectChange}
                                            options={gender}
                                            isvisible={this.state.isDisable ? "true" :  undefined}
                                          />
                                        </div>
                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Blood Group" isMandatory={true} />    
                                        </div>
                                        <div className="form-group sform-control frlrclfgf">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="blood_group"
                                            value={businessUserData.blood_group}
                                            onChange={this.handleselectChange}
                                            options={blood_group}
                                          />
                                        </div>
                                        <div className="form-group frlrclfgf-1">
                                        <Label title="Nationality" isMandatory={true} />  
                                        </div>
                                        <div className="form-group sform-control frlrclfgf">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="nationality"
                                            value={businessUserData.nationality}
                                            onChange={this.handleselectChange}
                                            options={nationality}
                                          />
                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Language" isMandatory={true} />   
                                        </div>
                                        <div className="form-group sform-control frlrit">
                                          <Select maxMenuHeight={120}
                                            className="form-controls w-100 p-0 "
                                            isMulti
                                            name="language"
                                            value={businessUserData.language}
                                            onChange={this.handleselectChange}
                                            options={language}
                                            isvisible={this.state.isDisable ? "true" :  undefined}
                                          />
                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Address" isMandatory={true} />
                                        </div>
                                        <div className="form-group  frlrit">
                                          <InputField
                                            isMandatory={true}
                                            id="address1"
                                            inputType={"text"}
                                            name={"address1"}
                                            controlFunc={this.handleInputChange}
                                            content={businessUserData.address1.trim()}
                                            placeholder={""}
                                            isvisible={this.state.isDisable ? "true" :  undefined}
                                            max={100}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={
                                              errors.address1
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="City" isMandatory={true} />   
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <InputField
                                            inputType={"text"}
                                            name={"city"}
                                            controlFunc={this.handleInputChange}
                                            content={businessUserData.city.trim()}
                                            placeholder={""}
                                            isvisible={this.state.isDisable ? "true" :  undefined}
                                            max={30}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={
                                              errors.city
                                            }
                                          />
                                        </div>
                                        <div className="form-group frlrclfgf-1">
                                        <Label title="State" isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <RegionDropdown
                                            name="state"
                                            defaultOptionLabel="Select State"
                                            country={businessUserData.country}
                                            value={businessUserData.state || ""}
                                            className="form-control"
                                            onChange={(val) =>
                                              this.selectState(val)
                                            }
                                            placeholder="State"
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={
                                              errors.state
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Country" isMandatory={true} /> 
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <CountryDropdown
                                            placeholder="Country"
                                            name="country"
                                            value={businessUserData.country || ""}
                                            className="form-control"
                                            onChange={(val) =>
                                              this.selectCountry(val)
                                            }
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={
                                              errors.country
                                            }
                                          />
                                        </div>
                                        <div className="form-group frlrclfgf-1">
                                        <Label title="Zip" isMandatory={true} />  
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <InputField
                                            inputType={"text"}
                                            name={"zip_code"}
                                            controlFunc={this.handleInputChange}
                                            content={businessUserData.zip_code.trim()}
                                            placeholder={""}
                                            isvisible={this.state.isDisable ? "true" :  undefined}
                                            max={10}
                                          />{" "}
                                          <SpanField
                                            classname="text-danger"
                                            title={
                                              errors.zip_code
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group  frllft">
                                        <Label title="Phone" isMandatory={true} /> 
                                        </div>
                                        <div className=" frlrit">
                                          <div className=" form-control ">

                                            <PhoneInput
                                              international
                                              country="IN"
                                              defaultCountry="IN"
                                              placeholder="Enter phone number"
                                              readOnly={true}
                                              value={phone_number}
                                              max={10}

                                              onChange={
                                                this.handlePhoneInputChange
                                              }
                                              error={
                                                phone_number
                                                  ? isValidPhoneNumber(
                                                    phone_number
                                                  )
                                                    ? undefined
                                                    : "Invalid phone number"
                                                  : "Phone number required"
                                              }
                                              isvisible={this.state.isDisable ? "true" :  undefined}

                                            />
                                          </div>

                                          <SpanField
                                            classname="text-danger"
                                            title={errors.phone_number}
                                          />
                                        </div>

                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group  frllft">
                                        <Label title="Schedule Type" isMandatory={true} /> 
                                        </div>
                                        <div className=" frlrit">
                                          <div className="form-group ">
                                          <CheckboxOrRadioGroup
                                           setName={"schedule_type"}
                                             type={"radio"}
                                             disabled={true}
                                             controlFunc={this.handleSechduleType}
                                             options={scuedueType}
                                               selectedOptions={[businessUserData.schedule_type]}
                                              />
                                          
                                          </div>

                                          <SpanField
                                            classname="text-danger"
                                            title={errors.schedule_type}
                                          />
                                        </div>

                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group frllft ">
                                        <Label className="mt-0" title="Working Days" isMandatory={true} />
                                        </div>
                                        <div className=" frlrit">
                                          <div className=" mt-lg-0">

                                            <CheckboxOrRadioGroup
                                              setName={"working_days"}
                                              type={"checkbox"}
                                              controlFunc={this.handleWorkingDays}
                                              options={working_days}
                                              name={"working_days"}
                                              selectedOptions={
                                                businessUserData.working_days
                                              }
                                            />
                                          </div>


                                          <div className="square-switch">
                                            <input
                                              type="checkbox"
                                              id="allDaysId"
                                              switch="none"
                                              onChange={this.handleWorkingDays}
                                              checked={
                                                businessUserData.working_all_days
                                              }
                                            />
                                            <label
                                              htmlFor="allDaysId"
                                              data-on-label="All days"
                                              data-off-label="All days"
                                              className="dcte22 wid-100 bm-5"
                                            ></label>
                                          </div>
                                        </div>
                                        <SpanField
                                          classname="text-danger offset-3"
                                          title={errors.working_days}
                                        />
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Morning Working Hours" isMandatory={true} />
                                        </div>

                                        <div className="form-group frlrclfgf pdls">
                                          {/* <TimePicker
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "morning_shift_start"
                                            )}
                                            //step={60}
                                            start="0"
                                            end="24"
                                            value={
                                              businessUserData.working_hours
                                                .morning_shift_start
                                                ? businessUserData.working_hours
                                                  .morning_shift_start
                                                : ""
                                            }
                                            placeholder="From"
                                          /> */}
                                          <input
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            type="time"
                                            className="form-control"
                                            placeholder=""
                                            name="morning_shift_start"
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "morning_shift_start"
                                            )}
                                            value={businessUserData.working_hours
                                              ? businessUserData
                                                .working_hours
                                                .morning_shift_start
                                              : ""}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.working_hours_morning}
                                          />
                                        </div>

                                        <div className="form-group frlrclfgf pdrs">
                                          {/* <TimePicker
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "morning_shift_end"
                                            )}
                                            // step={60}
                                            start="0"
                                            end="24"
                                            value={
                                              businessUserData.working_hours
                                                .morning_shift_end
                                            }
                                            placeholder="To"
                                          /> */}
                                          <input
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            type="time"
                                            className="form-control"
                                            placeholder=""
                                            name="morning_shift_end"
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "morning_shift_end"
                                            )}
                                            value={businessUserData.working_hours
                                              ? businessUserData
                                                .working_hours
                                                .morning_shift_end
                                              : ""}
                                          />
                                        </div>

                                        <div className="form-group frlrclfgf-1">
                                          <div className="square-switch">
                                            <input
                                              type="checkbox"
                                              id="square-switch33"
                                              switch="none"
                                              onChange={this.handleWorkhoursChange.bind(
                                                this,
                                                "24Hours"
                                              )}
                                              checked={
                                                businessUserData.working_hours
                                                  .hours_24
                                              }
                                            />
                                            <label
                                              htmlFor="square-switch33"
                                              data-on-label="24 hours"
                                              data-off-label="24 hours"
                                              className="dcte22 wid-100 bm-5"
                                            ></label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Evening Working Hours" isMandatory={true} /> 
                                        </div>

                                        <div className="form-group frlrclfgf pdls">
                                          {/* <TimePicker
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "evening_shift_start"
                                            )}
                                            // step={60}
                                            start="0"
                                            end="24"
                                            value={
                                              businessUserData.working_hours
                                                .evening_shift_start
                                            }
                                            placeholder="From"
                                          /> */}
                                          <input
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            type="time"
                                            className="form-control"
                                            placeholder=""
                                            name="evening_shift_start"
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "evening_shift_start"
                                            )}
                                            value={businessUserData.working_hours
                                              ? businessUserData
                                                .working_hours
                                                .evening_shift_start
                                              : ""}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.evening_shift_start}
                                          />
                                        </div>

                                        <div className="form-group frlrclfgf pdrs">
                                          {/* <TimePicker
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "evening_shift_end"
                                            )}
                                            // step={60}
                                            start="0"
                                            end="24"
                                            value={
                                              businessUserData.working_hours
                                                .evening_shift_end
                                            }
                                            placeholder="To"
                                          /> */}
                                          <input
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            type="time"
                                            className="form-control"
                                            placeholder=""
                                            name="evening_shift_end"
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "evening_shift_end"
                                            )}
                                            value={businessUserData.working_hours
                                              ? businessUserData
                                                .working_hours
                                                .evening_shift_end
                                              : ""}
                                          />
                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft">

                                          <Label title="Medical License ID" isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <InputField
                                            isMandatory={true}
                                            inputType={"text"}
                                            // title={"Medical License ID"}
                                            name={"licence_id"}
                                            controlFunc={this.handleInputChange}
                                            content={businessUserData.licence_id}
                                            placeholder={""}
                                            max={50}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.licence_id}
                                          />
                                        </div>
                                        <div className="form-group frlrclfgf-1">
                                          <Label title="Expiry Date" isMandatory={true} />
                                        </div>
                                        <div className="form-group  frlrclfgf">
                                          <DatePicker
                                            className="form-control"
                                            value={businessUserData.expiry_date}
                                            format="dd-MM-yyyy"
                                            onChange={(date) => this.handleExDateChange(date)}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.expiry_date}
                                          />{" "}
                                        </div>
                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                          <Label title="Degree (Ex: M.B.B.S., M.D., F.R.C.S.,)" isMandatory={true}/>
                                          {/* <StarField /> */}
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <InputField
                                            inputType={"text"}
                                            name={"qualification"}
                                            controlFunc={this.handleInputChange}
                                            content={businessUserData.qualification}
                                            placeholder={""}
                                            max={50}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.qualification}
                                          />
                                          
                                        </div>
                                      </div>
                                      <div className="frllftfull mb-5 ">
                                        <div className="form-group frllft">
                                        <Label title="Speciality" isMandatory={true} />

                                        </div>
                                        <div className="form-group sform-control frlrit" >
                                      {/*     <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="speciality"
                                            isMulti
                                            value={businessUserData.speciality}
                                            onChange={this.handleselectChange}
                                            options={specialities_array}
                                          /> */}
                                            <Select maxMenuHeight={120}
                                                className="form-control p-0"
                                                name="speciality"
                                                value={businessUserData.speciality}
                                                onChange={
                                                  this.handleselectChange
                                                }
                                                options={specialities_array}
                                              />
                                        </div>
                                      </div>
                                     
                                    </div>
                                  </div>

                                  {/** experience and membership section */}

                                  <div
                                    className=""
                                    style={{
                                      display: this.state.expandmemDisabled
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    <div className="">
                                      <div
                                        className="formlayout ml-0 pb-15 row ml-0"
                                        style={{
                                          paddingBottom: "0",
                                          marginBottom: "0",
                                        }}
                                      >
                                        <div className="col-sm-12">
                                          <h5 className=" mt-0 mb-15">
                                            <b>Medical Licence</b>
                                          {/*   <button
                                              style={{
                                                display: this.state.isDisable
                                                  ? "none"
                                                  : "",
                                              }}
                                              type="button"
                                              className="btn btn-blue btn-sm btn-space"
                                              onClick={() => {
                                                this.setState({
                                                  medEdit: true,
                                                });
                                              }}
                                            >
                                              <i className="fa fa-plus"></i>
                                            </button> */}
                                          </h5>
                                        </div>
                                        {this.state.businessUserData
                                              .medical_licence_details && this.state.businessUserData
                                              .medical_licence_details.length ? ( 
                                              <div
                                                className={
                                                  this.state.businessUserData
                                                    .medical_licence_details && this.state.businessUserData
                                                    .medical_licence_details.length > 0
                                                    ? "show-reactData-table w-100"
                                                    : "hide-reactData-table col-sm-12"
                                                }
                                              >
                                                <ReactDatatable
                                                className="table table-bordered table-striped kas  mobile_table"
                                                  config={this.config}
                                                  records={
                                                    this.state.businessUserData
                                                      .medical_licence_details
                                                  }
                                                  columns={this.medical_licence_columns}
                                                  id="Medical_datatable"
                                                  
                                                />
                                              </div>
                                          ):""}
                                       

                                        <div
                                          className="row nomargin"
                                         /*  style={{
                                            display: this.state.medEdit
                                              ? "flex"
                                              : "none",
                                          }} */
                                        >
                                          <Fragment>
                                            <div className="col-sm-3">
                                              <div className="form-group">
                                                <InputField
                                                  id="organization"
                                                  inputType={"text"}
                                                  title={"Organization"}
                                                  name={"organization"}
                                                  controlFunc={
                                                    this
                                                      .handleMedicalLicenceInputChange
                                                  }
                                                  content={
                                                    medical_licence_formData.organization
                                                  }
                                                  placeholder={""}
                                                  max={100}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    medical_licence_error.organization
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="form-group">
                                                <InputField
                                                  id="licence_Id"
                                                  inputType={"text"}
                                                  title={"Licence ID"}
                                                  name={"licence_Id"}
                                                  controlFunc={
                                                    this
                                                      .handleMedicalLicenceInputChange
                                                  }
                                                  content={
                                                    medical_licence_formData.licence_Id
                                                  }
                                                  placeholder={""}
                                                  max={50}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    medical_licence_error.licence_Id
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="datepickerdiv">
                                              <label>Expiration Date</label>

                                              <DatePicker
                                                className="form-control"
                                                value={
                                                  medical_licence_formData.expiration_date
                                                }
                                                format="dd-MM-yyyy"
                                                onChange={(date) =>
                                                  this.handleDatePickerChange(
                                                    date,
                                                    "medical_licence_formData"
                                                  )
                                                }
                                                yearPlaceholder='YYYY'
                                                monthPlaceholder='MM'
                                                dayPlaceholder='DD'
                                              />
                                              <SpanField
                                                classname="text-danger"
                                                title={
                                                  medical_licence_error.expiration_date
                                                }
                                              />
                                            </div>
                                            <div className="col-sm-3">
                                              <label></label>
                                              <div className="row">
                                                <button
                                                  type="button"
                                                  onClick={this.addMedicalLicenceDetail.bind(
                                                    this
                                                  )}
                                                  className="plusbtn-lft plusbtn-position"
                                                >
                                                  <i
                                                    className={
                                                      "edit" ===
                                                        this.state.medAction
                                                        ? "fa fa-edit"
                                                        : "fa fa-plus"
                                                    }
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                               {/*  <button
                                                  type="button"
                                                  onClick={this.cancelAddEdit.bind(
                                                    this,
                                                    "medicalLicence"
                                                  )}
                                                  className="plusbtn-lft plusbtn-position"
                                                >
                                                  <i
                                                    className="fa fa fa-ban"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button> */}
                                              </div>
                                            </div>
                                          </Fragment>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div
                                        className="formlayout ml-0 pb-15 ml-0 row"
                                        style={{
                                          paddingBottom: "0",
                                          marginBottom: "0",
                                        }}
                                      >
                                        <div className="col-sm-12">
                                          <h5 className=" mt-0 mb-15">
                                            <b>Experience</b>

                                            {/* <button
                                              style={{
                                                display: this.state.isDisable
                                                  ? "none"
                                                  : "",
                                              }}
                                              type="button"
                                              className="btn btn-blue btn-sm btn-space"
                                              onClick={() => {
                                                this.setState({
                                                  exEdit: true,
                                                });
                                              }}
                                            >
                                              <i className="fa fa-plus"></i>
                                            </button> */}
                                          </h5>
                                        </div>
{
  this.state.businessUserData
  .experience && this.state.businessUserData.experience
  .length ? (<div
    className={
      this.state.businessUserData.experience && this.state.businessUserData.experience
        .length > 0
        ? "show-reactData-table w-100"
        : "hide-reactData-table col-sm-12"
    }
  >
    <ReactDatatable
    className="table table-bordered table-striped kas  mobile_table"
      id="ex_datatable"
      config={this.config}
      records={
        this.state.businessUserData
          .experience
      }
      columns={this.experienceColumns}
   //   key={this.state.businessUserData.experience.map(item => item.id).join('-')}
    />
  </div>) : ""
}
                                        

                                        <div
                                          className="row"
                                         /*  style={{
                                            display: this.state.exEdit
                                              ? "flex"
                                              : "none",
                                          }} */
                                        >
                                          <Fragment>
                                            <div className="col-sm-3">
                                              <div className="form-group">
                                                <InputField
                                                  id="hospital"
                                                  inputType={"text"}
                                                  title={"Hospital"}
                                                  name={"hospital"}
                                                  controlFunc={this.handleExperienceInputChange}
                                                  content={
                                                    experiences_formData.hospital
                                                  }
                                                  placeholder={""}
                                                  max={50}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    experiences_errors.hospital
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="form-group">
                                                <InputField
                                                  id="ex_address"
                                                  inputType={"text"}
                                                  title={"Address"}
                                                  name={"ex_address"}
                                                  controlFunc={
                                                    this
                                                      .handleExperienceInputChange
                                                  }
                                                  content={
                                                    experiences_formData.ex_address
                                                  }
                                                  placeholder={""}
                                                  max={100}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    experiences_errors.ex_address
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-2">
                                              <div className="form-group">
                                                <InputField
                                                  inputType={"text"}
                                                  title={"City"}
                                                  name={"ex_city"}
                                                  controlFunc={this.handleExperienceInputChange}
                                                  content={
                                                    experiences_formData.ex_city
                                                  }
                                                  placeholder={""}
                                                  max={30}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    experiences_errors.ex_city
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-2">
                                              <div className="form-group">
                                                <label>Country</label>
                                                <CountryDropdown
                                                  placeholder="Country"
                                                  name="ex_country"
                                                  value={
                                                    experiences_formData.ex_country ||
                                                    ""
                                                  }
                                                  className="form-control"
                                                  onChange={this.handleExperienceChange.bind(
                                                    this,
                                                    "ex_country"
                                                  )}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    experiences_errors.ex_country
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-2">
                                              <div className="form-group">
                                                <label>State</label>

                                                <RegionDropdown
                                                  name="ex_state"
                                                  defaultOptionLabel="Select State"
                                                  country={
                                                    experiences_formData.ex_country
                                                  }
                                                  value={
                                                    experiences_formData.ex_state ||
                                                    ""
                                                  }
                                                  className="form-control"
                                                  onChange={this.handleExperienceChange.bind(
                                                    this,
                                                    "ex_state"
                                                  )}
                                                  placeholder="State"
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    experiences_errors.ex_state
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-2">
                                            <div className="form-group">
                            <InputField
                              inputType={"text"}
                              title={"Pincode"}
                              name={"ex_zipcode"}
                              controlFunc={this.handleExperienceInputChange}
                              content={experiences_formData.ex_zipcode}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={experiences_errors.ex_zipcode}
                            />
                          </div>
                          </div>
                                            <div className="col-sm-3 datepickerdiv">
                                              <label>From</label>

                                              <DatePicker
                                                className="form-control"
                                                value={
                                                  experiences_formData.experience_from
                                                }
                                                format="dd-MM-yyyy"
                                                onChange={this.handleExperienceChange.bind(
                                                  this,
                                                  "experience_from"
                                                )}
                                                yearPlaceholder='YYYY'
                                                monthPlaceholder='MM'
                                                dayPlaceholder='DD'
                                              />
                                              <SpanField
                                                classname="text-danger"
                                                title={
                                                  experiences_errors.experience_from
                                                }
                                              />
                                            </div>
                                            <div className="col-sm-3 datepickerdiv">
                                              <label>To</label>

                                              <DatePicker
                                                className="form-control"
                                                value={
                                                  experiences_formData.experience_to
                                                }
                                                format="dd-MM-yyyy"
                                                onChange={this.handleExperienceChange.bind(
                                                  this,

                                                  "experience_to"
                                                )}
                                                yearPlaceholder='YYYY'
                                                monthPlaceholder='MM'
                                                dayPlaceholder='DD'
                                              />
                                              <SpanField
                                                classname="text-danger"
                                                title={
                                                  experiences_errors.experience_to
                                                }
                                              />
                                            </div>
                                            <div className="col-sm-3">
                                              <label></label>
                                              <div className="row">
                                                <button
                                                  type="button"
                                                  onClick={this.addExperienceDetail.bind(
                                                    this
                                                  )}
                                                  className="plusbtn-lft plusbtn-position"
                                                >
                                                  <i
                                                    className={
                                                      "edit" ===
                                                        this.state.exAction
                                                        ? "fa fa-edit"
                                                        : "fa fa-plus"
                                                    }
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              {/*   <button
                                                  type="button"
                                                  onClick={this.cancelAddEdit.bind(
                                                    this,
                                                    "experience"
                                                  )}
                                                  className="plusbtn-lft plusbtn-position"
                                                >
                                                  <i
                                                    className="fa fa fa-ban"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button> */}
                                              </div>
                                            </div>
                                          </Fragment>
                                        </div>
                                      </div>
                                    </div>

                                    {/**  Certifications & Memberships */}

                                    <div className="">
                                      <div className="formlayout ml-0 row">
                                        <div className="col-sm-12">
                                          <h5 className=" mt-0 mb-15">
                                            <b>Certifications/Memberships</b>
                                           {/*  <button
                                              style={{
                                                display: this.state.isDisable
                                                  ? "none"
                                                  : "",
                                              }}
                                              type="button"
                                              className="btn btn-blue btn-sm btn-space"
                                              onClick={() => {
                                                this.setState({
                                                  certEdit: true,
                                                });
                                              }}
                                            >
                                              <i className="fa fa-plus"></i>
                                            </button> */}
                                          </h5>
                                        </div>
                                        {this.state.businessUserData.certification ? (
                                          <div
                                          className={
                                            this.state.businessUserData.certification && this.state.businessUserData
                                              .certification.length > 0
                                              ? "show-reactData-table w-100"
                                              : "hide-reactData-table col-sm-12"
                                          }
                                        >
                                          <ReactDatatable
                                            id="cert_datatable"
                                            className="table table-bordered table-striped kas  mobile_table"
                                            config={this.config}
                                            records={
                                              this.state.businessUserData
                                                .certification
                                            }
                                            columns={this.certificateColumns}
                                          />
                                        </div>
                                        ) : ""}
                                        
                                        <div
                                          className="row"
                                         /*  style={{
                                            display: this.state.certEdit
                                              ? "flex"
                                              : "none",
                                          }} */
                                        >
                                          <Fragment>
                                            <div className="col-sm-3">
                                              <div className="form-group">
                                                <InputField
                                                  inputType={"text"}
                                                  title={"Name"}
                                                  name={"specialization"}
                                                  controlFunc={this.handleCertificateInputChange}
                                                  content={
                                                    certifications_formData.specialization
                                                  }
                                                  placeholder={""}
                                                  max={50}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    certificate_errors.specialization
                                                  }
                                                />
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="form-group">
                                                <InputField
                                                  inputType={"text"}
                                                  title={"Organization"}
                                                  name={"organization"}
                                                  controlFunc={this.handleCertificateInputChange}
                                                  content={
                                                    certifications_formData.organization
                                                  }
                                                  placeholder={""}
                                                  max={100}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    certificate_errors.organization
                                                  }
                                                />
                                                
                                              </div>
                                            </div>

                                            <div className="col-sm-6">
                                              <div className="form-group">
                                                <InputField
                                                  inputType={"text"}
                                                  title={"Valid URL"}
                                                  name={"valid_URL"}
                                                  controlFunc={this.handleCertificateInputChange}
                                                  content={
                                                    certifications_formData.valid_URL
                                                  }
                                                  placeholder={""}
                                                  max={50}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    certificate_errors.valid_URL
                                                  }
                                                />
                                                
                                              
                                              </div>
                                            </div>

                                            <div className="col-sm-12">
                                              <div className="row">
                                                <div className="col-sm-4">
                                                  <label>Completion Date</label>
                                                  <div className="row">
                                                    <div className="col-sm-6">
                                                      <MonthPick
                                                        defaultValue="Select Month"
                                                        selectedOption={
                                                          certifications_formData.certification_completion_month
                                                        }
                                                        controlFunc={this.handleCertificationChange.bind(
                                                          this,

                                                          "certification_completion_month"
                                                        )}
                                                        id="certification_completion_month"
                                                       
                                                        name="certification_completion_month"
                                                      />
                                                      <SpanField
                                                        classname="text-danger"
                                                        title={
                                                          certificate_errors.certification_completion_month
                                                        }
                                                      />
                                                     
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <YearPick
                                                        defaultValue="Select Year"
                                                        start={currentYear}
                                                        selectedOption={
                                                          certifications_formData.certification_completion_year
                                                        }
                                                        controlFunc={this.handleCertificationChange.bind(
                                                          this,

                                                          "certification_completion_year"
                                                        )}
                                                        id="certification_completion_year"
                                                        name="certification_completion_year"
                                                      />
                                                      <SpanField
                                                        classname="text-danger"
                                                        title={
                                                          certificate_errors.certification_completion_year
                                                        }
                                                      />
                                                     
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4">
                                                  <label>Valid till</label>
                                                  <div className="row">
                                                    <div className="col-sm-6">
                                                      <MonthPick
                                                        defaultValue="Select Month"
                                                        selectedOption={
                                                          certifications_formData.valid_upto_month
                                                        }
                                                        controlFunc={this.handleCertificationChange.bind(
                                                          this,

                                                          "valid_upto_month"
                                                        )}
                                                        id="valid_upto_month"
                                                        name="valid_upto_month"
                                                      />
                                                      <SpanField
                                                        classname="text-danger"
                                                        title={
                                                          certificate_errors.valid_upto_month
                                                        }
                                                      />
                                                     
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <YearPick
                                                        defaultValue="Select Year"
                                                        start={currentYear}
                                                        selectedOption={
                                                          certifications_formData.valid_upto_year
                                                        }
                                                        controlFunc={this.handleCertificationChange.bind(
                                                          this,

                                                          "valid_upto_year"
                                                        )}
                                                        id="valid_upto_year"
                                                        name="valid_upto_year"
                                                      />
                                                      <SpanField
                                                        classname="text-danger"
                                                        title={
                                                          certificate_errors.valid_upto_year
                                                        }
                                                      />
                                                     
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4">
                                                  <label></label>
                                                  <div className="row">
                                                    <button
                                                      type="button"
                                                      onClick={this.addCertificationDetail.bind(
                                                        this
                                                      )}
                                                      className="plusbtn-lft plusbtn-position"
                                                    >
                                                      <i
                                                        className={
                                                          "edit" ===
                                                            this.state.certAction
                                                            ? "fa fa-edit"
                                                            : "fa fa-plus"
                                                        }
                                                        aria-hidden="true"
                                                      ></i>
                                                    </button>
                                                   {/*  <button
                                                      type="button"
                                                      onClick={this.cancelAddEdit.bind(
                                                        this,
                                                        "certificate"
                                                      )}
                                                      className="plusbtn-lft plusbtn-position"
                                                    >
                                                      <i
                                                        className="fa fa fa-ban"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </button> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Fragment>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="">
                                      <div className="formlayout ml-0  pb-0 row">
                                        <div className="col-sm-12">
                                          <h5 className=" mt-0 mb-15">
                                            <b>Educational Qualifications*</b>
                                           {/*  <button
                                              style={{
                                                display: this.state.isDisable
                                                  ? "none"
                                                  : "",
                                              }}
                                              type="button"
                                              className="btn btn-blue btn-sm btn-space"
                                              onClick={() => {
                                                this.setState({
                                                  edEdit: true,
                                                });
                                              }}
                                            >
                                              <i className="fa fa-plus"></i>
                                            </button> */}
                                          </h5>
                                        </div>
{this.state.businessUserData.educational_qualification && this.state.businessUserData.educational_qualification.length ? ( <div
                                                className={
                                                  this.state.businessUserData
                                                    .educational_qualification && this.state.businessUserData
                                                    .educational_qualification.length >
                                                    0
                                                    ? "show-reactData-table w-100"
                                                    : "hide-reactData-table col-sm-12"
                                                }
                                              >
                                                <ReactDatatable
                                                  id="ed_datatable"
                                                  config={this.config}
                                                  records={
                                                    this.state.businessUserData
                                                      .educational_qualification
                                                  }
                                                  columns={this.education_columns}
                                                  className="table table-bordered table-striped kas  mobile_table"
                                                />
                                              </div>):""}
                                       
                                        <div
                                          className="row"
                                         /*  style={{
                                            display: this.state.edEdit
                                              ? "flex"
                                              : "none",
                                          }} */
                                        >
                                          <Fragment>
                                            <div className="col-sm-3">
                                              <div className="form-group">
                                                <label>
                                                  Education
                                                  <StarField />
                                                </label>
                                                <InputField
                              isMandatory={true}
                              inputType={"text"}
                              // title={"education"}
                              name={"education"}
                              controlFunc={this.handleEducationDetailChange.bind(
                                this,
                                "education"
                              )}
                              content={
                                educational_qualification_formData.education
                              }
                              placeholder={""}
                              max={50}
                            />
                                                {/* <Select maxMenuHeight={120}
                                                  className="form-control p-0"
                                                  name="education"
                                                  value={
                                                    educational_qualification_formData.education
                                                  }
                                                  onChange={this.handleEducationDetailChange.bind(
                                                    this,
                                                    "education"
                                                  )}
                                                  options={EDUCATION_OPTIONS}
                                                /> */}
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    educational_qualification_errors.education
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-3">
                                              <div className="form-group">
                                                <label>
                                                  Field of Study
                                                  <StarField />
                                                </label>
                                                <Select maxMenuHeight={120}
                                                  className="form-control p-0"
                                                  name="specialization"
                                                  value={
                                                    educational_qualification_formData.specialization
                                                  }
                                                  onChange={this.handleEducationDetailChange.bind(
                                                    this,

                                                    "specialization"
                                                  )}
                                                  options={specializations_array}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    educational_qualification_errors.specialization
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-6">
                                              <div className="form-group">
                                                <InputField
                                                  isMandatory={true}
                                                  inputType={"text"}
                                                  title={"University"}
                                                  name={"university"}
                                                  controlFunc={this.handleEducationDetailChange.bind(
                                                    this,

                                                    "university"
                                                  )}
                                                  content={
                                                    educational_qualification_formData.university
                                                  }
                                                  placeholder={""}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    educational_qualification_errors.university
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6">
                                              <div className="form-group mb-0">
                                                <label>
                                                  Completion Date
                                                  <StarField />
                                                </label>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-6">
                                                  <MonthPick
                                                    defaultValue="Select Month"
                                                    selectedOption={
                                                      educational_qualification_formData.completion_month
                                                    }
                                                    controlFunc={this.handleEducationDetailChange.bind(
                                                      this,
                                                      "completion_month"
                                                    )}
                                                    id="completion_month"
                                                    name="completion_month"
                                                  />
                                                </div>
                                                <div className="col-sm-6">
                                                  <YearPick
                                                    defaultValue="Select Year"
                                                    start={currentYear}
                                                    selectedOption={
                                                      educational_qualification_formData.completion_year
                                                    }
                                                    controlFunc={this.handleEducationDetailChange.bind(
                                                      this,

                                                      "completion_year"
                                                    )}
                                                    id="completion_year"
                                                    name="completion_year"
                                                  />
                                                </div>

                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    educational_qualification_errors.completion_year_month
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-6">
                                              <div className="form-group mb-0">
                                                <label></label>
                                              </div>
                                              <div className="row">
                                                <button
                                                  type="button"
                                                  onClick={this.addEducationDetail.bind(
                                                    this
                                                  )}
                                                  className="plusbtn-lft plusbtn-position"
                                                >
                                                  <i
                                                    className={
                                                      "edit" ===
                                                        this.state.edAction
                                                        ? "fa fa-edit"
                                                        : "fa fa-plus"
                                                    }
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                             {/*    <button
                                                  type="button"
                                                  onClick={this.cancelAddEdit.bind(
                                                    this,
                                                    "education"
                                                  )}
                                                  className="plusbtn-lft plusbtn-position"
                                                >
                                                  <i
                                                    className="fa fa fa-ban"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button> */}
                                              </div>
                                            </div>
                                          </Fragment>
                                        </div>
                                        <div
                                          className={
                                            this.state.businessUserData
                                              .educational_qualification && this.state.businessUserData
                                              .educational_qualification.length >
                                              0
                                              ? "show-reactData-table"
                                              : "hide-reactData-table"
                                          }
                                        ></div>
                                        <Popup
                                          show={this.state.addPopup}
                                          onHide={PopupClose}
                                          onConfirm={PopupConfirm}
                                          title={"Confirmation"}
                                          message={
                                            "Are you sure you want to delete this record ?"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-sm-5"
                          style={{
                            display: this.state.expandmemDisabled
                              ? "none"
                              : "block",
                          }}
                        >
                              <div className="card">
                          <div className="card-header">
                             <div className="card-title">
                               Payment Details
                               </div> 
                          </div>
                          <div className="card-body">
                          {payment_details.length ? payment_details.map((el, i) => (
                                            <Fragment key={i}>
                                              
                                              {/* <div className="frllftfull  mt-10">
                                                {i === 0 ? (
                                                  <h5 className="">
                                                    
                                                    
                                                  </h5>
                                                ) : (
                                                  ""
                                                )}
                                                <hr className="mrgtp" />
                                               
                                              </div> */}
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Card Number</label>
                                                </div>
                                                <div className="form-group  frlrit">
                                                  <InputField
                                                    isMandatory={true}
                                                    inputType={"text"}
                                                    value={this.state.value}
                                                    // title={'Last Name'}
                                                    name={"credit_card"}
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "credit_card"
                                                    )}
                                                    content={
                                                      payment_details[i]
                                                        ? payment_details[i]
                                                          .credit_card
                                                        : ""
                                                    }
                                                    placeholder={""}
                                                    isvisible={this.state.isDisable ? "true" :  undefined}
                                                    max={16}
      
                                                  />
                                                  <SpanField
                                                    classname="text-danger"
                                                    title={payment_details_error[i].credit_card}
                                                  />
                                                </div>
                                              </div>
      
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Name in Card</label>
                                                </div>
                                                <div className="form-group frlrit">
                                                  <InputField
                                                    isMandatory={true}
                                                    inputType={"text"}
                                                    // title={'Last Name'}
                                                    name={"card_name"}
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "card_name"
                                                    )}
                                                    content={
                                                      payment_details[i]
                                                        ? payment_details[i].card_name
                                                        : ""
                                                    }
                                                    placeholder={""}
                                                    isvisible={this.state.isDisable ? "true" :  undefined}
                                                    max={50}
      
                                                  />
                                                  <SpanField
                                                    classname="text-danger"
                                                    title={payment_details_error[i].card_name}
                                                  />
                                                </div>
                                                
                                              </div>
      
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Expiration Date</label>
                                                </div>
                                                <div className="form-group frlrclfgf-1">
                                                  <MonthPick
                                                    defaultValue="MM"
                                                    year={
                                                      payment_details[i] ? payment_details[i].expiry_month : ""
                                                    }
                                                    selectedOption={
                                                      payment_details[i]
                                                        ? payment_details[i]
                                                          .expiry_month
                                                        : ""
                                                    }
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
      
                                                      "expiry_month"
                                                    )}
                                                    id="expiry_month"
                                                    name="expiry_month"
                                                  />
                                                </div>
      
                                                {/* <div className="form-group frlrclfgf-1">
                                                  <label></label>
                                                </div> */}
                                                <div className="form-group frlrclfgf-1">
                                                  <YearPick
                                                    defaultValue="YY"
                                                    startyear={currentYear}
                                                    endyear={currentYear + 20}
                                                    selectedOption={payment_details[i]? payment_details[i].expiry_year : "" }
                                                    controlFunc={this.handlePaymentDetailChange.bind(this,i,"expiry_year")}
                                                    id="expiry_year"
                                                    name="expiry_year"
                                                  />
                                                </div>
                                                <div className="form-group frllft ">
                                                  <label className="ml-2">CVV</label>
                                                  <div className="form-group frlrit frlrclfgf w-50 float-right">
                                                  <InputField
                                                    isMandatory={true}
                                                    inputType={"text"}
                                                    // title={'Last Name'}
                                                    name={"cvv"}
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "cvv"
                                                    )}
                                                    content={
                                                      payment_details[i]
                                                        ? payment_details[i].cvv
                                                        : ""
                                                    }
                                                    placeholder={""}
                                                    isvisible={this.state.isDisable ? "true" :  undefined}
                                                    max={3}
      
                                                  />
      
                                                  <SpanField
                                                    classname="text-danger"
                                                    title={payment_details_error[i].cvv}
                                                  />
                                                </div>
                                                </div>
                                                {/* <div className="form-group frlrit frlrclfgf-1 float-right">
                                                  <InputField
                                                    isMandatory={true}
                                                    inputType={"text"}
                                                    // title={'Last Name'}
                                                    name={"cvv"}
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "cvv"
                                                    )}
                                                    content={
                                                      payment_details[i]
                                                        ? payment_details[i].cvv
                                                        : ""
                                                    }
                                                    placeholder={""}
                                                    isvisible={this.state.isDisable ? "true" :  undefined}
                                                    max={3}
      
                                                  />
      
                                                  <SpanField
                                                    classname="text-danger"
                                                    title={payment_details_error[i].cvv}
                                                  />
                                                </div> */}
                                              </div>
      
                                              <div className="frllftfull">
                                        
                                                <div className="form-group frllft">
                                                  <label>Card Type</label>
                                                </div>
                                                <div className="form-group sform-control frlrit">
                                                  <Select 
                                                  
                                                  //maxMenuHeight={120}
                                                    className="form-control p-0"
                                                    name="card_type"
                                                    value={
                                                      payment_details[i]
                                                        ? payment_details[i].card_type
                                                        : ""
                                                    }
                                                    onChange={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "card_type"
                                                    )}
                                                    options={card_type}
                                                  />
                                                </div>
                                              </div>
      
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>
                                                    Netbanking/ Paytm / Paypal
                                                  </label>
                                                </div>
                                                <div className="form-group frlrit">
                                                  <InputField
                                                    isMandatory={true}
                                                    inputType={"text"}
                                                    // title={'Last Name'}
                                                    name={"payment_option"}
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "payment_option"
                                                    )}
                                                    content={
                                                      payment_details[i]
      
                                                        ? payment_details[i]
                                                          .payment_option.trim()
                                                        : ""
                                                    }
                                                    placeholder={""}
                                                    isvisible={this.state.isDisable ? "true" :  undefined}
                                                    max={50}
                                                  />
                                                  <SpanField
                                                    classname="text-danger"
                                                    title={payment_details_error[i].payment_option}
                                                  />
                                                </div>
                                              </div>
                                            </Fragment>
                                          )):""}
                          </div>
                          </div>
                          <div className="card">
                          <div className="card-header"> <div className="card-title">Reviews</div> </div>
                            <div className="card-body">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="row reviewsdesign">
                                   
                                    <div className="col-sm-12">
                                      <div>
                                        <button>Friendliness</button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.friendliness
                                              ? reviewinfo.friendliness
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="3px"
                                          numberOfStars={5}
                                          name="rating"
                                        />
                                      </div>
                                      <div>
                                        <button>Value for Money</button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.value_of_money
                                              ? reviewinfo.value_of_money
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="3px"
                                          numberOfStars={5}
                                          name="rating"
                                        />
                                      </div>
                                      <div>
                                        <button>Less Wait Time</button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.less_wait_time
                                              ? reviewinfo.less_wait_time
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="3px"
                                          numberOfStars={5}
                                          name="rating"
                                        />
                                      </div>
                                      <div>
                                        {" "}
                                        <button>Best Treatment </button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.best_treatment
                                              ? reviewinfo.best_treatment
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="3px"
                                          numberOfStars={5}
                                          name="rating"
                                        />
                                      </div>
                                      <div>
                                        <button>Easy Explanation</button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.easy_explanation
                                              ? reviewinfo.easy_explanation
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="3px"
                                          numberOfStars={5}
                                          name="rating"
                                        />
                                      </div>
                                      <div>
                                        <button>Good Facility </button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.good_facility
                                              ? reviewinfo.good_facility
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="3px"
                                          numberOfStars={5}
                                          name="rating"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card">
                          <div className="card-header">
                             <div className="card-title">
                             Comments
                               </div> 
                          </div>
                          <div className="card-body">
                          {commentsArr
                                      ? commentsArr.map((element, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="col-sm-12 cmdpad"
                                          >
                                            <table className="">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <img
                                                      src={
                                                        element.avatar
                                                          ? element.avatar
                                                          : "http://klinicq.com/static/media/iconpatent.5ca3dcad.png"
                                                      }
                                                      className="riwwimg"
                                                    />{" "}
                                                  </td>
                                                  <td>
                                                    <h5 className="wwim">
                                                      {element.commenter
                                                        ? element.commenter
                                                        : ""}{" "}
                                                      <span>
                                                        {" "}
                                                        {element.reviewed_on
                                                          ? element.reviewed_on
                                                          : ""}
                                                      </span>
                                                    </h5>
                                                    <p
                                                      style={{
                                                        marginBottom: "3px",
                                                      }}
                                                    >
                                                      <StarRatings
                                                        rating={
                                                          element.overall_rating
                                                            ? element.overall_rating
                                                            : 0
                                                        }
                                                        starRatedColor="#ebb338"
                                                        starDimension="15px"
                                                        starSpacing="3px"
                                                        numberOfStars={5}
                                                        name="rating"
                                                      />
                                                    </p>
                                                    <p>
                                                      {element.comment_text
                                                        ? element.comment_text
                                                        : ""}
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        );
                                      })
                                      : ""}
                          </div>
                          </div>
                      
                        </div>
                      </div>
                  {/* <Footerad /> */}
                  
                </div>
              </div>
             
            </section>
            <FooterSection />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(BusinessUserProfile));
