import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../components/container/layout/client/Header";
import ClientSideBar from "../components/container/layout/client/SideBar";
import Select from "react-select";
import ToggleButton from "../components/UI/ToggleButton";
import InputField from "../components/UI/InputField";
import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";
import Label from "../components/UI/Label";
import DatePicker from "react-date-picker";
import * as moment from "moment";
import { stringToLabelValue } from "../constants/globalLevelFunctions";
import FooterSection from "../components/UI/FooterSection";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal'
import {
  nationalityArray,
  gender,
  language,
  relationship,
  maritial_status,
  card_type,
} from "../config/data";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
import { getClientById } from "../config/url.json";
import { updateClient, getTimeoutbycategory,DeleteAccount } from "../config/url.json";
import { CONFIG } from "../config/data";
import MonthPick from "../components/UI/MonthPick";
import YearPick from "../components/UI/YearPick";
import { Spinner } from "../components/UI/Spinner";
import { withRouter } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Popup } from "../components/UI/Popup";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}


const google = window.google;

let nationality = [];
nationalityArray.forEach((element, index) => {
  nationality.push({ value: element, label: element });
});
const currentYear = new Date().getFullYear();

class ClientProfile extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const timeout = this.state;

    this.state = {
      timeout: timeout,
      isTimedOut: false,
      showModal: false,
      deletePopup: false,
      loggedInuserId: props.userdata.id,
      access_token: props.access_token,
      isDisable: false,
      editEnabled: true,
      editDisabled: false,
      formSuccess: { status: "", message: "" },
      headerInfo: {
        avatar: "",
        full_name: "",
        registeration_id: "",
        blood_group: "",
      },
      clientData: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        blood_group: "",
        gender: "",
        maritial_status: "",
        nationality: "",
        language: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        user_type: "patient",
        phone_number: "",
        google_address: false,
        emergency_contact: {
          first_name: "",
          last_name: "",
          relationship: "",
          phone_number: "",
          phone_code:"+91",
        },
        employment_details: {
          employer_name: "",
          address: "",
          city: "",
          country: "",
          state: "",
          zip_code: "",
          phone_number: "",
          employee_status: "",
         
        },
        vehicle_details: 
          {
            vehicle_make: "",
            vehicle_model: "",
            vehicle_registration: "",
            year_of_make: "",
            vin_number: "",
          },
        
        payment_details: [
          {
            credit_card: "",
            card_name: "",
            card_type: "",
            expiry_month: "",
            expiry_year: "",
            cvv: "",
            payment_option: "",
          },
        ],
       
        insurance_details: {
          insurance_provider: "",
          policy_id_number: "",
          health_insurance_confirmation: "",
          patient_policy_holder: "",
        },
        medical_history: [],
        family_medical_history: [],
      },

      vehicle_details: [
        {
          vehicle_make: "",
          vehicle_model: "",
          vehicle_registration: "",
          year_of_make: "",
          vin_number: "",
        },
      ],

      payment_details: [
        {
          credit_card: "",
          card_name: "",
          card_type: "",
          expiry_month: "",
          expiry_year: "",
          cvv: "",
          payment_option: "",
        },
      ],
      payment_details_error: [{
        credit_card: "",
        card_name: "",
        card_type: "",
        expiry_month: "",
        expiry_year: "",
        cvv: "",
        payment_option: "",
      },],
      family_details: [
        {
          first_name: "",
          last_name: "",
          date_of_birth: "",
          gender: "",
          relationship: "",
          blood_group: "",
          phone_number: "",
        },
      ],
      errors: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        blood_group: "",
        gender: "",
        maritial_status: "",
        nationality: "",
        language: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        insurance_provider: "",
        policy_id_number: "",
        employer_name: "",
        address: "",
        credit_card: "",
        card_name: "",
        cvv: "",
        insurance_details: {
          insurance_provider: "",
          policy_id_number: "",
          health_insurance_confirmation: "",
          patient_policy_holder: "",
        },
        emergency_contact: {
          first_name: "",
          last_name: "",
          relationship: "",
          phone_number: "",
          phone_code:"+91",
        },
        employment_details: {
          employer_name: "",
          address: "",
          city: "",
          country: "",
          state: "",
          zip_code: "",
          phone_number: "",
          employee_status: "",
        },

      },
     
      family_details_errors: [
        {
          first_name: "",
          last_name: "",
          date_of_birth: "",
          gender: "",
          relationship: "",
          blood_group: "",
          phone_number: "",
        },
      ],
    };

    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
      this.autocomplete = null;
    //this.handleWorkhoursChange = this.handleWorkhoursChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
     this.handleEmployeePlaceSelect = this.handleEmployeePlaceSelect.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

  }
  handleClose() {
    this.setState({ showModal: false })
  }


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  };

  _onAction(e) {
    console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    console.log('user is idle', e)
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
  if(isTimedOut===true)
  {
    localStorage.removeItem("token");
    this.props.logout();
    
  }
  }



  componentDidMount() {

    this.autocomplete_address1 = new google.maps.places.Autocomplete(document.getElementById("address1"),{});
    this.autocomplete_address1.addListener("place_changed", this.handlePlaceSelect);

    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'), {});
    this.autocomplete.addListener("place_changed", this.handleEmployeePlaceSelect);

    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            timeout: data.data.timeout

          });
      })
      .catch((e) => { });



    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.state.access_token}`;
    axios
      .post(getClientById, { client_id: this.state.loggedInuserId }, CONFIG)
      .then((result) => {
        if (result.data.status === "1") {
          let data = result.data.data;
          let family_details = result.data.data.family_details;

          this.setState({ family_details: family_details });
          let ExistingvehicleDetails = this.state.vehicle_details;
          let ExistingPaymentDetails = this.state.payment_details;
          console.log(this.state.payment_details);
          if (data.payment_details == null||data.payment_details =="") {
            data.payment_details = {
              credit_card: "",
              card_name: "",
              expiry_month: "",
              expiry_year: "",
              cvv: "",
              payment_option: "",
            };
          } else {
             data.payment_details = data.payment_details;
          }
          if (data.vehicle_details == null) {
            data.vehicle_details = {
              vehicle_make: "",
              vehicle_model: "",
              vehicle_registration: "",
              year_of_make: "",
              vin_number: "",
            };
          } else {
            data.vehicle_details = JSON.parse(data.vehicle_details);
          }
          if (data.emergency_contact == null) {
            data.emergency_contact = {
              first_name: "",
              last_name: "",
              relationship: "",
              phone_number: "",
              phone_code:"+91",
            };
          }
          if (data.employment_details == null||data.employment_details == "") {
            data.employment_details = {
              employer_name: "",
              address: "",
              city: "",
              country: "",
              state: "",
              zip_code: "",
              phone_number: "",
              employee_status: "",
            };
          }
          
          if (data.insurance_details == null||data.insurance_details =="") {
            data.insurance_details = {
              insurance_provider: "",
              policy_id_number: "",
              health_insurance_confirmation: "",
              patient_policy_holder: "",
            };
          }
          const yourDate = data.date_of_birth;

          data.date_of_birth = data.date_of_birth ? moment(yourDate, moment.defaultFormat).toDate() : "";
          //  console.log(data.date_of_birth);
          this.handleDOBChange(data.date_of_birth);

          if (data.family_medical_history == null) {
            data.family_medical_history = [];
          }
          if (data.medical_history == null) {
            data.medical_history = [];
          }
          let convertedblood = [];
          if (data.blood_group != null) {
            convertedblood = {
              label: data.blood_group,
              value: data.blood_group,
            };
          }

          let convertedgender = [];
          if (data.gender != null) {
            convertedgender = {
              label: data.gender,
              value: data.gender,
            };
          }
          let convertedmaritial_status = [];
          if (data.marital_status != null) {
            convertedmaritial_status = {
              label: data.marital_status,
              value: data.marital_status,
            };
          }
          data.language = stringToLabelValue(data.language);

          let convertednationality = [];
          if (data.nationality != null) {
            convertednationality = {
              label: data.nationality,
              value: data.nationality,
            };
          }
          data.nationality = convertednationality;
          data.gender = convertedgender;
          data.blood_group = convertedblood;
          data.maritial_status = convertedmaritial_status;
          data.phone_number = data.phone_number.includes("+91") ? data.phone_number : data.phone_code.concat(data.phone_number);
          ExistingvehicleDetails.splice(0, 1, data.vehicle_details);

          ExistingPaymentDetails.splice(0, 1, data.payment_details);

          this.setState({
            vehicle_details: ExistingvehicleDetails,
            payment_details: ExistingPaymentDetails,
          });
          this.setState({
            clientData: data,

            // family_details: JSON.parse(response.data.data.family_details),
          });
        }
      })
      .catch((e) => {
        console.log("business user getbyid failed");
      });
    console.log("mountdone");
    console.log(this.state.vehicle_details);
  }

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          addDisResponseMessage: { message: "" },
          formSuccess: { message: "" },
          searchformSuccess: { message: "" },
        }),
      5000
    );
  };

  addVehicleDetail() {
    this.setState((prevState) => ({
      vehicle_details: [
        ...prevState.vehicle_details,
        {
          vehicle_make: "",
          vehicle_model: "",
          vehicle_registration: "",
          year_of_make: "",
          vin_number: "",
        },
      ],
    }));
  }

  removeVehicleDetail(i) {
    let vehicle_details = [...this.state.vehicle_details];
    vehicle_details.splice(i, 1);
    this.setState({ vehicle_details });
  }

  handleVehicleDetailChange(i, value, field) {
    let fields = this.state.vehicle_details;

    if (value === "vehicle_make") {
      fields[i].vehicle_make = field.target.value;
    } else if (value === "vehicle_model") {
      fields[i].vehicle_model = field.target.value;
    } else if (value === "vehicle_registration") {
      fields[i].vehicle_registration = field.target.value;
    } else if (value === "year_of_make") {
      fields[i].year_of_make = field.target.value;
    } else if (value === "vin_number") {
      fields[i].vin_number = field.target.value;
    }
    this.setState({
      vehicle_details: fields,
    });
  }

  addPaymentDetail() {
    this.setState((prevState) => ({
      payment_details: [
        ...prevState.payment_details,
        {
          credit_card: "",
          card_name: "",
          card_type: "",
          expiry_month: "",
          expiry_year: "",
          cvv: "",
          payment_option: "",
        },
      ],
    }));
    this.setState((prevState) => ({
      payment_details_error: [
        ...prevState.payment_details_error,
        {
          credit_card: "",
          card_name: "",
          card_type: "",
          expiry_month: "",
          expiry_year: "",
          cvv: "",
          payment_option: "",
        },
      ],
    }));
  }

  removePaymentDetail(i) {
    let payment_details = [...this.state.payment_details];
    payment_details.splice(i, 1);
    this.setState({ payment_details });
  }

  handlePaymentDetailChange(i, value, field) {

    
    let fields = this.state.payment_details;

    let payment_details_error = this.state.payment_details_error;
    if (value === "credit_card" || value === "card_name" || value === "payment_option" || value === "cvv") {

      // const re = /^[0-9\b]+$/;
      // console.log(field.target.value);
      //   if (field.target.value === '' || re.test(field.target.value)) {
      //      this.setState({value: field.target.value})
      //   }  
      
      let max = field.target.max;
      let v = field.target.value;

      if (v.length > max) {
        const value1 = v.slice(0, max);
        payment_details_error[i][value] = "Maximum Length has exceeded.";
        fields[i][value] = value1;
      } else {
        payment_details_error[i][value] = "";
        if(value === "card_name")
          fields[i][value] = v.replace(/[^a-zA-Z]/ig,'');
          else if(value === "credit_card")
          fields[i][value] = v.replace(/^(?:5[1-5][0-9]{14})$/ig,'');
          else if(value === "cvv")
          fields[i][value] = v.replace(/[^0-9]/ig,'');
        else 
        fields[i][value] = v;

      }

      console.log(payment_details_error);
      console.log(fields);
    } else if (value === "card_type" || value === "expiry_month" || value === "expiry_year") {
      fields[i][value] = field;
    }
    this.setState({
      payment_details_error: payment_details_error,
      payment_details: fields
    });
  }

  handlePlaceSelect() {
    let place = this.autocomplete_address1.getPlace();
    console.log(place);

    let { clientData,errors } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];

        if (addressType === "administrative_area_level_1")
        {
          clientData.state = val;
          errors.state = ""
        }
        if (addressType === "locality")
          clientData.city = val;
          errors.city="";
        if (addressType === "country") clientData.country = val;
        errors.country="";
        clientData.address1 = place.name;
        if (addressType === "sublocality_level_1") clientData.address2 = val;
        if (addressType === "postal_code") clientData.zip_code = val;
        errors.zip_code="";
      }
    }

    this.setState({
      clientData,
      google_address: true,
    });
  }

  handleEmployeePlaceSelect() {
    let newplace = this.autocomplete.getPlace();
console.log(newplace);
    let { clientData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };

    for (var i = 0; i < newplace.address_components.length; i++) {
      var addressType = newplace.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = newplace.address_components[i][componentForm[addressType]];

        if (addressType === "administrative_area_level_1")
          clientData.employment_details.state = val;
        if (addressType === "locality")
          clientData.employment_details.city = val;
        if (addressType === "country")
          clientData.employment_details.country = val;

        clientData.employment_details.address = newplace.name;
        // if (addressType === "sublocality_level_1")
        //   clientData.employment_details.address2 = val;
        if (addressType === "postal_code")
          clientData.employment_details.zip_code = val;
      }
    }

    this.setState({
      clientData,
      google_address: true,
    });
  }

  validateMaxLength(name, value, max) {
    let data = this.state.clientData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ clientData: data, errors: errors });
  }
  handleInput = (e) => {
    let data = this.state.clientData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "first_name" ||
      name === "last_name" ||
      name === "email" ||
      name === "city" ||
      name === "address1" ||
      name === "zip_code" ||
      name === "phone_number"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ clientData: data });
  };

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.clientData;
    let errors=this.state.errors;
    data[e.name] = selectedOptions;

    this.setState({ clientData: data });
  };
  handlenationalityselectChange = (selectedOptions, e) => {
    let data = this.state.clientData;
    let errors=this.state.errors;
    data[e.name] = selectedOptions;
     errors.nationality="";
    this.setState({ clientData: data });
  };
  handlegenderselectChange = (selectedOptions, e) => {
    let data = this.state.clientData;
    let errors=this.state.errors;
    data[e.name] = selectedOptions;
    errors.gender= "";
    this.setState({ clientData: data });
  };
  handlemaritalstatusselectChange = (selectedOptions, e) => {
    let data = this.state.clientData;
    let errors=this.state.errors;
    data[e.name] = selectedOptions;
    errors.maritial_status= "";
    this.setState({ clientData: data });
  };

  /* for country detail change */
  selectCountry(val) {
    let data = this.state.clientData;
    data.country = val;
    this.setState({ clientData: data });
  }
  /* for state detail change */

  selectState(val) {
    let data = this.state.clientData;
    data.state = val;
    this.setState({ clientData: data });
  }

  handlePhoneInput = (event) => {
    let clientData = this.state.clientData;
    clientData.phone_number = event;
    this.setState({ clientData });
  };
  
  handleDOBChange = (date) => {
    let clientData = this.state.clientData;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    clientData.date_of_birth = offsetDate;
    this.setState({ clientData: clientData });
  };
  handleEmploymentDetails(field, value) {
    let data = this.state.clientData;
    let errors=this.state.errors;
    if (!errors.employment_details) {
      errors.employment_details = {}; 
    }
    if (
      field === "country" ||
      field === "state" ||
      field === "employee_status" 
    ){
      data.employment_details[field] = value;
    }
   // else data.employment_details[field] = value.target.value;
    else if (field === "phone_number") {
      const phoneNumber = value || "";
      const isValid = isValidPhoneNumber(phoneNumber);

     if ((phoneNumber !== "" && isValid==false) || phoneNumber.length > 14) {
        
          errors.employment_details[field] = "Please enter valid employee Phone number.";
         //const value1 = value.slice(0, 13);
 
         //data.emergency_contact[field] = value1;
 
       } else {
 
         errors.employment_details[field] = "";
         data.employment_details[field] = value;
       }
      }
      else
      {
        data.employment_details[field] = value.target.value;
      }
    this.setState({ clientData: data });
  }
  
  /* handleEmploymentDetails(field, value) {
    let data = this.state.clientData;

    let errors = this.state.errors;
    if (field === "country" || field === "state" || field === "employee_status") {

      data.employment_details[field] = value;
    } else if (field === "phone_number") {

      if (value !== "" &&
        !isValidPhoneNumber(data.employment_details.phone_number)) {

        // errors.employment_details.phone_number = "Please enter valid Phone number.";
        const value1 = value.slice(0, 13);

        data.employment_details.phone_number = value1;

      } else {

        errors.employment_details.phone_number = "";
        data.employment_details.phone_number = value;
      }

    }
    else {
      let max = value.target.max;
      value = value.target.value;

      if (value.length > max) {
        const value1 = value.slice(0, max);
        errors.employment_details[field] = "Exceeds maximum length";
        data.employment_details[field] = value1;
      } else {


        errors.employment_details[field] = "";
        data.employment_details[field] = value.replace(/[^a-zA-Z]/ig,'');

      }

      // data.employment_details[field] = value.target.value;
    }

    this.setState({ clientData: data, errors: errors });

  };
 */

  //  validateMaxLength(name, value, max) {
  //   let data = this.state.clientData;
  //   let errors = this.state.errors;

  //   if (value.length > max) {
  //     const value1 = value.slice(0, max);
  //     errors[name] = "Exceeds maximum length";
  //     data[name] = value1;
  //   } else {
  //     errors[name] = "";
  //     data[name] = value;
  //   }
  //   this.setState({ clientData: data, errors: errors });
  // }

  handleInsuranceDetails(field, value) {
    let data = this.state.clientData;
    let errors = this.state.errors;
    console.log("handle inc change");
    console.log(field);
    if(field==="insurance_provider"||field==="policy_id_number")
    {

   //  let regex = new RegExp("^[a-zA-Z0-9]+$");
     if(!data.insurance_details[field].match(/^[A-Za-z0-9]+$/)){
       errors.insurance_details[field]="Enter a valid insurance detail.";
    }else
    {
     data.insurance_details[field]=value;
     errors.insurance_details[field]="";
                     
    }    
    
   }  
     if (field === "health_insurance_confirmation" ||
      field === "patient_policy_holder")
       {
      data.insurance_details[field] = value.target.checked === true ? "Yes" : "No";
       } 
     
    else {
      let max = value.target.max;
      value = value.target.value;
      if (value.length > max) {
        const value1 = value.slice(0, max);
        errors[field] = "Maximum Length has exceeded.";
        data.insurance_details[field] = value1;
      } else {
        errors[field] = "";
        data.insurance_details[field] = value;
      }
    }
     
           
    this.setState({ clientData: data });
  }

  handelVehicleDetails(field, value) {
    let data = this.state.clientData;
    data.vehicle_details[field] = value.target.value;
    this.setState({ clientData: data });
  }
  handelPaymentDetails(field, value) {
    let data = this.state.clientData;

    if (
      field === "card_type" ||
      field === "expiry_month" ||
      field === "expiry_year"
    )
      data.payment_details[field] = value;
    else data.payment_details[field] = value.target.value;

    this.setState({ clientData: data });

  }

 /*  handelEmergencyContact(field, value) {
    let data = this.state.clientData;
    let errors = this.state.errors;
    if (field === "relationship") {


      data.emergency_contact[field] = value;
    }
     else if (field === "phone_number")
      {
     console.log(value);

      if ((value !== "" && value !== undefined) && !isValidPhoneNumber(data.emergency_contact[field]) && value.length > 13) {
        console.log(value);
         errors.emergency_contact[field] = "Please enter a valid Phone number.";
        //const value1 = value.slice(0, 13);

        //data.emergency_contact[field] = value1;

      } else {

        data.emergency_contact[field] = value;
        errors.emergency_contact[field] = "";
       
      }

    }
    else {
      let max = value.target.max;
      value = value.target.value;
      if (value.length > max) {
        const value1 = value.slice(0, max);
        errors.emergency_contact[field] = "Maximum Length has exceeded.";
        data.emergency_contact[field] = value1;
      } else {
        errors.emergency_contact[field] = "";

        data.emergency_contact[field] = value.replace(/[^a-zA-Z]/ig,'');
      }

       data.emergency_contact[field] = value.target.value;
    };

    this.setState({ clientData: data });
  } */

  handelEmergencyContact(field, value) {
    const data = this.state.clientData;
    const errors = this.state.errors;
    if (!errors.emergency_contact) {
      errors.emergency_contact = {}; // Initialize errors.emergency_contact if it's not already initialized
    }

    if (field === "relationship") {
      data.emergency_contact[field] = value;
    } else if (field === "first_name"||field === "last_name") {
      data.emergency_contact[field] = value.target.value;
    }else if (field === "phone_number") {
      const phoneNumber = value || "";
      const isValid = isValidPhoneNumber(phoneNumber);
 
      if ((phoneNumber !== "" && isValid==false) || phoneNumber.length > 14) {
        errors.emergency_contact[field] = "Please enter a valid Phone number.";
        
      } else {
        data.emergency_contact[field] = phoneNumber;
        errors.emergency_contact[field] = "";

      }

      const max = value.target.max;
      const value = value.target.value;
  
      if (value.length > max) {
        const value1 = value.slice(0, max);
        errors.emergency_contact[field] = "Maximum Length has exceeded.";
        data.emergency_contact[field] = value1;
      } else {
        errors.emergency_contact[field] = "";
  
        data.emergency_contact[field] = value.replace(/[^a-zA-Z]/ig, "");
      }
  
      data.emergency_contact[field] = value.target.value;
    }
  
    this.setState({ clientData: data, errors: errors });
  }

  editProfile() {
    this.setState({ editEnabled: !this.state.editEnabled });
    this.setState({ editDisabled: !this.state.editDisabled });

    //enable the fields to edit
    this.setState({ isDisable: !this.state.isDisable });
  }

  cancelEdit() {
    this.setState({ editDisabled: !this.state.editDisabled });
    this.setState({ editEnabled: !this.state.editEnabled });
  }

  updateProfile = (e) => {
    e.preventDefault();
    let {  google_address } = this.state;
       
    let clientData = this.state.clientData;
    let errors = this.state.errors;
    clientData.user_id = this.state.loggedInuserId;
    clientData.user_type = "patient";

    errors = Validation(clientData, errors);
    // console.log(errors); return false;
    this.setState({ errors: errors });
    console.log(errors);
    /* if (!google_address && clientData.employment_details.address !== "" && clientData.address1 !=="") {
      errors.address = "Please choose address from autocomplete";
    } */
    if (errors.formIsValid) {

      let data = {};
      data.blood_group = clientData.blood_group.label;
      data.gender = clientData.gender.label;
      data.marital_status = clientData.maritial_status.label;

      let lang = [];

      if (clientData.language) {
        clientData.language.forEach((element) => lang.push(element.value));
      }
      data.language = lang.toString();

      data.nationality = clientData.nationality.label;
      data.first_name = clientData.first_name;
      data.last_name = clientData.last_name;
      data.address1 = clientData.address1;
      data.address2 = clientData.address2;
      data.city = clientData.city;
      data.country = clientData.country;
      data.state = clientData.state;
      data.zip_code = clientData.zip_code;
      data.date_of_birth = clientData.date_of_birth;
      //data.date_of_birth =moment(clientData.date_of_birth).format('DD-MM-YYYY').toString();


      // phone number convrsion to separate country code and number for db
      data.phone_number = parsePhoneNumber(clientData.phone_number).nationalNumber;
      data.phone_code = "+" + parsePhoneNumber(clientData.phone_number).countryCallingCode;

      data.email = clientData.email;
      data.user_id = this.state.loggedInuserId;
      data.emergency_contact = clientData.emergency_contact;
      data.vehicle_details = clientData.vehicle_details;
      data.employment_details = clientData.employment_details;
      data.insurance_details = clientData.insurance_details;
      data.medical_history = clientData.medical_history;
      data.family_medical_history = clientData.family_medical_history;
      data.payment_details = clientData.payment_details;
      data.family_details = clientData.family_details;


      console.log(data);


      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.state.access_token}`;
      this.setState({ loading: true }, () => {
        axios
          .put(updateClient, data, CONFIG)
          .then((result) => {
            console.log(result);
            this.setState({ loading: false });
         //   this.setState({ formSuccess: result.data });
         toast.success("We have saved your profile changes.",{
          className:"toast-success"
        });
            this.clearMessage();
          //  window.location.reload();
          })
          .catch((e) => {
            console.log("business user getbyid failed",e);
          });
      });
    }
  };

  DeleteAccount=(user_id,user_type)=> {
   
    // let user_id = this.props.userdata.id;
  //  let user_type=this.props.userdata.user_type;
     axios.defaults.headers.common[
       "Authorization"
     ] = `Bearer ${this.props.access_token}`;
     axios
       .post(DeleteAccount, { user_id: user_id ,user_type:user_type}, CONFIG)
       .then((res) => {
         let response = res.data;
         if (response.status === "1") {
          
           toast.error("User Account deleted. ", {
             className: "toast-error"
           });
          
         }
       })
       .catch((e) => { });
     this.setState({ deletePopup: false, record: null });
 
     this.setState({
       confirmedDelete: false,
     });
   
 
   };
  render() {
    let PopupdeleteConfirm = () =>
    this.DeleteAccount(this.props.userdata.id,this.props.userdata.user_type);
    const {
      clientData,
      vehicle_details,
      payment_details,
      errors,
      formSuccess,
      payment_details_error,
      timeout,
      loading
    } = this.state;
   // console.log(vehicle_details);
    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let disease_array = this.props.appdata
      ? this.props.appdata[0].diseases
      : [];
    let disease = [];
    disease_array.forEach((element, index) => {
      disease.push(element.disease_name);
    });
    disease_array = disease;

    let specialities = [];
    specialities_array.forEach((element, index) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;

    let facilities_array = this.props.appdata
      ? this.props.appdata[0].facilities
      : [];
    let facilities = [];
    facilities_array.forEach((element, index) => {
      facilities.push({ value: element.id, label: element.facility_name });
    });
    facilities_array = facilities;
    return (
      <div className="main-content bacfot">
        <div className="mainpage">
           <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} /> 
       {/*    <Spinner loading={this.state.loading} /> */}
       {loading && <Dnaspinner />}
          {/* <Header info={this.state.headerInfo} /> */}
          <Header user_id={this.state.loggedInuserId} />
          <div className="main-box">
             <div className="sidemenu pr0">
              <ClientSideBar />

            </div> 
            <section className="main-body hospital-profilegap">
            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />
            <Popup
                                      show={this.state.deletePopup}
                                      onHide={() =>
                                        this.setState({
                                          deletePopup: false,
                                        })
                                      }
                                      onConfirm={PopupdeleteConfirm}
                                      title={"Confirmation"}
                                      message={
                                        "Are you sure you want to delete your account?"
                                      }
                                    />

              <div className="container-fluid">
                <div className="checkout-tabs row">
                  <div className="col-sm-12">
                    <div className="row  clearfix align-items-center">

                      <div className="col-lg-6">

                        <h4 class="font-weight-bold mb-0">Profile Summary</h4><br/>
                      </div>
                      <table className="deleteaccountbtn1">
                              <tbody>
                                <tr>
                                        <td></td>
                                  
                                         <button
                                            type="submit"
                                            onClick={() =>
                                              this.setState({ deletePopup: true})
                                            }
                                            className="s3 float-right"
                                          >
                                            Delete Account
                                          </button>
                                        
                                        </tr>
                                        </tbody></table>
                      <div className="col-lg-6">
                     
                        <span className="float-left">
                          <button className="signupbtn  abtn"
                            style={{
                              display: this.state.editEnabled
                                ? "block"
                                : "none",
                            }}
                            onClick={this.updateProfile}
                          >
                            Save
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                    <SpanField
                                    classname={
                                      "0" === formSuccess.status
                                        ? "text-danger user-notification"
                                        : "text-success user-notification"
                                    }
                                    title={formSuccess.message}
                                  />
                      <div className="col-sm-6">


                        <div className="card">
                        <div class="card-header"> <div class="card-title">Primary Contact</div> </div>
                          <div className="card-body">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                                <div className="col-sm-12">
                               

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="First Name"
                                       /*  isMandatory={true} */
                                      />
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"first_name"}
                                        controlFunc={this.handleInput}
                                        content={clientData.first_name}
                                        placeholder={""}
                                        isvisible={true}
                                        max={50}
                                        tabIndex={0}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.first_name}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Last Name"
                                    /*     isMandatory={true} */
                                      />
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"last_name"}
                                        controlFunc={this.handleInput}
                                        content={clientData.last_name.trim()}
                                        placeholder={""}
                                        isvisible={true}
                                        max={50}
                                        tabIndex={1}
                                        />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.last_name}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label title="Age in Years" />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        isMandatory={true}
                                        inputType={"text"}
                                        name={"age"}
                                        max={3}
                                        controlFunc={this.handleInput}
                                        content={
                                          clientData.age ? clientData.age.trim() : ""
                                        }
                                        placeholder={""}
                                        isvisible={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.age}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <Label title="Sex" isMandatory={true} />
                                    </div>
                                    <div className="form-group sform-control frlrclfgf">
                                      <Select maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="gender"
                                        value={clientData.gender}
                                        onChange={this.handlegenderselectChange}
                                        options={gender}
                                        isvisible={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.gender}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Marital Status"
                                        isMandatory={false}
                                      />
                                    </div>
                                    <div className="form-group sform-control frlrclfgf">
                                      <Select maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="maritial_status"
                                        value={clientData.maritial_status}
                                        onChange={this.handlemaritalstatusselectChange}
                                        options={maritial_status}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.maritial_status}
                                      />
                                    </div>
                                    <div className="form-group  frlrclfgf-1">
                                      <Label
                                        title="Nationality"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group sform-control frlrclfgf">
                                      <Select maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="nationality"
                                        value={clientData.nationality}
                                        onChange={this.handlenationalityselectChange}
                                        options={nationality}
                                        isvisible={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.nationality}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Address"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        id="address1"
                                        inputType={"text"}
                                        name={"address1"}
                                        controlFunc={this.handleInput}
                                        content={clientData.address1.trim()}
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                        max={100}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.address1}
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label title="City" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        inputType={"text"}
                                        name={"city"}
                                        controlFunc={this.handleInput}
                                        content={clientData.city.trim()}
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                        max={20}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.city}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <Label title="State" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <RegionDropdown
                                        name="state"
                                        defaultOptionLabel="Select State"
                                        country={clientData.country}
                                        value={clientData.state || ""}
                                        className="form-control"
                                        onChange={(val) =>
                                          this.selectState(val)
                                        }
                                        placeholder="State"
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.state}
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Country"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <CountryDropdown
                                        placeholder="Country"
                                        name="country"
                                        value={clientData.country || ""}
                                        className="form-control"
                                        onChange={(val) =>
                                          this.selectCountry(val)
                                        }
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.country}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <Label
                                        title="Zip Code"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        inputType={"text"}
                                        name={"zip_code"}
                                        controlFunc={this.handleInput}
                                        content={clientData.zip_code.trim()}
                                        max={10}
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.zip_code}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Primary Mobile"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className=" frlrit">
                                      <div className="form-control">
                                        <PhoneInput
                                          international
                                          country="IN"
                                          defaultCountry="IN"
                                          disabled="true"
                                          placeholder="Enter phone number"
                                          value={clientData.phone_number}
                                          onChange={this.handlePhoneInput}
                                          max={13}
                                          error={
                                            clientData.phone_number
                                              ? isValidPhoneNumber(
                                                clientData.phone_number
                                              )
                                                ? undefined
                                                : "Invalid phone number"
                                              : "Phone number required"
                                          }
                                        />
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.phone_number}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label title="Email" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        // isMandatory={true}
                                        inputType={"text"}
                                        name={"email"}
                                        readOnly={true}
                                        controlFunc={this.handleInput}
                                        content={clientData.email}
                                        placeholder={""}
                                        max={50}
                                        isvisible={this.state.isDisable}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.email}
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Language"
                                        isMandatory={false}
                                      />
                                    </div>
                                    <div className="form-group  sform-control  frlrit  mb- ">
                                      <Select maxMenuHeight={120}
                                        className="form-control p-0 "
                                        isMulti
                                        name="language"
                                        value={clientData.language}
                                        onChange={this.handleselectChange}
                                        options={language}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.language}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Date of Birth"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrit datepickerdiv">
                                      <DatePicker
                                        className="form-control"
                                        value={clientData.date_of_birth}
                                        format="dd-MM-yyyy"
                                        onChange={(date) =>
                                          this.handleDOBChange(date)
                                        }
                                        yearPlaceholder='YYYY'
                                        monthPlaceholder='MM'
                                        dayPlaceholder='DD'
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.date_of_birth}
                                      />
                                    </div>
                                  </div>


                                </div>{" "}
                                {/** end of first column */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                        <div class="card-header"> <div class="card-title"> Employment Details</div> </div>
                          <div className="card-body h366">
                  

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>Employer Name</label>
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        isMandatory={false}
                                        inputType={"text"}
                                        name={"employer_name"}
                                        controlFunc={this.handleEmploymentDetails.bind(
                                          this,
                                          "employer_name"
                                        )}
                                        content={
                                          clientData.employment_details
                                            .employer_name
                                            ? clientData.employment_details
                                              .employer_name
                                            : ""
                                        }
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                        max={50}
                                      />
                                      {/* <SpanField
                                        classname="text-danger"
                                        title={errors.employment_details.employer_name}
                                      /> */}
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>Address</label>
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        isMandatory={false}
                                        inputType={"text"}
                                        // title={'Last Name'}
                                        name={"address"}
                                        id={"address"}

                                        controlFunc={this.handleEmploymentDetails.bind(
                                          this,
                                          "address"
                                        )}
                                        content={
                                          clientData.employment_details.address
                                            ? clientData.employment_details
                                              .address.trim()
                                            : ""
                                        }
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                        max={50}

                                      />
                                      {/* <SpanField
                                        classname="text-danger"
                                        title={errors.employment_details.address}
                                      /> */}
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>City</label>
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        isMandatory={false}
                                        inputType={"text"}
                                        // title={'Last Name'}
                                        name={"city"}
                                        max={50}
                                        controlFunc={this.handleEmploymentDetails.bind(
                                          this,
                                          "city"
                                        )}
                                        content={
                                          clientData.employment_details.city
                                            ? clientData.employment_details.city.trim()
                                            : ""
                                        }
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                      />
                                      {/* <SpanField
                                        classname="text-danger"
                                        title={errors.employment_details.city}
                                      /> */}
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <label>Country</label>
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <CountryDropdown
                                        placeholder="Country"
                                        name="country"
                                        value={
                                          clientData.employment_details
                                            .country.trim() || ""
                                        }
                                        className="form-control"
                                        onChange={(val) =>
                                          this.handleEmploymentDetails(
                                            "country",
                                            val
                                          )
                                        }
                                      />
                                      {/* <SpanField
                                        classname="text-danger"
                                        title={errors.employment_details.country}
                                      /> */}
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>State</label>
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <RegionDropdown
                                        name="state"
                                        defaultOptionLabel="Select State"
                                        country={
                                          clientData.employment_details.country
                                        }
                                        value={
                                          clientData.employment_details.state ||
                                          ""
                                        }
                                        className="form-control"
                                        onChange={(val) =>
                                          this.handleEmploymentDetails(
                                            "state",
                                            val
                                          )
                                        }
                                        placeholder="State"
                                      />
                                      {/* <SpanField
                                        classname="text-danger"
                                        title={errors.employment_details.state}
                                      /> */}
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <label>Zip</label>
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        isMandatory={false}
                                        inputType={"text"}
                                        max={50}
                                        // title={'Last Name'}
                                        name={"zip_code"}
                                        controlFunc={this.handleEmploymentDetails.bind(
                                          this,
                                          "zip_code"
                                        )}
                                        content={
                                          clientData.employment_details.zip_code
                                            ? clientData.employment_details
                                              .zip_code
                                            : ""
                                        }
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                      />
                                      {/* <SpanField
                                        classname="text-danger"
                                        title={errors.employment_details.zip_code}
                                      /> */}
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>Employer Phone </label>
                                    </div>
                                    <div className=" frlrit">
                                      <div className="form-control">
                                        <PhoneInput
                                          international
                                          defaultCountry="IN"
                                          placeholder="Enter phone number"
                                          value={
                                            clientData.employment_details
                                              .phone_number
                                          }
                                          onChange={this.handleEmploymentDetails.bind(
                                            this,
                                            "phone_number"
                                          )}
                                          max={13}
                                          error={
                                            clientData.employment_details
                                              .phone_number
                                              ? isValidPhoneNumber(
                                                clientData.employment_details
                                                  .phone_number
                                              )
                                                ? undefined
                                                : "Invalid phone number"
                                              : "Phone number required"
                                          }
                                        />
                                          <SpanField
                                          classname="text-danger"
                                          title={errors.employment_details?errors.employment_details.phone_number:""}
                                        /> 
                                      </div>
                                    </div>
                                  </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                      {/* MedicalInsurance */}
                        <div className="card">
                        <div class="card-header"> <div class="card-title"> Medical Insurance</div> </div>
                          <div className="card-body">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                  <button
                                        style={{
                                          display: this.state.editDisabled
                                            ? "block"
                                            : "none",
                                        }}
                                        onClick={this.editProfile.bind(this)}
                                      >
                                        Edit
                                      </button>
                                   

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>Insurance Provider</label>
                                      </div>
                                      <div className="form-group frlrit">
                                        <InputField
                                          // isMandatory={true}
                                          inputType={"text"}
                                          // title={'Last Name'}
                                          name={"insurance_provider"}
                                          controlFunc={this.handleInsuranceDetails.bind(
                                            this,
                                            "insurance_provider"
                                          )}
                                          content={
                                            clientData.insurance_details
                                              .insurance_provider
                                              ? clientData.insurance_details
                                                .insurance_provider.trim()
                                              : ""
                                          }
                                          placeholder={""}
                                          isvisible={this.state.isDisable}
                                          max={50}

                                        />
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.insurance_details?errors.insurance_details.insurance_provider:""}
                                        />
                                      </div>
                                    </div>

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>Policy ID</label>
                                      </div>
                                      <div className="form-group frlrit">
                                        <InputField
                                          // isMandatory={true}
                                          inputType={"text"}
                                          // title={'Last Name'}
                                          name={"policy_id_number"}
                                          controlFunc={this.handleInsuranceDetails.bind(
                                            this,
                                            "policy_id_number"
                                          )}
                                          content={
                                            clientData.insurance_details
                                              .policy_id_number
                                              ? clientData.insurance_details
                                                .policy_id_number.trim()
                                              : ""
                                          }
                                          placeholder={""}
                                          isvisible={this.state.isDisable}
                                          max={50}

                                        />
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.insurance_details?errors.insurance_details.policy_id_number:""}
                                        />
                                      </div>
                                    </div>

                                   

                                    {/* {vehicle_details.map((el, i) => (
                                      <Fragment key={i}>
                                        <div className="frllftfull  mt-10">
                                          {i === 0 ? (
                                            <h5 className="regxs regxsd">
                                              Vechile details
                                              <button
                                                type="button"
                                                onClick={this.addVehicleDetail.bind(
                                                  this
                                                )}
                                                className="plusbtn"
                                              >
                                                <i
                                                  className="fa fa-plus-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>
                                            </h5>
                                          ) : (
                                              ""
                                            )}
                                          <hr className="mrgtp" />
                                          {i !== 0 ? (
                                            <button
                                              type="button"
                                              onClick={this.removeVehicleDetail.bind(
                                                this
                                              )}
                                              className="plusbtn"
                                            >
                                              <i
                                                className="fa fa-minus"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          ) : (
                                              ""
                                            )}
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Vehicle Make</label>
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"vehicle_make"}
                                              controlFunc={this.handleVehicleDetailChange.bind(
                                                this,
                                                i,
                                                "vehicle_make"
                                              )}
                                              content={
                                                vehicle_details[i]
                                                  ? vehicle_details[i]
                                                    .vehicle_make
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Vehicle Model</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"vehicle_model"}
                                              controlFunc={this.handleVehicleDetailChange.bind(
                                                this,
                                                i,
                                                "vehicle_model"
                                              )}
                                              content={
                                                vehicle_details[i]
                                                  ? vehicle_details[i]
                                                    .vehicle_model
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                            <label>Year of Make</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"year_of_make"}
                                              controlFunc={this.handleVehicleDetailChange.bind(
                                                this,
                                                i,
                                                "year_of_make"
                                              )}
                                              content={
                                                vehicle_details[i]
                                                  ? vehicle_details[i]
                                                    .year_of_make
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>VIN number</label>
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"vin_number"}
                                              controlFunc={this.handleVehicleDetailChange.bind(
                                                this,
                                                i,
                                                "vin_number"
                                              )}
                                              content={
                                                vehicle_details[i]
                                                  ? vehicle_details[i]
                                                    .vin_number
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Vehicle registration </label>
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"vehicle_registration"}
                                              controlFunc={this.handleVehicleDetailChange.bind(
                                                this,
                                                i,
                                                "vehicle_registration"
                                              )}
                                              content={
                                                vehicle_details[i]

                                                  ? vehicle_details[i]
                                                    .vehicle_registration
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>
                                        </div>
                                      </Fragment>
                                    ))} */}

                                    

                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
<div class="card-header"> <div class="card-title"> Policy Holder Confirmation</div> </div>
<div className="card-body">
     <div className="frllftfull">
                                      

                                      <div className="row mt-10 mb-10">
                                        <div className="col-sm-6">
                                          <label>
                                            Does the patient holds a policy?
                                          </label>

                                          <div className="row">
                                            <div className="col-sm-12">
                                              <ToggleButton
                                                name="health_insurance_confirmation"
                                                dataOn={"Yes"}
                                                dataOff={"No"}
                                                controlFunc={this.handleInsuranceDetails.bind(
                                                  this,
                                                  "health_insurance_confirmation"
                                                )}
                                                checked={
                                                  clientData.insurance_details
                                                    .health_insurance_confirmation ===
                                                    "Yes"
                                                    ? true
                                                    : false
                                                }
                                              />

                                              {/* <div className="form-check fltx">
                                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                                        <label className="form-check-label" for="exampleRadios1">
                                                           Yes
                                                        </label>
                                                    </div>
													
													            <div className="form-check fltx">
                                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                                        <label className="form-check-label" for="exampleRadios1">
                                                           No
                                                        </label>
                                                      </div>*/}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-sm-6">
                                          <label>
                                            Is the patient a policy holder?
                                          </label>

                                          <div className="row">
                                            <div className="col-sm-12">
                                              <ToggleButton
                                                name="patient_policy_holder"
                                                dataOn={"Yes"}
                                                dataOff={"No"}
                                                controlFunc={this.handleInsuranceDetails.bind(
                                                  this,
                                                  "patient_policy_holder"
                                                )}
                                                checked={
                                                  clientData.insurance_details
                                                    .patient_policy_holder ===
                                                    "Yes"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
</div>
                        </div>
                        <div className="card">
<div class="card-header"> <div class="card-title"> Emergency Contact</div> </div>
<div className="card-body">
<div className="frllftfull  mt-10">
                                    
                                    </div>

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>First Name</label>
                                      </div>
                                      <div className="form-group frlrit">
                                        <InputField
                                          isMandatory={true}
                                          inputType={"text"}
                                          // title={'Last Name'}
                                          name={"first_name"}
                                          controlFunc={this.handelEmergencyContact.bind(
                                            this,
                                            "first_name"
                                          )}
                                          content={clientData.emergency_contact.first_name}
                                          placeholder={""}
                                          isvisible={this.state.isDisable}
                                          max={50}
                                          // tabIndex={0}
                                        />
      
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.emergency_contact ? errors.emergency_contact.first_name : ""}
                                        />
                                      </div>
                                    </div>

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>Last Name</label>
                                      </div>
                                      <div className="form-group frlrit">
                                        <InputField
                                          isMandatory={true}
                                          inputType={"text"}
                                          // title={'Last Name'}
                                          name={"last_name"}
                                          controlFunc={this.handelEmergencyContact.bind(
                                            this,
                                            "last_name"
                                          )}
                                          content={clientData.emergency_contact.last_name}
                                          placeholder={""}
                                          isvisible={this.state.isDisable}
                                          max={50}


                                          
                                        />
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.emergency_contact ? errors.emergency_contact.last_name : ""}
                                        />
                                      </div>
                                    </div>

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>
                                          Relationship
                                        </label>
                                      </div>
                                      <div className=" frlrit">
                                        <div className="form-group sform-control">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="relationship"
                                            value={
                                              clientData.emergency_contact
                                                .relationship
                                                ? clientData.emergency_contact
                                                  .relationship
                                                : ""
                                            }
                                            onChange={this.handelEmergencyContact.bind(
                                              this,
                                              "relationship"
                                            )}
                                            options={relationship}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.emergency_contact ? errors.emergency_contact.relationship : ""}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>Primary Mobile</label>
                                      </div>
                                      <div className=" frlrit">
                                        <div className="form-control">
                                          <PhoneInput
                                            international
                                            defaultCountry="IN"
                                            placeholder="Enter phone number"
                                            value={clientData.emergency_contact.phone_number}
                                            max={13}
                                            onChange={this.handelEmergencyContact.bind(
                                              this,
                                              "phone_number"
                                            )}
                                            error={
                                              clientData.emergency_contact
                                                .phone_number
                                                ? isValidPhoneNumber(
                                                  clientData.emergency_contact
                                                    .phone_number
                                                )
                                                  ? undefined
                                                  : "Invalid phone number"
                                                : "Phone number required"
                                            }
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.emergency_contact ? errors.emergency_contact.phone_number:""}
                                          />
                                        </div>
                                      </div>
                                    </div>
</div>
                        </div>
                        <div className="card">


{payment_details.map((el, i) => (
                                      <Fragment key={i}>
                                        <div class="card-header">
                                          <div class="card-title"> 
                                          {i === 0 ? (
                                            <span> Payment Details</span>
                                          ) : (
                                            ""
                                          )}
                                          </div> 
                                        </div>
                                        <div className="card-body">
                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Card Number</label>
                                          </div>
                                          <div className="form-group  frlrit">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              value={this.state.value}
                                              // title={'Last Name'}
                                              name={"credit_card"}
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,
                                                "credit_card"
                                              )}
                                              content={
                                                payment_details[i]
                                                  ? payment_details[i]
                                                    .credit_card
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                              max={16}

                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={payment_details_error[i].credit_card}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Name in Card</label>
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"card_name"}
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,
                                                "card_name"
                                              )}
                                              content={
                                                payment_details[i]
                                                  ? payment_details[i].card_name
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                              max={50}

                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={payment_details_error[i].card_name}
                                            />
                                          </div>
                                          
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Expiration Date</label>
                                          </div>
                                          <div className="form-group frlrclfgf-4">
                                            <MonthPick
                                              defaultValue="MM"
                                              year={
                                                payment_details[i] ? payment_details[i].expiry_month : ""
                                              }
                                              selectedOption={
                                                payment_details[i]
                                                  ? payment_details[i]
                                                    .expiry_month
                                                  : ""
                                              }
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,

                                                "expiry_month"
                                              )}
                                              id="expiry_month"
                                              name="expiry_month"
                                            />
                                          </div>

                                          <div className="form-group frlrclfgf-1">
                                            {/* <label>Expiry Year</label> */}
                                          </div>
                                          <div className="form-group frlrclfgf-4 mg-left3">
                                            <YearPick
                                              defaultValue="YY"
                                              startyear={currentYear}
                                              endyear={currentYear + 20}
                                              selectedOption={payment_details[i]? payment_details[i].expiry_year : "" }
                                              controlFunc={this.handlePaymentDetailChange.bind(this,i,"expiry_year")}
                                              id="expiry_year"
                                              name="expiry_year"
                                            />
                                          </div>
                                          <div className="form-group frllft">
                                            <label className="ml-3">CVV</label>
                                          </div>
                                          <div className="form-group frlrit frlrclfgf mg-t1 float-right">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"cvv"}
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,
                                                "cvv"
                                              )}
                                              content={
                                                payment_details[i]
                                                  ? payment_details[i].cvv
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                              max={3}

                                            />

                                            <SpanField
                                              classname="text-danger"
                                              title={payment_details_error[i].cvv}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Card Type</label>
                                          </div>
                                          <div className="form-group sform-control frlrit">
                                            <Select maxMenuHeight={120}
                                              className="form-control p-0"
                                              name="card_type"
                                              value={
                                                payment_details[i]
                                                  ? payment_details[i].card_type
                                                  : ""
                                              }
                                              onChange={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,
                                                "card_type"
                                              )}
                                              options={card_type}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>
                                              Netbanking/ Paytm / Paypal
                                            </label>
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"payment_option"}
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,
                                                "payment_option"
                                              )}
                                              content={
                                                payment_details[i]

                                                  ? payment_details[i]
                                                    .payment_option
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                              max={50}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={payment_details_error[i].payment_option}
                                            />
                                          </div>
                                        </div>
                                        </div>
                                      
                                      </Fragment>
                                    ))} 

</div>
                      </div>
                      {/** end of second column */}
                    </div>

                    
                  </div>
                  {" "}
                  {/** end of row */}
                </div>
              </div>

          
            </section>
            <FooterSection />
          </div>

        </div>
      </div>
    );
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientProfile));
