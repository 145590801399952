import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDateISOFormat,
  getFrequencyFromDays,
  setDaysoftheWeek
} from "../../constants/globalLevelFunctions";
import {
  businessShiftPlannerView,
  getBusinessUserbyBusiness,
  createShiftPlanner,
  updateshiftplanner,
  removeshiftplanner,
  getTimeoutbycategory
} from "../../config/url.json";
import { ExpirePopup } from "../../components/UI/ExpirePopup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../../components/container/layout/client/Header";
import BusinessHeader from "../../components/container/layout/business/Header";
import BusinessUserSideBar from "../../components/container/SideBar";
import ClientSideBar from "../../components/container/layout/client/SideBar";
import Scheduler, { SchedulerData, ViewTypes } from "react-big-scheduler";
import "react-big-scheduler/lib/css/style.css";
import DragDropContext from "../../components/UI/withDnDContext";
import DatePicker from "react-date-picker";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as moment from "moment";
// import TimePicker from "react-bootstrap-time-picker";
import {
  working_days,
  schedule_type,
  shift_series,
} from "../../../src/constants/config/data";
import CheckboxOrRadioGroup from "../../components/UI/CheckboxOrRadioGroup";
import { CONFIG } from "../../config/data";
import Spinner from "../../components/UI/Spinner";
import Validation from "../../actions/Validation";
import SpanField from "../../components/UI/SpanField";
import { data } from "jquery";
import { Fragment } from "react";
import FooterSection from "../../components/UI/FooterSection";
import Footerad from "../../components/UI/Footerad";
import BusinessUserHeader from "../../components/container/layout/business_user/Header";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal'
import { Popupinfo } from "../../components/UI/Popupinfo";
import Dnaspinner from "../../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity

  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}


const initalValue = {
  from: "",
  to: "",
  totalDays: 0,
  morning_shift_start: "0",
  morning_shift_end: "0",
  evening_shift_start: "0",
  evening_shift_end: "0",
  hours_24: false,
  working_days: [],
  working_all_days: false,
  selectedDoc: "",
  schedule_type: "",
  errors: {
    selectedDoc: "",
    shift: "",
    to: "",
    from: "",
    schedule_type: "",
  },
};

const initalUpdateShift = {
  doctorName: "",
  business_user_id: "",
  from: "",
  to: "",
  totalDays: 0,
  shift_start: "",
  shift_end: "",
  schedule_type: "",
  shiftOccurence: ["Single Day"],
  event_id: "",
  errors: {
    selectedDoc: "",
    shift: "",
    to: "",
    from: "",
    schedule_type: "",
  },
  id: ""
};

class ShiftPlanner extends Component {
  constructor(props) {
    super(props);

    let schedulerData = new SchedulerData(new Date(), ViewTypes.Week);
    schedulerData.localeMoment.locale("en");
    schedulerData.config.resourceName = "Doctors";
    schedulerData.config.views = [
      {
        viewName: "Day",
        viewType: ViewTypes.Day,
        showAgenda: false,
        isEventPerspective: false,
      },
      {
        viewName: "Week",
        viewType: ViewTypes.Week,
        showAgenda: false,
        isEventPerspective: false,
      },
      {
        viewName: "Month",
        viewType: ViewTypes.Month,
        showAgenda: false,
        isEventPerspective: false,
      },
    ];
    schedulerData.config.schedulerWidth = "80%";
    schedulerData.config.calendarPopoverEnabled = true;


    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;
    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      showexpirePopup: this.props.validity,

      viewModel: schedulerData,
      doctors_list: [],
      events: [],
      resources: [],
      from: "",
      to: "",
      openModal: false,
      editModel: false,
      createShift: initalValue,
      updateShift: initalUpdateShift,
      loading: false,
      deleteConfirm: false
    };
    this.from = "";
   
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
   
  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   
};

  _onAction(e) {
   // console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }
 

  closePopup = (e) => {
    this.setState({
      openModal: false,
      editModel: false,
      deleteConfirm: false
    });
  };

  deleteEvent = (e) => {
    this.setState({ deleteConfirm: true });
  }

  conformDeleteEvent = (e) => {
    console.log(this.state.updateShift);

    let udpateObj = this.state.updateShift;

    var data = {};
    //find all series or only this occurence
    //shift_series static data from constant
    if (shift_series[1] === udpateObj.shiftOccurence.toString()) {
      data = {
        entireEventUpdate: true,
        id: udpateObj.appointment.event_id,
        businessUserid: udpateObj.appointment.business_user_id,
        businessId: udpateObj.appointment.business_id,
        startDate: getDateISOFormat(udpateObj.from),
        endDate: getDateISOFormat(udpateObj.to),
      }
    } else {
      data = {
        singleEventUpdate: true,
        appointment: {
          schedule_id: udpateObj.appointment.schedule_id,
        }
      }
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(removeshiftplanner, data)
      .then((result) => {
        if (result.data.status === 1) {
          console.log(result);
          this.closePopup();
         // alert(result.data.message);
         
          this.searchEvents();
          toast.error("Shifts have been deleted successfully.",{
            className:"toast-error"
          });
        } else if (result.data.status === 0) {
          console.log(result);
          this.closePopup();
         // alert(result.data.message);
          toast.error("Shift could not be deleted.",{
            className:"toast-success"
          });
          //somesh remove once backend is fixed
          this.searchEvents();
        }
      })
      .catch((e) => {
        //alert(e);
        console.log(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });


  };

  updateEvent = (e) => {
    let udpateObj = this.state.updateShift;

    var data = {};
    //find all series or only this occurence
    //shift_series static data from constant

    if (shift_series[1] === udpateObj.shiftOccurence.toString()) {
      data = this.getAllEventSeriesObj(udpateObj);
    } else {
      data = this.getSingleEventObj(udpateObj);
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(updateshiftplanner, data)
      .then((result) => {
        if (result.data.status === 1) {
          console.log("this.state.viewModel");
          console.log(result);
          this.closePopup();
        //  alert(result.data.message);
          toast.success("Shifts have been updated successfully.",{
            className:"toast-success"
          });
          if (data.singleEventUpdate) {
            //Refresh table data without calling backend services
            let schedulerData = this.state.viewModel;

            let events = this.state.events;
            events.filter(
              (eventItem) => {
                if (eventItem.id === udpateObj.id) {
                  console.log(eventItem);
                  eventItem.schedule_type = data.appointment.schedule_type;
                  eventItem.start = data.appointment.appt_start_time;
                  eventItem.end = data.appointment.appt_end_time;
                  eventItem.bgColor = (data.appointment.schedule_type.toLowerCase() === "appointment")
                    ? "#007dd7"
                    : "#00b300"
                }
              }
            );
            this.setState({
              events
            });
            schedulerData.setEvents(events);
            this.setState({
              viewModel: schedulerData
            });
          } else {
            this.searchEvents();
          }

        } else if (result.data.status === 0) {
          console.log(result);
          this.closePopup();
         // alert(result.data.message);
          toast.error("Shift could not be updated.",{
            className:"toast-error"
          });
        }
      })
      .catch((e) => {
        //alert(e);
        console.log(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };


  getSingleEventObj = (udpateObj) => {
    let appt_start_time = "";
    if (isNaN(udpateObj.shift_start)) {
      appt_start_time =
        getDateISOFormat(udpateObj.from) + "T" + udpateObj.shift_start + ":00";
    } else {
      appt_start_time =
        getDateISOFormat(udpateObj.from) +
        "T" +
        new Date(udpateObj.shift_start * 1000).toISOString().substr(11, 8);
    }

    let appt_start_end = "";
    if (isNaN(udpateObj.shift_end)) {
      appt_start_end =
        getDateISOFormat(udpateObj.from) + "T" + udpateObj.shift_end + ":00";
    } else {
      appt_start_end =
        getDateISOFormat(udpateObj.from) +
        "T" +
        new Date(udpateObj.shift_end * 1000).toISOString().substr(11, 8);
    }

    let data = {
      singleEventUpdate: true,
      businessUserid: udpateObj.appointment.business_user_id,
      businessId: udpateObj.appointment.business_id,
      appointment: {
        schedule_id: udpateObj.appointment.schedule_id,
        business_id: udpateObj.appointment.business_id,
        business_user_id: udpateObj.appointment.business_user_id,
        schedule_day_id: udpateObj.appointment.schedule_day_id,
        appt_start_time: appt_start_time,
        appt_end_time: appt_start_end,
        appt_next_available: udpateObj.appointment.appt_next_available,
        status: "Scheduled",
        schedule_type: udpateObj.schedule_type.value,
      },
      id: udpateObj.appointment.event_id,
    };

    return data;
  };

  getAllEventSeriesObj = (udpateObj) => {
    console.log(udpateObj);
    let sessionArray = [];
    let start_date_time = "";
    if (isNaN(udpateObj.shift_start)) {
      start_date_time =
        getDateISOFormat(udpateObj.from) + "T" + udpateObj.shift_start + ":00";
    } else {
      start_date_time =
        getDateISOFormat(udpateObj.from) +
        "T" +
        new Date(udpateObj.shift_start * 1000).toISOString().substr(11, 8);
    }

    let end_date_time = "";
    if (isNaN(udpateObj.shift_end)) {
      end_date_time =
        getDateISOFormat(udpateObj.from) + "T" + udpateObj.shift_end + ":00";
    } else {
      end_date_time =
        getDateISOFormat(udpateObj.from) +
        "T" +
        new Date(udpateObj.shift_end * 1000).toISOString().substr(11, 8);
    }

    if (
      start_date_time !== "" &&
      end_date_time !== ""
    ) {
      let sessionObj = {
        type: "Morning",
        from: start_date_time,
        to: end_date_time
      };
      sessionArray.push(sessionObj);
    }

    let data = {
      entireEventUpdate: true,
      businessUserid: udpateObj.appointment.business_user_id,
      businessId: udpateObj.appointment.business_id,
      startDate: getDateISOFormat(udpateObj.from),
      endDate: getDateISOFormat(udpateObj.to),
      status: "Scheduled",
      scheduleType: udpateObj.schedule_type.value,
      // frequency: getFrequencyFromDays(udpateObj.working_days),
      isAllDayEvent: false,
      id: udpateObj.appointment.event_id,
      sessions: sessionArray,
    };

    return data;
  };

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });

     
   
    //if beyond search results then call search events to get the data from db 
    if(new Date(schedulerData.startDate) < this.state.from){
        console.log(schedulerData.viewType);
        console.log(schedulerData.startDate);

       this.setState({
        from: new Date(schedulerData.startDate)
       }, () => {
        this.searchEvents()
      }); 
    }
  };

  nextClick = (schedulerData) => {
   
    schedulerData.next();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });
    
    
  
    //if beyond search results then call search events to get the data from db 
    if(new Date(schedulerData.endDate) > this.state.to){
        console.log(schedulerData.viewType);
        console.log(schedulerData.startDate);

       this.setState({
        to: new Date(schedulerData.endDate), from:new Date(schedulerData.startDate)
       }, () => {
        this.searchEvents()
      }); 
    }
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventClicked = (schedulerData, event) => {

    if (event.appointment.isActive) {

      let updateShift = Object.assign(
        {},
        initalUpdateShift
      );;

      updateShift.doctorName = event.doctorName;
      updateShift.business_user_id = event.resourceId;
      updateShift.from = moment(event.start, moment.defaultFormat).toDate();
      updateShift.to = moment(event.end, moment.defaultFormat).toDate();
      updateShift.shift_start = event.start.split("T")[1].substring(0, 5);
      updateShift.shift_end = event.end.split("T")[1].substring(0, 5);
      updateShift.schedule_type = {
        label: event.schedule_type,
        value: event.schedule_type,
      };
      updateShift.appointment = event.appointment;
      updateShift.id = event.id;
      this.setState({
        updateShift,
        editModel: true,
      });
    }

  };

  ops1 = (schedulerData, event) => {
    alert(
      `You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    if (
      alert(
        `Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`
      )
    ) {
      let newFreshId = 0;
      schedulerData.events.forEach((item) => {
        if (item.id >= newFreshId) newFreshId = item.id + 1;
      });

      let newEvent = {
        id: newFreshId,
        title: "New event you just created",
        start: start,
        end: end,
        resourceId: slotId,
        bgColor: "purple",
      };
      schedulerData.addEvent(newEvent);
      this.setState({
        schedulerData: schedulerData,
      });
    }
  };

  updateEventStart = (schedulerData, event, newStart) => {
    if (
      alert(
        `Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`
      )
    ) {
      schedulerData.updateEventStart(event, newStart);
    }
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    if (
      alert(
        `Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`
      )
    ) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    this.setState({
      viewModel: schedulerData,
    });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (
      alert(
        `Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`
      )
    ) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      this.setState({
        viewModel: schedulerData,
      });
    }
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.state.events);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.state.events);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = 10;
    }
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollTop");
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollBottom");
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(
        getBusinessUserbyBusiness,
        { business_id: this.props.userdata.id },
        CONFIG
      )
      .then((res) => {
        let data = res.data;

        if (data.status !== "0") {
          let resdata = data.data;
          let doctorList = [];

          resdata.forEach((element, index) => {
            if (element.status === "Approved") {
              let doctor = {};
              doctor.label = element.first_name + " , " + element.last_name;
              doctor.value = element.user_id;
              doctor.schedule_type = element.schedule_type;
              doctorList[index] = doctor;
            }

          });
          this.setState({ doctors_list: doctorList });
        }
      })
      .catch((e) => { });

    var from = new Date();

    var to = new Date();
    to.setDate(to.getDate() + 30);

    this.setState({ from, to });

    this.from = from;

    const data = {
      businessId: this.props.userdata.id,
      From: getDateISOFormat(from),
      To: getDateISOFormat(to),
    };

    //call db to get the event data
    this.loadEventsData(data);
  };

  calenderConfig = () => {
    console.log(this.from);
    let schedulerData = new SchedulerData(this.from, ViewTypes.Week);
    schedulerData.localeMoment.locale("en");
    schedulerData.config.resourceName = "Doctors";
    schedulerData.config.views = [
      {
        viewName: "Day",
        viewType: ViewTypes.Day,
        showAgenda: false,
        isEventPerspective: false,
      },
      {
        viewName: "Week",
        viewType: ViewTypes.Week,
        showAgenda: false,
        isEventPerspective: false,
      },
      {
        viewName: "Month",
        viewType: ViewTypes.Month,
        showAgenda: false,
        isEventPerspective: false,
      },
    ];
    schedulerData.config.schedulerWidth = "80%";
    schedulerData.setEvents([]);
    schedulerData.setResources([]);

    console.log(schedulerData);

    this.setState({
      viewModel: schedulerData,
    });

    console.log(this.state.viewModel);
  };

  loadEventsData = (data) => {
    
    this.enableLoader();

    let schedulerData = this.state.viewModel;
    schedulerData.setDate(this.from);
   
    
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(businessShiftPlannerView, data)
      .then((result) => {
        if (result.data.status === 1) {
          let resources = this.state.resources;
          resources = [];
          let events = this.state.events;
          events = [];
          let ui_event_id = 0;

          result.data.data.map((doctorDetail, i) => {
            resources.push({
              id: doctorDetail.business_user_id,
              name: doctorDetail.firstName + " , " + doctorDetail.lastName,
            });

            doctorDetail.appointments[0].appointments.map((appoint, i) => {
              let event = {
                id: ui_event_id++,
                start: appoint.appt_start_time,
                end: appoint.appt_end_time,
                resourceId: appoint.business_user_id,
                title: "",
                bgColor:
                  appoint.isActive ? (appoint.schedule_type.toLowerCase() === "appointment"
                    ? "#007dd7"
                    : "#00b300") : "#b3b3b3",
                showPopover: true,
                doctorName:
                  doctorDetail.firstName + " , " + doctorDetail.lastName,
                schedule_type: appoint.schedule_type,
                event_id: appoint.event_id,
                appointment: appoint,
              };

              events.push(event);
            });
          });

          this.setState({
            resources,
            events,
          }, () => {
            schedulerData.setResources(resources);
            schedulerData.setEvents(events);
            
            this.setState({
              viewModel: schedulerData,
            }, this.disableLoader());
          });
          
         
         
        } else if (result.data.status === 0) {
           alert(
            result.data.message +
            " From: " +
            getDateISOFormat(this.state.from) +
            " To: " +
            getDateISOFormat(this.state.to)
          ); 
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        //alert(e);
        this.setState({ loading: false });
        console.log(e);
      });
  };

  enableLoader = () => {
    this.setState({ loading: true });
  }

  disableLoader = () => {
    this.setState({ loading: false });
  }

  addNewShiftPlanner = () => {
    this.enableLoader();
    let createShift = this.state.createShift;

    createShift.type = "ShiftPlannerCreateEvent";
    let errors = {};
    errors = Validation(createShift, errors);
    createShift.errors = errors;
    this.setState({ createShift });

    if (!errors.formIsValid) {
      this.disableLoader();
      return;
    }

    let createEventData = {};
    createEventData.businessId = this.props.userdata.id;
    createEventData.businessUserid = createShift.selectedDoc.value;
    createEventData.startDate = getDateISOFormat(createShift.from);
    createEventData.endDate = getDateISOFormat(createShift.to);
    createEventData.isAllDayEvent = createShift.working_all_days;
    createEventData.status = "Scheduled";
    createEventData.frequency = getFrequencyFromDays(createShift.working_days);
    createEventData.scheduleType = createShift.schedule_type.value;


    let sessionArray = [];
    if (
      createShift.morning_shift_start !== "" &&
      createShift.morning_shift_end !== "" && createShift.morning_shift_start !== createShift.morning_shift_end
    ) {
      console.log("morning");
      var a = createShift.morning_shift_start.split(':'); // split it at the colons
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var morning_shift_start = (+a[0]) * 60 * 60 + (+a[1]) * 60;

      var b = createShift.morning_shift_end.split(':'); // split it at the colons
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var morning_shift_end = (+b[0]) * 60 * 60 + (+b[1]) * 60;

      let sessionObj = {
        type: "Morning",
        from: createEventData.startDate + "T" + new Date(morning_shift_start * 1000).toISOString().substr(11, 8),
        to: createEventData.endDate + "T" + new Date(morning_shift_end * 1000).toISOString().substr(11, 8),
      };
      sessionArray.push(sessionObj);
    }
    if (
      createShift.evening_shift_start !== "" &&
      createShift.evening_shift_end !== "" && createShift.evening_shift_start !== createShift.evening_shift_end
    ) {

      var a = createShift.evening_shift_start.split(':'); // split it at the colons
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var evening_shift_start = (+a[0]) * 60 * 60 + (+a[1]) * 60;

      var b = createShift.evening_shift_end.split(':'); // split it at the colons
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var evening_shift_end = (+b[0]) * 60 * 60 + (+b[1]) * 60;


      let sessionObj = {
        type: "Evening",
        from:
          createEventData.startDate +
          "T" +
          new Date(evening_shift_start * 1000)
            .toISOString()
            .substr(11, 8),
        to:
          createEventData.endDate +
          "T" +
          new Date(evening_shift_end * 1000)
            .toISOString()
            .substr(11, 8),
      };
      sessionArray.push(sessionObj);
    }
    createEventData.sessions = sessionArray;

    console.log(createEventData);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(createShiftPlanner, createEventData, CONFIG)
      .then((res) => {
        console.log(createEventData);
        console.log(this.state.events);
        let result = res.data;

        if (1 === result.status) {
          this.closePopup();
          this.disableLoader();
         // alert("Event created Successfully");
          toast.success("Shifts have been created successfully.",{
            className:"toast-success"
          });
          this.searchEvents();
        } else if (0 === result.status) {
          this.disableLoader();
          alert(result.message);
          toast.error("Your Shifts overlap with already existing events.Shifts cannot be created.",{
            className:"toast-error"
          });
          this.searchEvents();
        }
      })
      .catch((e) => {
        this.disableLoader();
      });
  };

  handleUpdateDateChange = (e, element) => {
    let updateShift = this.state.updateShift;
    let errors=this.state.updateShift.errors;
    if (element === "updateShift.from") {
      updateShift.from = e;
      errors.from="";
    } else {
      updateShift.to = e;
      errors.to="";
    }

    this.setState({
      updateShift,errors
    });
  };

  handleDOBChange = (e, element) => {
    if ("from" === element || "to" === element) {
      this.setState({
        [element]: e,
      });
    } else {
      let createShift = this.state.createShift;
      let errors=this.state.createShift.errors;
      if ("createShift.from" === element) {
        createShift.from = e;
        errors.from="";
      } else {
        createShift.to = e;
        errors.to="";
      }


      this.setState({
        createShift
      });

      //this will set the daysof the week name in createshift
      setDaysoftheWeek(createShift, e);

      //Calculate the date difference
      const diff = moment(this.state.createShift.to).diff(
        moment(this.state.createShift.from)
      );
      console.log(this.state.createShift.to);
      console.log(diff);
      const diffDuration = moment.duration(diff);
      const ndate1 = new Date(this.state.createShift.from);
      const ndate2 = new Date(this.state.createShift.to);
      const diffTime = Math.abs(ndate2 - ndate1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
       console.log(diffTime + " milliseconds");
       console.log(diffDays + " days"); 
     /*   createShift.totalDays = isNaN(diffDuration.days())
        ? 0
        : diffDuration.days() + 1; */
createShift.totalDays=isNaN(diffDays)? 0 :diffDays + 1;
console.log(createShift.totalDays);
createShift.working_days.length === 7
? (createShift.working_all_days = true)
: (createShift.working_all_days = false);

      this.setState({
        createShift,
      });
    }
  };

  handleShiftOccurence = (e) => {
    let data = this.state.updateShift;
    data.shiftOccurence = [e.target.value];
    this.setState({ updateShift: data });
  };

  searchEvents = () => {
    this.from = this.state.from;

    if (this.state.from && this.state.to) {
      const data = {
        businessId: this.props.userdata.id,
        From: getDateISOFormat(this.state.from),
        To: getDateISOFormat(this.state.to),
      };
      //call db to get the event data
      this.loadEventsData(data);
    }
  };

  handleMorningWorkhoursStartChange(field, value) {
    let data = this.state.createShift;
    let errors=this.state.createShift.errors;
    console.log(field, value);
    if ("24Hours" === field) {
      data.hours_24 = !data.hours_24;
      data.morning_shift_start = '00:00';
      data.morning_shift_end = '11:59';
      data.evening_shift_start = '12:00';
      data.evening_shift_end = '23:59';
      errors.shift="";
    } else {
      data.hours_24 = false;
      data[`${field}`] = value.target.value;
     
      if(data.morning_shift_start >= '12:00' && data.morning_shift_start <= '23:59')
      {
        errors.shift="please select correct morning time for the starting shift";
      }else
      {
        errors.shift="";
      }
  
    }

    this.setState({ createShift: data,errors });
  }

  handleMorningWorkhoursEndChange(field, value) {
    let data = this.state.createShift;
    let errors=this.state.createShift.errors;
    console.log(field, value);
    if ("24Hours" === field) {
      data.hours_24 = !data.hours_24;
      data.morning_shift_start = '00:00';
      data.morning_shift_end = '11:59';
      data.evening_shift_start = '12:00';
      data.evening_shift_end = '23:59';
      errors.shift="";
    } else {
      data.hours_24 = false;
      data[`${field}`] = value.target.value;
        
      if(data.morning_shift_end >= '12:00' && data.morning_shift_end <= '23:59')
      {
        errors.shift="please select correct morning time for the ending shift";
      }else
      {
        errors.shift="";
      } 
    
    }

    this.setState({ createShift: data,errors });
  }

  handleEveningWorkhoursStartChange(field, value) {
    let data = this.state.createShift;
    let errors=this.state.createShift.errors;
    console.log(field, value);
    if ("24Hours" === field) {
      data.hours_24 = !data.hours_24;
      data.morning_shift_start = '00:00';
      data.morning_shift_end = '11:59';
      data.evening_shift_start = '12:00';
      data.evening_shift_end = '23:59';
      errors.shift="";
    } else {
      data.hours_24 = false;
      data[`${field}`] = value.target.value;
       
      if(data.evening_shift_start >= '00:00' && data.evening_shift_start <= '11:59')
      {
        errors.shift="please select correct evening time for the starting shift";
      }else
      {
        errors.shift="";
      }
    
     
    }

    this.setState({ createShift: data,errors });
  }

  handleEveningWorkhoursEndChange(field, value) {
    let data = this.state.createShift;
    let errors=this.state.createShift.errors;
    console.log(field, value);
    if ("24Hours" === field) {
      data.hours_24 = !data.hours_24;
      data.morning_shift_start = '00:00';
      data.morning_shift_end = '11:59';
      data.evening_shift_start = '12:00';
      data.evening_shift_end = '23:59';
      errors.shift="";
    } else {
      data.hours_24 = false;
      data[`${field}`] = value.target.value;
    
      if(data.evening_shift_end >= '00:00' && data.evening_shift_end <= '11:59')
      {
        errors.shift="please select correct evening time for the ending shift";
      }else
      {
        errors.shift="";
      }
  
    }

    this.setState({ createShift: data,errors });
  }
  handleWorkhoursChange(field, value) {
    let data = this.state.createShift;
    let errors=this.state.createShift.errors;
    console.log(field, value);
    if ("24Hours" === field) {
      data.hours_24 = !data.hours_24;
      data.morning_shift_start = '00:00';
      data.morning_shift_end = '11:59';
      data.evening_shift_start = '12:00';
      data.evening_shift_end = '23:59';
      errors.shift="";
    } else {
      data.hours_24 = false;
      data[`${field}`] = value.target.value;
      console.log(value.target.value);
      console.log(data.evening_shift_start);
     console.log(data.morning_shift_start);
      if(data.morning_shift_start <= '00:00' && data.morning_shift_start >= '11:59')
      {
        errors.shift="please select correct morning time for the starting shift";
      }else
      {
        errors.shift="";
      }
      if(data.morning_shift_end >= '12:00' && data.morning_shift_end <= '23:59')
      {
        errors.shift="please select correct morning time for the ending shift";
      }else
      {
        errors.shift="";
      }
      if(data.evening_shift_start >= '00:00' && data.evening_shift_start <= '11:59')
      {
        errors.shift="please select correct evening time for the starting shift";
      }else
      {
        errors.shift="";
      }
      if(data.evening_shift_end >= '00:00' && data.evening_shift_end <= '11:59')
      {
        errors.shift="please select correct evening time for the ending shift";
      }else
      {
        errors.shift="";
      }
      console.log(data.evening_shift_end);
    }

    this.setState({ createShift: data,errors });
  }

  handleUpdateWorkhoursChange(field, value) {
    console.log(field);
    console.log(value);
    let data = this.state.updateShift;

    data[`${field}`] = value.target.value;

    console.log(field);
    console.log(value);
    this.setState({ updateShift: data });
  }

  handleWorkingDays = (e) => {
    let data = this.state.createShift;

    let working_all_days_data = data.working_all_days;

    if ("allDaysId" === e.target.id) {
      working_all_days_data = !working_all_days_data;

      data.working_all_days = working_all_days_data;

      data.working_all_days
        ? (data.working_days = working_days)
        : (data.working_days = []);
    } else {
      const newSelection = e.target.value;
      let newSelectionArray;

      if (data.working_days.indexOf(newSelection) > -1) {
        newSelectionArray = data.working_days.filter((s) => s !== newSelection);
      } else {
        newSelectionArray = [...data.working_days, newSelection];
      }

      data.working_days = newSelectionArray;
      let wd = [];
      data.working_days.forEach((element, index) => {
        if (working_days.indexOf(element) > -1) {
          wd.push(element);
        }
      });

      data.working_days = wd;
    }

    data.working_days.length === 7
      ? (data.working_all_days = true)
      : (data.working_all_days = false);

    this.setState({ createShift: data });
  };

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.createShift;
    let errors=this.state.createShift.errors;
    data[e.name] = selectedOptions;
    errors[e.name]="";
    if (e.name !== "schedule_type") {
      if (null !== selectedOptions.schedule_type) {
        data.schedule_type = {
          label: selectedOptions.schedule_type,
          value: selectedOptions.schedule_type,
        };
      } else {
        data.schedule_type = "";
      }
    }
    this.setState({ createShift: data,errors });
  };

  handleselectUpdateChange = (selectedOptions, e) => {
    let data = this.state.updateShift;
    let errors=this.state.updateShift.errors;
    data[e.name] = selectedOptions;
    errors[e.name]="";
    this.setState({ updateShift: data,errors });
  };

  render() {
    const { loading, viewModel,hospitaltimeout } = this.state;
    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    let PopupConfirm = () => {
      this.props.history.push({
        pathname: "/showsubscription",
        state: {}
      });
    };
    return (
      <div className="main-content bacfot">
         <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />
   
        <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={PopupClose}
          onConfirm={PopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />
      {/*   <Spinner loading={loading} /> */}
      {loading && <Dnaspinner />}
        <div className="mainpage">
          <section className="my-head">
            <div className="container-fluid">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessHeader user_id={this.props.userdata.id} />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserHeader user_id={this.props.userdata.id} />
              ) : (
                <Header user_id={this.props.userdata.id} />
              )}
            </div>
          </section>
          <div className="main-box">
            <div className="sidemenu pr0">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessUserSideBar />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserSideBar />
              ) : (
                <ClientSideBar />
              )}
            </div>
            {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
            <section className="main-body hospital-profilegap">
              <div className="container-fluid">
              <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                <div className="checkout-tabs">
                  <div className="row">


                    {/* shift planner page starts here */}
                    <div className="col-sm-12">
                      <h4 className="font-weight-bold">Shift Planner</h4>
                      <div className="row mt-4">
                        <div className="col-sm-12 ">

                          <div className="card ">

                            <div className="card-body  pad-btm0 ">

                              {/* <div className="row">
                                <div className="col-lg-7">
                                  <div className="row">

                                    <div className="col-sm-1 ">
                                      <div className="required">
                                        <label className="f12">Date</label>
                                      </div>

                                    </div>
                                    <div className="col-sm-4">
                                      <div className="form-group">
                                        <div className="input-group">
                                          <DatePicker
                                            className="form-control"
                                            value={this.state.from}
                                            format="dd-MM-yyyy"
                                            onChange={(date) =>
                                              this.handleDOBChange(date, "from")
                                            }
                                          />
                                        </div>
                                      </div>


                                    </div>
                                    <div className="col-sm-1">
                                     <label> to </label>
                                    </div>
                                    <div className="col-sm-4">
                                      <div className="form-group">
                                        <div className="input-group">
                                          <DatePicker
                                            className="form-control"
                                            value={this.state.to}
                                            minDate={this.state.from}
                                            format="dd-MM-yyyy"
                                            onChange={(date) =>
                                              this.handleDOBChange(date, "to")
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="form-group row">
                                    <div className="input-group">
                                      <button
                                        type="button"
                                        className="signupbtn abtn btdpml"
                                        onClick={this.searchEvents}
                                      >
                                        <i
                                          className="fa fa-search-plus"
                                          aria-hidden="true"
                                        ></i>

                                        &nbsp;Search
                                      </button>
                                      &nbsp;&nbsp;&nbsp;
                                      &nbsp;&nbsp;&nbsp;
                                      <button
                                        type="button"
                                        className="signupbtn abtn btdpml"
                                        onClick={() => {
                                          let initVal = Object.assign(
                                            {},
                                            initalValue
                                          );
                                          this.setState({
                                            createShift: initVal,
                                          });
                                          this.setState({ openModal: true, });
                                        }}
                                      >
                                        <i
                                          className="fa fa-user-plus"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Add
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                               <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="ShiftPlanner">
                                                                    <div className="d-flex flex-wrap">
                                                                    <div className="d-flex mr-3">
                                                                        
                                                                    <div className="form-group ">
                                                                                
                                                                                <div className="input-group mt-3">
                                                                                <div className="required mr-3">
                                                                                        <label>From</label>
                                                                                </div>
                                                                                    <DatePicker
                                                                                        className="form-control"
                                                                                        value={this.state.from}
                                                                                        format="dd-MM-yyyy"
                                                                                        onChange={(date) =>
                                                                                            this.handleDOBChange(date, "from")
                                                                                        }
                                                                                        yearPlaceholder='YYYY'
                                                                                        monthPlaceholder='MM'
                                                                                        dayPlaceholder='DD'
                                                                                    />
                                                                                </div>
                                                                    </div>
                                                                    </div>
                                                                        <div className="d-flex mr-3 flex-grow-1">
                                                                            
                                                                            <div className="form-group">
                                                                           
                                                                                <div className="input-group mt-3">
                                                                                <div className="required">
                                                                                        <label>to</label>
                                                                            </div>
                                                                            
                                                                                    <DatePicker
                                                                                        className="form-control ml-4"
                                                                                        value={this.state.to}
                                                                                        minDate={this.state.from}
                                                                                        format="dd-MM-yyyy"
                                                                                        onChange={(date) =>
                                                                                            this.handleDOBChange(date, "to")
                                                                                        }
                                                                                        yearPlaceholder='YYYY'
                                                                                        monthPlaceholder='MM'
                                                                                        dayPlaceholder='DD'
                                                                                    />
                                                                                    </div>    
                                                                                    </div> 
                                                                                    </div>
                                                                                    
                                                                                    <div className="ShiftPlannerBtn">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="signupbtn abtn btdpml"
                                                                                        onClick={this.searchEvents}
                                                                                    >
                                                                                        <i
                                                                                            className="fa fa-search-plus"
                                                                                            aria-hidden="true"
                                                                                        ></i>
                                                                                        &nbsp;Search
                                                                     </button>
                                                                    <button
                                                                        type="button"
                                                                        className="signupbtn abtn btdpml ml-2 "
                                                                        onClick={() => {
                                                                            let initVal = Object.assign(
                                                                                {},
                                                                                initalValue
                                                                            );
                                                                            this.setState({
                                                                                createShift: initVal,
                                                                            });
                                                                            this.setState({ openModal: true, });
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-user-plus"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        &nbsp;Add
                                                                   </button>
                                                                    </div>
                                                                    </div>
                                                                    
                                                                   <div >
                                                                    
                                                                    <div className="ShiftPlannerBtn mb-2 ">
                                                                    {/* <button
                                                                                        type="button"
                                                                                        className="signupbtn abtn btdpml mr-2"
                                                                                        onClick={this.searchEvents}
                                                                                    >
                                                                                        <i
                                                                                            className="fa fa-search-plus"
                                                                                            aria-hidden="true"
                                                                                        ></i>
                                                                                        &nbsp;Search
                                                                     </button>
                                                                    <button
                                                                        type="button"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={() => {
                                                                            let initVal = Object.assign(
                                                                                {},
                                                                                initalValue
                                                                            );
                                                                            this.setState({
                                                                                createShift: initVal,
                                                                            });
                                                                            this.setState({ openModal: true, });
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-user-plus"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        &nbsp;Add
                                                                   </button> */}
                                                                    </div>
                                                                    </div>
                                                                    </div>
                                                                   </div>
                                                                </div>
                            </div>

                          </div>

                        </div>
                        <div className="col-sm-12">
                          <div className="card">
                          <div className="card-body">
                          <Scheduler
                          schedulerData={viewModel}
                          prevClick={this.prevClick}
                          nextClick={this.nextClick}
                          eventItemClick={this.eventClicked}
                          onViewChange={this.onViewChange}
                          onScrollLeft={this.onScrollLeft}
                          onSelectDate={this.onSelectDate}
                          onScrollRight={this.onScrollRight}
                          onScrollTop={this.onScrollTop}
                          onScrollBottom={this.onScrollBottom}
                          toggleExpandFunc={this.toggleExpandFunc}
                        />
                          </div>
                          </div>
                          
                        </div>
                      
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {/* <Footerad /> */}
          
            </section>
           )}
            <FooterSection />
          </div>
        </div>



        <Modal
          show={this.state.editModel}
          onHide={this.closePopup}
          centered={true}
        >
          <Modal.Header closeButton className="model-hptd">
            <Modal.Title className="h5 model-text-color">
            {this.state.updateShift.from < new Date() ? 
                    "View Shift"   : "Update / Delete Shift" 
            }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12">
              <div className="row">
                <div className="frllftfull">
                  <div className="frllft">
                    <label>Doctor</label>
                  </div>
                  <div className="form-group frlrit alignline26">
                    <span>{this.state.updateShift.doctorName}</span>
                  </div>
                </div>
                <div className="frllftfull">
                  <div className="frllft">
                    <label>Type</label>
                  </div>
                  <div className="frlrit">
                    <CheckboxOrRadioGroup
                      setName={"shiftOccurence"}
                      type={"radio"}
                      controlFunc={this.handleShiftOccurence}
                      options={shift_series}
                      disabled={this.state.updateShift.from < new Date()}
                      selectedOptions={this.state.updateShift.shiftOccurence}
                    />
                  </div>
                </div>
                <div className="frllftfull">
                  <div className="form-group frllft">
                    <label>Date</label>
                  </div>
                  <div className="form-group frlrit">
                    <table className="width-100">
                      <tbody>
                        <tr>
                          <td style={{ width: "20%" }}>
                            <DatePicker
                              className="form-control"
                              value={this.state.updateShift.from}
                              format="dd-MM-yyyy"
                              onChange={(date) =>
                                this.handleUpdateDateChange(
                                  date,
                                  "updateShift.from"
                                )
                              }
                              disabled={this.state.updateShift.from < new Date()}
                            />
                            <SpanField
                              classname="text-danger"
                              title={this.state.updateShift.errors.from}
                            />
                          </td>
                          {shift_series[1] === this.state.updateShift.shiftOccurence.toString() ?
                            <Fragment>
                              <td className="text-center" style={{ width: "10%" }}>
                                To
                              </td>
                              <td style={{ width: "20%" }}>
                                <DatePicker
                                  className="form-control"
                                  value={this.state.updateShift.to}
                                  minDate={this.state.updateShift.from}
                                  format="dd-MM-yyyy"
                                  onChange={(date) =>
                                    this.handleUpdateDateChange(
                                      date,
                                      "updateShift.to"
                                    )
                                  }
                                  disabled={this.state.updateShift.from < new Date()}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={this.state.updateShift.errors.to}
                                />
                              </td>
                            </Fragment> : <Fragment><td className="text-center" style={{ width: "10%" }}>
                            </td>
                              <td style={{ width: "30%" }}>
                              </td>
                            </Fragment>
                          }

                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="frllftfull">
                  <div className="form-group frllft">
                    <label>Shift Timing</label>
                  </div>
                  <div className="form-group frlrit">
                    <SpanField
                      classname="text-danger"
                      title={this.state.updateShift.errors.shift}
                    />
                    <table className="width-100">
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}>
                            {/* <TimePicker
                              disabled={this.state.updateShift.hours_24}
                              onChange={this.handleUpdateWorkhoursChange.bind(
                                this,
                                "shift_start"
                              )}
                              start="00:00"
                              end="23:59"
                              value={
                                this.state.updateShift.shift_start
                                  ? this.state.updateShift.shift_start
                                  : ""
                              }
                            /> */}
                            <input
                              disabled={this.state.updateShift.hours_24 || this.state.updateShift.from < new Date()}
                              type="time"
                              className="form-control"
                              placeholder=""
                              name="shift_start"
                              onChange={this.handleUpdateWorkhoursChange.bind(
                                this,
                                "shift_start"
                              )}
                              value={this.state.updateShift.shift_start}
                            />
                          </td>
                          <td className="text-center" style={{ width: "10%" }}>
                            To
                          </td>
                          <td style={{ width: "30%" }}>
                            {/* <TimePicker
                              disabled={this.state.updateShift.hours_24}
                              onChange={this.handleUpdateWorkhoursChange.bind(
                                this,
                                "shift_end"
                              )}
                              //step={60}
                              start="00:00"
                              end="23:59"
                              value={this.state.updateShift.shift_end}
                            /> */}
                            <input
                              disabled={this.state.updateShift.hours_24 || this.state.updateShift.from < new Date()}
                              type="time"
                              className="form-control"
                              placeholder=""
                              name="shift_end"
                              onChange={this.handleUpdateWorkhoursChange.bind(
                                this,
                                "shift_end"
                              )}
                              value={this.state.updateShift.shift_end}
                            />
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "2%" }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="frllftfull">
                  <div className="form-group frllft">
                    <label>Schedule Type</label>
                  </div>
                  {this.state.updateShift.from < new Date() ? 
                      <div className="form-group frlrit alignline26">
                          <span>{this.state.updateShift.schedule_type.value}</span>
                      </div>
                                    : 
                      <div className="form-group frlrit">
                        <Select maxMenuHeight={120}
                          className="form-control p-0"
                          name="schedule_type"
                          value={this.state.updateShift.schedule_type}
                          onChange={this.handleselectUpdateChange}
                          options={schedule_type}
                        />
                      </div>
                  }
                </div>

              </div>
            </div>
            <div>
              {this.state.deleteConfirm ?
                <div>
                  <p> Are you sure you want to delete {this.state.updateShift.shiftOccurence} Occurence ? </p>

                  <div className="row reverse">
                    <div className="form-group mb-0 text-right">
                      <button className="grnbtn reddg" onClick={this.conformDeleteEvent}>
                        Yes
                      </button>
                    </div>
                    &nbsp;
                    <div className="form-group mb-0 text-right">
                      <button className="grnbtn " onClick={() => {
                        this.setState({ deleteConfirm: false })
                      }}>
                        No
                      </button>
                    </div>
                  </div>

                </div>
                :

                <div>
                {this.state.updateShift.from < new Date() ? "":
                <div className="row reverse">
                  <div className="form-group mb-0 text-right">
                    <button className="grnbtn" onClick={this.updateEvent}>
                      Update
                    </button>
                  </div>
                  &nbsp;
                  <div className="form-group mb-0 text-right">
                    <button className="grnbtn reddg" onClick={this.deleteEvent}>
                      Delete
                    </button>
                  </div>
                </div>
                }
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.openModal}
          onHide={this.closePopup}
          centered={true}
        >
          <Modal.Header closeButton className="model-hptd">
            <Modal.Title className="h5 model-text-color">
              Create Shift
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12">
              <div className="row">
                <div className="frllftfull">
                  <div className="form-group frllft">
                    <label>Doctor</label>
                  </div>
                  <div className="form-group frlrit">
                    <Select maxMenuHeight={120}
                      className="form-control p-0"
                      name="selectedDoc"
                      value={this.state.createShift.selectedDoc}
                      onChange={this.handleselectChange}
                      options={this.state.doctors_list}
                    />
                    <SpanField
                      classname="text-danger"
                      title={this.state.createShift.errors.selectedDoc}
                    />
                  </div>
                </div>
                <div className="frllftfull">
                  <div className="form-group frllft">
                    <label>Schedule type</label>
                  </div>
                  <div className="form-group frlrit">
                    <Select maxMenuHeight={120}
                      className="form-control p-0"
                      name="schedule_type"
                      value={this.state.createShift.schedule_type}
                      onChange={this.handleselectChange}
                      options={schedule_type}
                    />
                    <SpanField
                      classname="text-danger"
                      title={this.state.createShift.errors.schedule_type}
                    />
                  </div>
                </div>
                <div className="frllftfull">
                  <div className="form-group frllft">
                    <label>Date</label>
                  </div>
                  <div className="form-group frlrit">
                    <table className="width-100">
                      <tbody>
                        <tr>
                          <td style={{ width: "40%" }}>
                            <DatePicker
                              className="form-control"
                              value={this.state.createShift.from}
                              format="dd-MM-yyyy"
                              onChange={(date) =>
                                this.handleDOBChange(date, "createShift.from")
                              }
                               yearPlaceholder='YYYY'
                            monthPlaceholder='MM'
                            dayPlaceholder='DD'
                            />
                            <SpanField
                              classname="text-danger"
                              title={this.state.createShift.errors.from}
                            />
                          </td>
                          <td className="text-center" style={{ width: "5%" }}>
                            to
                          </td>
                          <td style={{ width: "40%" }}>
                            <DatePicker
                              className="form-control"
                              value={this.state.createShift.to}
                              minDate={this.state.createShift.from}
                              format="dd-MM-yyyy"
                              onChange={(date) =>
                                this.handleDOBChange(date, "createShift.to")
                              }
                              yearPlaceholder='YYYY'
                              monthPlaceholder='MM'
                              dayPlaceholder='DD'
                            />
                            <SpanField
                              classname="text-danger"
                              title={this.state.createShift.errors.to}
                            />
                          </td>
                          <td className="text-center" style={{ width: "5%" }}>
                            <span>{this.state.createShift.totalDays} Days</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="frllftfull">
                  <div className="form-group frllft">
                    <label>Morning</label>
                  </div>
                  <div className="form-group frlrit">
                    <SpanField
                      classname="text-danger"
                      title={this.state.createShift.errors.shift}
                    />
                    <table className="width-100">
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}>
                            {/* <TimePicker
                              disabled={this.state.createShift.hours_24}
                              onChange={this.handleWorkhoursChange.bind(
                                this,
                                "morning_shift_start"
                              )}
                              //step={60}
                              start="0"
                              end="11"
                              value={
                                this.state.createShift.morning_shift_start
                                  ? this.state.createShift.morning_shift_start
                                  : ""
                              }
                            /> */}
                            <input
                              disabled={this.state.createShift.hours_24}
                              type="time"
                              className="form-control"
                              placeholder=""
                              name="morning_shift_start"
                              onChange={this.handleMorningWorkhoursStartChange.bind(
                                this,
                                "morning_shift_start"
                              )}
                              value={this.state.createShift.morning_shift_start}
                            />
                          </td>
                          <td className="text-center" style={{ width: "10%" }}>
                            to
                          </td>
                          <td style={{ width: "30%" }}>
                            {/* <TimePicker
                              disabled={this.state.createShift.hours_24}
                              onChange={this.handleWorkhoursChange.bind(
                                this,
                                "morning_shift_end"
                              )}
                              //step={60}
                              start="0"
                              end="12"
                              value={this.state.createShift.morning_shift_end}
                              placeholder="To"
                            /> */}
                            <input
                              disabled={this.state.createShift.hours_24}
                              type="time"
                              className="form-control"
                              placeholder=""
                              name="morning_shift_end"
                              onChange={this.handleMorningWorkhoursEndChange.bind(
                                this,
                                "morning_shift_end"
                              )}
                              value={this.state.createShift.morning_shift_end}
                            />
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "2%" }}
                          ></td>
                          <td style={{ width: "25%" }}>
                            <input
                              type="checkbox"
                              id="square-switch24"
                              switch="none"
                              onChange={this.handleWorkhoursChange.bind(
                                this,
                                "24Hours"
                              )}
                              checked={this.state.createShift.hours_24}
                            />
                            <label
                              htmlFor="square-switch24"
                              data-on-label="24 hours"
                              data-off-label="24 hours"
                              className="dcte22 wid-85"
                            ></label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="frllftfull">
                  <div className="form-group frllft">
                    <label>Evening</label>
                  </div>
                  <div className="form-group frlrit">
                    <table className="width-100">
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}>
                            {/* <TimePicker
                              disabled={this.state.createShift.hours_24}
                              onChange={this.handleWorkhoursChange.bind(
                                this,
                                "evening_shift_start"
                              )}
                              //  step={60}
                              start="12"
                              end="24"
                              value={this.state.createShift.evening_shift_start}
                              placeholder="To"
                            /> */}
                            <input
                              disabled={this.state.createShift.hours_24}
                              type="time"
                              className="form-control"
                              placeholder=""
                              name="evening_shift_start"
                              onChange={this.handleEveningWorkhoursStartChange.bind(
                                this,
                                "evening_shift_start"
                              )}
                              value={this.state.createShift.evening_shift_start}
                            />
                          </td>
                          <td className="text-center" style={{ width: "10%" }}>
                            to
                          </td>
                          <td style={{ width: "30%" }}>
                            {/* <TimePicker
                              disabled={this.state.createShift.hours_24}
                              onChange={this.handleWorkhoursChange.bind(
                                this,
                                "evening_shift_end"
                              )}
                              //  step={60}
                              start="12"
                              end="24"
                              value={this.state.createShift.evening_shift_end}
                              placeholder="To"
                            /> */}
                            <input
                              disabled={this.state.createShift.hours_24}
                              type="time"
                              className="form-control"
                              placeholder=""
                              name="evening_shift_end"
                              onChange={this.handleEveningWorkhoursEndChange.bind(
                                this,
                                "evening_shift_end"
                              )}
                              value={this.state.createShift.evening_shift_end}
                            />
                          </td>
                          <td style={{ width: "25%" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="pocdde mb-10">
                  <div className="custom-control custom-checkbox mb-1">
                    <CheckboxOrRadioGroup
                      setName={"working_days"}
                      type={"checkbox"}
                      controlFunc={this.handleWorkingDays}
                      options={working_days}
                      name={"working_days"}
                      selectedOptions={this.state.createShift.working_days}
                    />
                    <input
                      type="checkbox"
                      id="allDaysId"
                      switch="none"
                      onChange={this.handleWorkingDays}
                      checked={this.state.createShift.working_all_days}
                    />
                    <label
                      htmlFor="allDaysId"
                      data-on-label="All days"
                      data-off-label="All days"
                      className="dcte22 wid-115"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group mb-0 ">
                    <button
                      className="grnbtn1"
                      onClick={this.addNewShiftPlanner}
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group mb-0 text-right">
                    <button className="grnbtn1 reddg" onClick={this.closePopup}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(DragDropContext(ShiftPlanner));
